import { CourseList, Token, PageClip, Course, CoursePathChapter, Cart } from './training.types';
import { IMe } from '../../auth/redux/auth.state';

const { detect } = require('detect-browser');
let browser = detect();
const isMobile =
  browser.os === 'Android OS' || browser.os === 'iOS' || browser.os === 'Windows Mobile';

export enum ShowModes {
  COURSE = 'course',
  QUESTION = 'question',
  PROFILE = 'profile'
}

export interface AfterPurchaseEvent {
  course: string;
  token: Token;
  tokens: Token[];
  secondary_id_required: boolean;
  language: string;
  user?: IMe;
}

export interface ITrainingState {
  cart: Cart;
  currentToken: Token;
  currentPageClip: PageClip;
  nextPageClip: PageClip;
  chapter: CoursePathChapter;
  courses: CourseList[];
  coursesTokens: CourseList[];
  course: Course;
  courseCode: string;
  error: string;
  hideSyllabus: boolean;
  inviteError: string;
  isLoadingCart: boolean;
  isLoadingChapter: boolean;
  isLoadingFinal: boolean;
  isLoadingPage: boolean;
  isLoadingCourse: boolean;
  sequence: string[];
  showMode: string;
  tokens: Token[];
  tokensLoading: boolean;
}

export const INITIAL_STATE: ITrainingState = {
  cart: undefined,
  currentPageClip: undefined,
  currentToken: undefined,
  error: undefined,
  chapter: undefined,
  course: undefined,
  courseCode: undefined,
  courses: undefined,
  coursesTokens: undefined,
  hideSyllabus: isMobile,
  inviteError: undefined,
  isLoadingCart: false,
  isLoadingChapter: false,
  isLoadingFinal: false,
  isLoadingPage: false,
  isLoadingCourse: false,
  nextPageClip: undefined,
  showMode: ShowModes.COURSE, // course, question, profile
  sequence: undefined,
  tokens: [],
  tokensLoading: false
};
