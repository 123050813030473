import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-play',
  templateUrl: './not-play.component.html',
  styleUrls: ['./not-play.component.scss']
})
export class NotPlayComponent implements OnInit {
  @Input() branding;
  @Input() loggedIn;
  @Input() environment;
  @Input() student: boolean;
  @Input() manager: boolean;
  @Input() dashboard: boolean;
  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  isStudent() {
    return this.student;
  }

  isManager() {
    return this.manager;
  }
}
