import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiService, Service } from '../../service/api.service';
import { RegistrationUser } from '../models/registration-user.model';
import { AuthActions } from '../../auth/redux/auth.actions';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../app.module';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { IAuthState, IClaims, IMe } from '../../auth/redux/auth.state';
import { selectError, selectLanguage, selectRunningAction } from '../../auth/redux/auth.selectors';
import { CourseStatus } from '../../training/redux/training.types';

@Component({
  selector: 'app-login-inset',
  templateUrl: './login-inset.component.html',
  styleUrls: ['./login-inset.component.scss']
})
export class LoginInsetComponent implements OnInit, OnDestroy {
  timeout: boolean;
  language: string;
  returnUrl: string;

  @select() readonly auth$: Observable<IAuthState>;
  @select(selectLanguage) readonly language$: Observable<string>;
  @select(selectError) readonly error$: Observable<string>;
  @select(selectRunningAction) readonly $isLoggingIn: Observable<boolean>;
  subscriptions: Subscription[] = [];

  focus;
  focus1;
  public form: FormGroup;

  @Input() goAfter = false;
  @Input() fromInvite = false;
  @Output() loginEvent: EventEmitter<string> = new EventEmitter<string>();
  email: string;
  password: string;
  errorLocal: string;
  errorMessage: string;
  userNotFound = false;
  loggingIn = null;
  loginStepChoices;
  regText = 'Signup/login';
  signInDisabled = true;
  emailFound = false;
  forgot = false;
  registrationUser: RegistrationUser;

  passwordPlaceholder = 'Password';
  showInvalid = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private ngRedux: NgRedux<IAppState>,
    private authActions: AuthActions
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.language$.subscribe((value: string) => (this.language = value)));
    this.subscriptions.push(
      this.error$.subscribe((value: string) => {
        return (this.errorMessage = value);
      })
    );
    this.ngRedux.dispatch(this.authActions.errorClear());
    this.ngRedux.dispatch(this.authActions.userStopAction());

    this.subscriptions.push(
      this.$isLoggingIn.subscribe((value) => {
        this.loggingIn = value;
      })
    );

    this.subscriptions.push(
      this.auth$.subscribe((value: IAuthState) => {
        if (value.loggedIn) {
          this.redirectAfterLogin(value.claims, value.user);
        }
      })
    );

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(
        (params: Params) => (this.forgot = params['forgot'] === 'true')
      )
    );

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || undefined;

    this.registrationUser = new RegistrationUser();
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.registrationUser.email = params['u'];
        this.timeout = params['timeout'] === 'true';
      })
    );
    this.initForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      language: this.language,
      rememberMe: [false]
    });
  }

  async onSubmit(action: string) {
    this.errorLocal = null;
    if (this.loggingIn) {
      console.log('already logging in');
      return;
    }
    if (!this.form.valid) {
      this.errorLocal = 'login.all-fields';
      if (action === 'signup' && !this.emailFound && !this.form.get('password').valid) {
        this.errorLocal = 'login.sign-up-error';
      }

      return;
    }
    this.errorLocal = null;
    this.userNotFound = false;

    switch (action) {
      case 'login':
        this.ngRedux.dispatch(
          this.authActions.userLoginAsync(
            this.form.get('email').value,
            this.form.get('password').value
          )
        );
        break;
      case 'signup':
      default:
        this.ngRedux.dispatch(
          this.authActions.userRegisterAsync({
            email: this.form.get('email').value.toLowerCase(),
            password: this.form.get('password').value,
            passwordConfirm: this.form.get('password').value,
            language: this.language,
            fromInvite: this.fromInvite
          })
        );
        break;
    }
  }

  blurEmail() {
    this.errorMessage = undefined;
    this.errorLocal = null;
    this.signInDisabled = true;
    this.showInvalid = false;

    if (!this.form.get('email').valid) {
      this.regText = 'Signup/login';
      this.emailFound = false;
      console.log('Form not valid');
      this.errorMessage = 'Invalid entry in form';
      return;
    }
    this.regText = 'Checking email address...';
    console.log('Checking email address...');

    this.apiService
      .postUrl(Service.PUBLIC, '/user/confirmed', { email: this.form.get('email').value })
      .then((result) => {
        console.log(result);
        this.errorMessage = undefined;
        result.error === 'Not Found' ? this.setNewUser() : this.setExistingUser();
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 406) {
          this.loggingIn = null;
          this.errorMessage = error.error.error;
          this.signInDisabled = true;
        } else {
          this.errorMessage = undefined;
          this.setNewUser();
        }
      });

    this.apiService
      .postUrl(Service.PUBLIC, `/user/email`, {
        email: this.form.get('email').value
      })
      .then((result) => {
        console.log(result);
        this.showInvalid = !result.data;
      })
      .catch((error) => {
        console.log(error);
        this.showInvalid = false;
      });
  }

  setExistingUser() {
    this.regText = 'Sign into existing account';
    this.signInDisabled = false;
    this.passwordPlaceholder = 'Found you! Enter your password.';
    this.emailFound = true;
    this.loggingIn = null;
  }

  setNewUser() {
    this.regText = 'Register new user';
    this.passwordPlaceholder = 'Create a password';
    this.signInDisabled = false;
    this.emailFound = false;
  }

  onGoogle() {
    if (this.loggingIn) {
      return false;
    }
    this.errorLocal = undefined;
    console.log('logging in google');
    this.ngRedux.dispatch(this.authActions.userLoginThirdPartyAsync('google'));
  }

  onLinkedIn() {}

  redirectAfterLogin(claims: IClaims, user: IMe) {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl).then();
    } else {
      if (this.goAfter) {
        if (claims && claims.is_manager) {
          let route = '/admin/tokens';
          if (!user.email_verified) {
            route = '/profile/validate';
          }
          this.router.navigate([route]).then();
        } else {
          let route = '/training/course-list';
          if (!user.email_verified) {
            route = '/profile/validate';
          }
          this.router.navigate([route]).then();
        }
      } else {
        this.loginEvent.next(CourseStatus.LoggedIn);
      }
    }
  }
}
