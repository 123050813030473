import * as tslib_1 from "tslib";
import * as moment from 'moment-timezone';
import { CourseShow, Question } from './training.types';
export var upsertTokens = function (Tokens, newToken) {
    var existingItem = Tokens.find(function (item) { return item.id === newToken.id; });
    if (existingItem) {
        return Tokens.map(function (item) { return (item.id === newToken.id ? newToken : item); });
    }
    return Tokens.concat([newToken]);
};
export var updateTokensFromCurrent = function (Tokens, newToken) {
    var existingCartItem = Tokens.find(function (item) { return item.id === newToken.id; });
    if (existingCartItem) {
        return Tokens.map(function (item) { return (item.id === newToken.id ? newToken : item); });
    }
    return Tokens.concat([newToken]);
};
export var initializeToken = function (item) {
    if (typeof item.created_at === 'number') {
        item.created_at = moment.unix(item.created_at).toDate();
    }
    if (item.completed_at && typeof item.completed_at === 'number') {
        item.completed_at = moment.unix(item.completed_at).toDate();
    }
    if (item.expires_at && typeof item.expires_at === 'number') {
        item.expires_at = moment.unix(item.expires_at).toDate();
    }
    if (item.renewal_eligible_at && typeof item.renewal_eligible_at === 'number') {
        item.renewal_eligible_at = moment.unix(item.renewal_eligible_at).toDate();
    }
    item.currentChapterIndex = item.currentChapterIndex || 0;
    item.currentPageIndex = item.currentPageIndex || 0;
    item.currentSectionIndex = item.currentSectionIndex || 0;
    item.currentSequenceNumber = item.currentSequenceNumber || 0;
    item.highestChapter = item.highestChapter || 0;
    item.highestSection = item.highestSection || 0;
    item.language = item.language || 'en';
    item.on_page = item.on_page || 'introduction';
    return item;
};
export var updateCoursesBasedOnTokens = function (courses, tokens) {
    // Find the course in our course list to update
    var list = courses.map(function (objCourse) {
        objCourse.tokens = tokens.filter(function (item) { return item.course === objCourse.id; });
        return objCourse;
    });
    console.log(list);
    return list;
};
export var applyScoring = function (questions, scoring) {
    questions = questions.map(function (question) {
        var thisMissed = scoring.questions.filter(function (item) { return item.id === question.id; });
        if (thisMissed[0]) {
            question.correct = thisMissed[0].correct;
        }
        return question;
    });
    return questions.slice();
};
export var removeProfileQuestion = function (course, itemToRemove) {
    course.profile = course.profile.filter(function (item) { return item !== itemToRemove; });
    return course;
};
function isThereASecurityQuestion(token) {
    return (token.security &&
        token.security.initial &&
        token.currentPageIndex === 0 &&
        token.currentChapterIndex === 0 &&
        token.currentSectionIndex === 0);
}
function isThereAProfileQuestion(token, course) {
    return [2, 7].includes(token.currentPageIndex + 1) && course.profile && course.profile.length > 0;
}
function isThereANextPageInThisChapter(token, course) {
    return (token.currentPageIndex + 1 <=
        course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].pages.length -
            1);
}
function isThereANextChapterInThisSection(token, course) {
    return (token.currentChapterIndex + 1 <=
        course.course_path[token.currentSectionIndex].chapters.length - 1);
}
function isThereANextSectionInThisCourse(token, course) {
    return token.currentSectionIndex + 1 <= course.course_path.length - 1;
}
function isAtLastPageOfChapter(token, course) {
    return (token.currentPageIndex + 1 >
        course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].pages.length -
            1);
}
function isThereAChapterQuiz(token, course) {
    return course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].questions
        .reinforcement;
}
function isThereASectionQuiz(token, course) {
    return course.course_path[token.currentSectionIndex].questions.reinforcement;
}
function isThereASecurityConfirmation(token, course) {
    var isPastFirstSection = token.currentPageIndex === 0 &&
        token.currentChapterIndex === 0 &&
        token.currentSectionIndex > 0;
    var isInFirstSection = token.currentPageIndex === 0 &&
        token.currentChapterIndex === 2 &&
        token.currentSectionIndex === 0;
    var isAfterLastPage = isAtLastPageOfChapter(token, course) &&
        !isThereANextChapterInThisSection(token, course) &&
        !isThereANextSectionInThisCourse(token, course);
    return (token.security &&
        token.security.verify &&
        (isPastFirstSection || isInFirstSection || isAfterLastPage || token.show === CourseShow.End));
}
function setToPriorPage(token, course) {
    if (token.currentPageIndex > 0) {
        token.currentPageIndex--;
    }
    else if (token.currentPageIndex === 0 && token.currentChapterIndex > 0) {
        token.currentChapterIndex--;
        token.currentPageIndex =
            course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].pages
                .length - 1;
    }
    else if (token.currentPageIndex === 0 &&
        token.currentChapterIndex === 0 &&
        token.currentSectionIndex > 0) {
        token.currentSectionIndex--;
        token.currentChapterIndex = course.course_path[token.currentSectionIndex].chapters.length - 1;
        token.currentPageIndex =
            course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].pages
                .length - 1;
    }
    else {
        console.log('No prior page');
        return token;
    }
    token.show = CourseShow.Course;
    var thisPage = course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].pages[token.currentPageIndex];
    token.currentSequenceNumber = thisPage.sequence_no;
    token.on_page_id = thisPage.id;
    token.on_page = thisPage.slug;
    return token;
}
function setToNextPage(token, course) {
    token.currentPageIndex++;
    token.show = CourseShow.Course;
    var thisPage = course.course_path[token.currentSectionIndex].chapters[token.currentChapterIndex].pages[token.currentPageIndex];
    token.currentSequenceNumber = thisPage.sequence_no;
    token.on_page_id = thisPage.id;
    token.on_page = thisPage.slug;
    token.highestSection = Math.max(token.highestSection, token.currentSectionIndex);
    token.highestChapter = Math.max(token.highestChapter, token.currentChapterIndex);
    token.highestPageId = Math.max(token.highestPageId, thisPage.sequence_no);
    token.status_percentage = Math.round((100 * token.highestPageId) / course.sequence.length);
    return token;
}
function setToNextChapter(token, course) {
    console.log('setToNextChapter');
    token.show = CourseShow.Course;
    token.questions = undefined;
    token.currentPageIndex = -1;
    token.currentChapterIndex++;
    setToNextPage(token, course);
    return token;
}
function setToNextSection(token, course) {
    console.log('setToNextSection');
    token.show = CourseShow.Course;
    token.questions = undefined;
    token.currentPageIndex = -1;
    console.log('Now, onto the next section!!');
    token.currentChapterIndex = 0;
    token.currentSectionIndex++;
    setToNextPage(token, course);
    return token;
}
// Go to next item which is usually a page but test to see if we need to show a quiz or profile question
export var setTokenToPreviousItem = function (token, course) {
    token = setToPriorPage(token, course);
    return tslib_1.__assign({}, token);
};
// Go to next item which is usually a page but test to see if we need to show a quiz or profile question
export var setTokenToNextItem = function (token, course) {
    switch (token.show) {
        case CourseShow.End:
            return tslib_1.__assign({}, token);
        case CourseShow.Profile:
        case CourseShow.SecurityInitialResult:
        case CourseShow.ProfileResult:
        case CourseShow.QuizResult:
            if (isThereANextPageInThisChapter(token, course)) {
                token = setToNextPage(token, course);
                return tslib_1.__assign({}, token);
            }
            if (isThereANextChapterInThisSection(token, course)) {
                token = setToNextChapter(token, course);
                return tslib_1.__assign({}, token);
            }
            if (isThereANextSectionInThisCourse(token, course)) {
                token = setToNextSection(token, course);
                return tslib_1.__assign({}, token);
            }
            token.show = CourseShow.End;
            return tslib_1.__assign({}, token);
        case CourseShow.Course:
            if (isThereASecurityQuestion(token)) {
                token.show = CourseShow.SecurityInitial;
                token.questions = undefined;
                return tslib_1.__assign({}, token);
            }
            if (isThereASecurityConfirmation(token, course)) {
                token.show = CourseShow.SecurityConfirm;
                token.questions = undefined;
                return tslib_1.__assign({}, token);
            }
            if (isThereAProfileQuestion(token, course)) {
                token.show = CourseShow.Profile;
                var q = new Question();
                q.id = course.profile[0];
                token.questions = [q];
                return tslib_1.__assign({}, token);
            }
            if (isThereANextPageInThisChapter(token, course)) {
                token = setToNextPage(token, course);
                return tslib_1.__assign({}, token);
            }
            if (isThereAChapterQuiz(token, course)) {
                token.show = CourseShow.Quiz;
                token.questions = undefined;
                return tslib_1.__assign({}, token);
            }
            if (isThereANextChapterInThisSection(token, course)) {
                token = setToNextChapter(token, course);
                return tslib_1.__assign({}, token);
            }
            // At this point we have no more chapters in our section
            if (isThereASectionQuiz(token, course)) {
                token.show = CourseShow.SectionQuiz;
                token.questions = undefined;
                return tslib_1.__assign({}, token);
            }
            // There's no quiz for this section
            if (isThereANextSectionInThisCourse(token, course)) {
                token = setToNextSection(token, course);
                return tslib_1.__assign({}, token);
            }
            token.show = CourseShow.End;
            return tslib_1.__assign({}, token);
        default:
            console.log("No scenario matched " + token.show + "?", token);
            throw new Error('Missing next step in course');
    }
};
