import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
  @select() readonly auth$: Observable<any>;

  constructor(private router: Router, private cookieService: CookieService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth$.pipe(
      map((e) => {
        if (localStorage.getItem('jwt') && e.loggedIn) {
          return true;
        } else {
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } }).then();
        }
      }),
      catchError((err) => {
        console.log(err);
        this.router.navigate(['/auth/login']).then();
        return of(false);
      })
    );
  }
}
