<div class="login-form">
  <form>
    <h1 class="sign-in social text-center">{{ 'home.already-logged-in' | translate }}</h1>

    <div class="form-group mt-4" *ngIf="!(isManager$ | async)">
      <button
        type="button"
        class="btn btn-secondary btn-block"
        [routerLink]="['/training/course-list']"
      >
        <a [routerLink]="['/training/course-list']">{{ 'courses.courses-menu' | translate }}</a>
      </button>
    </div>

    <div class="form-group mt-4" *ngIf="isManager$ | async">
      <button type="button" class="btn btn-secondary btn-block" [routerLink]="['/admin/tokens']">
        <a [routerLink]="['/admin/tokens']">Business Dashboard</a>
      </button>
    </div>

    <p class="text-center text-muted">
      <a class="dropdown-item" [routerLink]="['/logout']">{{ 'menu.logout' | translate }}</a>
    </p>
  </form>
</div>
