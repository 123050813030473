import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { selectUser } from '../../auth/redux/auth.selectors';
import { Observable } from 'rxjs/internal/Observable';
export class BouncedAlertComponent {
    constructor() {
        this.subscriptions = [];
        this.partiallyHidden = false;
    }
    ngOnInit() {
        this.subscriptions.push(this.user$.subscribe((value) => (this.objUser = value)));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    dismissError() {
        this.partiallyHidden = !this.partiallyHidden;
    }
}
tslib_1.__decorate([
    select(selectUser),
    tslib_1.__metadata("design:type", Observable)
], BouncedAlertComponent.prototype, "user$", void 0);
