import { Injectable } from '@angular/core';
import {
  Cart,
  CourseItem,
  CourseList,
  CourseShow,
  CourseStatus,
  Token,
  TrainingActionTypes
} from './training.types';
import { ApiService, Service } from '../../service/api.service';
import { setTokenToNextItem, setTokenToPreviousItem } from './training.utils';
import { Router } from '@angular/router';
import { StringHelper } from '../../shared/string-helper';
import { AuthActions } from '../../auth/redux/auth.actions';
import { PackageAuto, PackageTypes } from '../../shared/models/packages';

@Injectable()
export class TrainingActions {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private authActions: AuthActions
  ) {}

  chapterStart = () => ({
    type: TrainingActionTypes.CHAPTER_FETCH_START
  });

  chapterSuccess = (data: object, index: number) => ({
    type: TrainingActionTypes.CHAPTER_FETCH_SUCCESS,
    payload: { data, index }
  });

  chapterFailure = (status, error) => ({
    type: TrainingActionTypes.CHAPTER_FETCH_FAILURE,
    payload: error
  });

  cartAdd = (items: CourseItem[]) => ({
    type: TrainingActionTypes.CART_ADD,
    payload: items
  });

  clearCourse = () => ({
    type: TrainingActionTypes.COURSE_FETCH_SUCCESS,
    payload: null
  });

  clearProfileQuestion = (item) => ({
    type: TrainingActionTypes.CLEAR_PROFILE_QUESTION,
    payload: item
  });

  courseStart = () => ({
    type: TrainingActionTypes.COURSE_FETCH_START
  });

  courseSuccess = (course) => ({
    type: TrainingActionTypes.COURSE_FETCH_SUCCESS,
    payload: course
  });

  courseFailure = (status, error) => ({
    type: TrainingActionTypes.COURSE_FETCH_FAILURE,
    payload: error
  });

  coursesSuccess = (data) => ({
    type: TrainingActionTypes.COURSES_FETCH_SUCCESS,
    payload: data
  });

  coursesTokensSuccess = (data) => ({
    type: TrainingActionTypes.COURSES_TOKENS_FETCH_SUCCESS,
    payload: data
  });

  coursesFailure = (status, error) => ({
    type: TrainingActionTypes.COURSES_FETCH_FAILURE,
    payload: error
  });

  finalStart = () => ({
    type: TrainingActionTypes.FINAL_FETCH_START
  });

  finalSuccess = (data) => ({
    type: TrainingActionTypes.FINAL_FETCH_SUCCESS,
    payload: data
  });

  finalFailure = (status, error) => ({
    type: TrainingActionTypes.FINAL_FETCH_FAILURE,
    payload: error
  });

  hideSyllabus = () => ({
    type: TrainingActionTypes.SET_SYLLABUS_HIDE
  });

  pageStart = () => ({
    type: TrainingActionTypes.PAGE_FETCH_START
  });

  pageFailure = (status, error) => ({
    type: TrainingActionTypes.PAGE_FETCH_FAILURE,
    payload: error
  });

  pageSetFromToken = (pageIndex) => ({
    type: TrainingActionTypes.PAGE_SET_TEXT,
    payload: pageIndex
  });

  quizStart = () => ({
    type: TrainingActionTypes.QUIZ_FETCH_START
  });

  quizFetchSuccess = (questions, timestamp?) => ({
    type: TrainingActionTypes.QUIZ_FETCH_SUCCESS,
    payload: { questions, timestamp }
  });

  quizScoreSuccess = (data) => ({
    type: TrainingActionTypes.QUIZ_SCORE_SUCCESS,
    payload: data
  });

  quizScoreFailure = (data) => ({
    type: TrainingActionTypes.QUIZ_SCORE_FAILURE,
    payload: data
  });

  quizFailure = (status, error) => ({
    type: TrainingActionTypes.QUIZ_FETCH_FAILURE,
    payload: error
  });

  setCurrentToken = (objToken) => ({
    type: TrainingActionTypes.SET_CURRENT_TOKEN,
    payload: objToken
  });

  securityAnsweredSuccess = (data) => ({
    type: TrainingActionTypes.SECURITY_SUCCESS,
    payload: data
  });

  securityConfirmationFailure = (data) => ({
    type: TrainingActionTypes.SECURITY_CONFIRM_FAILURE,
    payload: data
  });

  tokenAdd = (token: Token) => ({
    type: TrainingActionTypes.TOKENS_ADD,
    payload: token
  });

  tokensStart = () => ({
    type: TrainingActionTypes.TOKENS_FETCH_START
  });

  tokensSuccess = (tokens) => ({
    type: TrainingActionTypes.TOKENS_FETCH_SUCCESS,
    payload: tokens
  });

  tokensFailure = (status, error) => ({
    type: TrainingActionTypes.TOKENS_FETCH_FAILURE,
    payload: error
  });

  cartStart = () => ({
    type: TrainingActionTypes.CART_FETCH_START
  });

  cartSuccess = (cart: Cart) => ({
    type: TrainingActionTypes.CART_FETCH_SUCCESS,
    payload: cart
  });

  cartFailure = (status, error) => ({
    type: TrainingActionTypes.CART_FETCH_FAILURE,
    payload: error
  });

  inviteError = (error) => ({
    type: TrainingActionTypes.INVITE_ERROR,
    payload: error
  });

  /** Async functions **/

  fetchTokensAsync = () => {
    return (dispatch) => {
      dispatch(this.tokensStart());

      this.apiService
        .getUrl(Service.PUBLIC, '/tokens')
        .then((response) => {
          dispatch(this.tokensSuccess(response.data));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.tokensFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  cartSetAsync = (course: CourseList, cart: Cart) => {
    return (dispatch) => {
      dispatch(this.cartStart());

      let params = {
        qty: 1,
        course: course.id,
        type: PackageTypes.TYPE_INDIVIDUAL,
        auto: PackageAuto.ONE
      };

      dispatch(
        this.cartSuccess({
          ...cart,
          ...params,
          items: course.lineItems
        })
      );

      this.apiService
        .postUrl(Service.STRIPE, '/cart', params)
        .then((response) => {
          dispatch(this.cartSuccess(response.data));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.cartFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  cartSet = (cart: Cart) => {
    return (dispatch) => {
      dispatch(this.cartSuccess(cart));
    };
  };

  cartSetBulkAsync = (cart: Cart) => {
    return (dispatch) => {
      dispatch(this.cartStart());

      this.apiService
        .postUrl(Service.STRIPE, '/cart', {
          auto: cart.auto,
          course: cart.course,
          qty: cart.qty,
          type: cart.type
        })
        .then((response) => {
          dispatch(this.cartSuccess(response.data));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.cartFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  cartClearAsync = () => {
    return (dispatch) => {
      dispatch(this.cartStart());
      this.apiService
        .deleteUrl(Service.STRIPE, '/cart')
        .then((response) => {
          if (response && response.data) {
            dispatch(this.cartSuccess(response.data));
          }
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.cartFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  cartClear = () => {
    return (dispatch) => {
      dispatch(this.cartSuccess(undefined));
    };
  };

  fetchCartAsync = () => {
    return (dispatch) => {
      dispatch(this.cartStart());

      this.apiService
        .getUrl(Service.STRIPE, '/cart')
        .then((response) => {
          console.info('🛒 Cart found');
          dispatch(this.cartSuccess(response.data));
        })
        .catch((response) => {
          if (response.status === 404) {
            console.info('🛒 No cart in progress');
          } else {
            console.log(response);
            dispatch(this.cartFailure(response.status, StringHelper.extractError(response)));
          }
        });
    };
  };

  fetchCourseAsync = (token: Token) => {
    return (dispatch) => {
      dispatch(this.courseStart());
      this.apiService
        .postUrl(Service.PUBLIC, `/course/${token.course}`, {
          token: token.id,
          language: token.language
        })
        .then((response) => {
          let data = response.data;
          if (data.profile && data.profile.includes('secondary_id_required')) {
            data.profile = data.profile.filter((item) => item !== 'secondary_id_required');
            console.log('Course requires secondary ID');
            dispatch(this.authActions.setSecondary());
          }
          dispatch(this.courseSuccess(data));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.courseFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  fetchCoursesAsync = () => {
    return (dispatch) => {
      dispatch(this.courseStart());
      this.apiService
        .getUrl(Service.PUBLIC, `/courses`, {})
        .then((response) => {
          dispatch(this.coursesSuccess(response.data));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.coursesFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  fetchCoursesTokensAsync = () => {
    return (dispatch) => {
      dispatch(this.courseStart());
      this.apiService
        .getUrl(Service.PUBLIC, `/tokens-courses`, {})
        .then((response) => {
          dispatch(this.coursesTokensSuccess(response.data));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.coursesFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  fetchChapterAsync = (token: Token) => {
    return (dispatch) => {
      dispatch(this.chapterStart());

      this.apiService
        .postUrl(
          Service.PUBLIC,
          `/course/${token.course}/chapter/${token.currentSectionIndex}/${token.currentChapterIndex}/chapter`,
          {
            token: token.id,
            language: token.language
          }
        )
        .then((response) => dispatch(this.chapterSuccess(response.data, token.currentChapterIndex)))
        .catch((response) => {
          console.log(response);
          return dispatch(this.chapterFailure(response.status, response.error.error));
        });
    };
  };

  fetchPriorPageAsync = () => {
    return (dispatch, globalState) => {
      console.log('🔔 fetchPriorPageAsync()');
      const language = globalState().auth.language;
      const state = globalState().training;
      const oldIndex = state.currentToken.currentChapterIndex;

      // Set our token to the prior item
      const objToken: Token = setTokenToPreviousItem(state.currentToken, state.course);
      objToken.language = language;
      dispatch(this.setCurrentToken(objToken));

      // If we need to load something as a result of that, do it now
      if (objToken.show === CourseShow.Course && oldIndex !== objToken.currentChapterIndex) {
        dispatch(this.fetchChapterAsync(objToken));
      }
      dispatch(this.statusUpdatePush(objToken, state));
    };
  };

  fetchNextPageAsync = () => {
    return (dispatch, globalState) => {
      console.log('🔔 fetchNextPageAsync()');
      const language = globalState().auth.language;
      const state = globalState().training;
      const oldIndex = state.currentToken.currentChapterIndex;

      // Set our token to the next item

      const objToken: Token = setTokenToNextItem(state.currentToken, state.course);
      objToken.language = language;
      dispatch(this.setCurrentToken(objToken));

      // If we need to load something as a result of that, do it now
      if (objToken.show === CourseShow.Course && oldIndex !== objToken.currentChapterIndex) {
        dispatch(this.fetchChapterAsync(objToken));
      }
      if (
        !objToken.questions &&
        [
          CourseShow.Quiz,
          CourseShow.SectionQuiz,
          CourseShow.SecurityInitial,
          CourseShow.SecurityConfirm
        ].includes(<CourseShow>objToken.show)
      ) {
        dispatch(this.quizAsync(objToken));
      }

      dispatch(this.statusUpdatePush(objToken, state));
    };
  };

  quizAsync = (token: Token, answers?: object) => {
    return (dispatch) => {
      dispatch(this.quizStart());

      interface IQuizObject {
        course: string;
        id: string;
        answers?: object;
        show: string;
        section?: number;
        chapter?: number;
      }

      const params: IQuizObject = {
        course: token.course,
        id: token.id,
        show: token.show
      };

      if (answers) {
        params.answers = answers;
        if (token.show === CourseShow.SecurityInitial) {
          params.show = CourseShow.SecurityAnswers;
        }
      }

      if (token.show === CourseShow.End) {
        params.show = CourseShow.FinalQuiz;
      }

      this.apiService
        .postUrl(Service.PUBLIC, `/quiz`, params)
        .then((response) => {
          switch (response.data.show) {
            case CourseShow.SecurityInitial:
              return dispatch(this.quizFetchSuccess(response.data.questions));
            case CourseShow.SecurityConfirm:
              return dispatch(
                this.quizFetchSuccess(response.data.questions, response.data.timestamp)
              );
            case CourseShow.SecurityAnswers:
            case CourseShow.SecurityConfirmResultPass:
              return dispatch(this.securityAnsweredSuccess(response.data));
            case CourseShow.SecurityConfirmResultFail:
              return dispatch(this.securityConfirmationFailure(response.data));
            case CourseShow.Quiz:
              return dispatch(this.quizFetchSuccess(response.data.questions));
            case CourseShow.QuizResultCourseLock:
            case CourseShow.QuizResultSectionLock:
              return dispatch(this.quizScoreFailure(response.data));
            case CourseShow.FinalQuiz:
              return dispatch(this.finalSuccess(response.data.questions));
            case CourseShow.QuizResult:
            case CourseShow.SecurityInitialResult:
              return dispatch(this.quizScoreSuccess(response.data.results));
            case CourseShow.FinalResult:
              return dispatch(this.quizScoreSuccess(response.data.results));
            default:
              return dispatch(this.fetchNextPageAsync());
          }
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 409) {
            this.router.navigate(['/profile/certificate/', token.id]).then();
          }
          dispatch(this.quizFailure(response.status, StringHelper.extractError(response)));
        });
    };
  };

  redeemInvite = (token: string) => {
    return (dispatch) => {
      this.apiService
        .postUrl(Service.PUBLIC, '/token/redeem-invite', { token })
        .then((response) => {
          console.log(response);
          dispatch(this.authActions.userSet(response.data.user));
          dispatch(this.afterPurchase(response.data.token));
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.inviteError(StringHelper.extractError(response)));
        });
    };
  };

  resetCourse = (objToken: Token) => {
    return (dispatch) => {
      const progress = {
        language: objToken.language,
        reset: true
      };
      this.apiService
        .postUrl(Service.PUBLIC, `/token-update-progress/${objToken.id}`, progress)
        .then((response) => {
          const objNewToken: Token = response.data;
          console.log(objNewToken);
          dispatch(this.setCurrentToken(objNewToken));
          dispatch(this.fetchChapterAsync(objNewToken));
          this.router
            .navigate([
              '/training',
              objNewToken.course,
              'page',
              objNewToken.on_page || 'introduction'
            ])
            .then();
        })
        .catch((error) => console.log(error));
    };
  };

  statusUpdatePush = (objToken: Token, state) => {
    return () => {
      const progress = {
        language: objToken.language,
        pageClip: {
          id: objToken.on_page_id || state.currentToken.show,
          slug: objToken.on_page || state.currentToken.show,
          language: objToken.language,
          sequence_no: state.currentToken.currentSequenceNumber,
          status_percentage: state.currentToken.status_percentage,
          currentPageIndex: state.currentToken.currentPageIndex,
          currentChapterIndex: state.currentToken.currentChapterIndex,
          currentSectionIndex: state.currentToken.currentSectionIndex,
          currentSequenceNumber: state.currentToken.currentSequenceNumber,
          show: state.currentToken.show
        }
      };
      this.apiService
        .postUrl(Service.PUBLIC, `/token-update-progress/${state.currentToken.id}`, progress)
        .then()
        .catch((error) => console.log(error));
    };
  };

  subscriberSetCreditCardAsync = (params: any) => {
    return async (dispatch) => {
      try {
        let response = await this.apiService.postUrl(
          Service.ADMIN,
          '/subscriber/account/cc',
          params
        );
        dispatch(this.cartSuccess(response.data));
        return true;
      } catch (error) {
        console.log(error);
        dispatch(this.cartFailure(error.status, StringHelper.extractError(error)));
        return false;
      }
    };
  };

  subscriberDeleteCreditCardAsync = () => {
    return async () => {
      try {
        let response = await this.apiService.deleteUrl(Service.ADMIN, '/subscriber/account/cc');
        console.log(response);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    };
  };

  afterPurchase = (objToken: Token, blnRedirectAfter = true) => {
    return (dispatch) => {
      if (objToken.token_type === 'initial' && blnRedirectAfter) {
        objToken.status = CourseStatus.InProgress;
        dispatch(this.tokenAdd(objToken));
        dispatch(this.setCurrentToken(objToken));
      } else {
        dispatch(this.tokenAdd(objToken));
      }
      dispatch(this.cartClear());
      if (blnRedirectAfter) {
        this.router.navigate(['/training', objToken.course, 'page', 'introduction']).then();
      }
    };
  };
}
