import { AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { rollbarFactory } from './rollbar';
import { NgRedux } from '@angular-redux/store';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from './app.root-reducer';
import { compose, applyMiddleware, Store, createStore } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { asyncLocalStorage } from 'redux-persist/storages';
var persistConfig = {
    // whitelist: ['auth'],
    // whitelist: ['auth', 'admin', 'training'],
    keyPrefix: 'cent-',
    storage: asyncLocalStorage
};
// @ts-ignore
export var store = process.env.APP_ENV === 'prod'
    ? createStore(rootReducer, compose(
    // @ts-ignore
    applyMiddleware(thunk), autoRehydrate()))
    : createStore(rootReducer, compose(
    // @ts-ignore
    applyMiddleware(thunk, createLogger()), autoRehydrate()));
var AppConfigService = /** @class */ (function () {
    function AppConfigService() {
    }
    AppConfigService.prototype.load = function () {
        return new Promise(function (resolve) {
            persistStore(store, persistConfig, function () {
                resolve();
            });
        });
    };
    return AppConfigService;
}());
export { AppConfigService };
var ɵ0 = GoogleLoginProvider.PROVIDER_ID;
var config = new AuthServiceConfig([
    {
        id: ɵ0,
        provider: new GoogleLoginProvider(environment.googleLogin)
    }
]);
export function provideConfig() {
    return config;
}
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
var ɵ1 = function (factoryConfig) { return function () { return factoryConfig.load(); }; }, ɵ2 = rollbarFactory;
var AppModule = /** @class */ (function () {
    function AppModule(ngRedux) {
        ngRedux.provideStore(store);
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
