import { AdminEmployees } from '../admin-tokens/admin-tokens.component';

export const INITIAL_STATE: IAdminState = {
  loading: false,
  saving: false,
  langWarning: true,
  loadingTokens: false,
  lazyLoading: false,
  subscriberProfile: undefined,
  creditCard: undefined,
  tokens: undefined,
  total_tokens: { 'food-handler-tx': 0, 'tabc-tx': 0 },
  error: undefined,
  parameters: {
    search: '',
    status: 'all',
    sortField: 'created_at',
    sortDirection: 'desc',
    start: 0,
    limit: 10,
    next: undefined,
    course: 'food-handler-tx'
  },
  courses: undefined,
  tiers: [
    {
      flat_amount: 99,
      flat_amount_decimal: '99',
      unit_amount: 0,
      unit_amount_decimal: '0',
      up_to: 20
    },
    {
      flat_amount: 99,
      flat_amount_decimal: '99',
      unit_amount: 0,
      unit_amount_decimal: '0',
      up_to: 40
    },
    {
      flat_amount: 99,
      flat_amount_decimal: '99',
      unit_amount: 0,
      unit_amount_decimal: '0',
      up_to: 80
    },
    {
      flat_amount: 99,
      flat_amount_decimal: '99',
      unit_amount: 0,
      unit_amount_decimal: '0',
      up_to: 100
    }
  ],
  purchases: undefined,
  purchasesPagination: {
    next: undefined,
    limit: 20,
    count: 0
  },
  webhooks: undefined,
  webhookPagination: {
    next: undefined,
    limit: 20,
    count: 0
  }
};

export interface ITiers {
  flat_amount: number;
  flat_amount_decimal: string;
  unit_amount: number;
  unit_amount_decimal: string;
  up_to: number;
}

export interface IPagination {
  next: number | undefined;
  limit: number;
  count: number;
}

export interface IAdminState {
  loading: boolean;
  saving: boolean;
  langWarning: boolean;
  loadingTokens: boolean;
  lazyLoading: boolean;
  subscriberProfile: SubscriberProfile;
  creditCard: ICard;
  tokens: AdminEmployees[];
  courses: IAdminCourse[];
  total_tokens: { 'food-handler-tx': number; 'tabc-tx': number };
  error: string;
  parameters: IParameters;
  tiers: ITiers[];
  purchases: any[];
  purchasesPagination: IPagination;
  webhooks: any[];
  webhookPagination: IPagination;
}

export interface IAdminCourse {
  apiKey: boolean;
  code: string;
  code_type: string;
  course: string;
  id: string;
  package_auto_fill: boolean;
  package_qty: number;
  total_tokens: number;
}

export interface SubscriberProfile {
  account: {
    id?: string;
    name?: string;
    apiKey?: string;
    webhook?: string;
    show_pro_upgrade?: boolean;
    employers: IEmployerShort[];
  };
  courses: IAdminCourse[];
}

export interface IParameters {
  search: string;
  status: string;
  sortField: string;
  sortDirection: string;
  start: number;
  limit: number;
  next: string | undefined;
  course: string;
}

export interface ICard {
  brand: string;
  funding: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  name: string;
}

export interface IEmployerShort {
  id: string;
  place_id: string;
  name: string;
  formatted_address: string;
  formatted_phone_number: string;
}
