<main>
  <section class="section section-shaped section-lg">
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          Error 404: <span style="font-style: italic">{{ router.url }}</span> was not found.
        </div>
      </div>
    </div>
  </section>
</main>
