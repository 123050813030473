import { IEmployerShort } from '../../admin/redux/admin.state';

export const TrainingActionTypes = {
  CHAPTER_FETCH_START: 'CHAPTER_FETCH_START',
  CHAPTER_FETCH_SUCCESS: 'CHAPTER_FETCH_SUCCESS',
  CHAPTER_FETCH_FAILURE: 'CHAPTER_FETCH_FAILURE',

  CLEAR_COURSE: 'CLEAR_COURSE',
  CLEAR_PROFILE_QUESTION: 'CLEAR_PROFILE_QUESTION',

  SET_COURSE_CODE: 'SET_COURSE_CODE',

  GET_CURRENT_TOKEN: 'GET_CURRENT_TOKEN',
  SET_CURRENT_TOKEN: 'SET_CURRENT_TOKEN',
  SET_CURRENT_TOKEN_BY_ID: 'SET_CURRENT_TOKEN_BY_ID',

  GET_CURRENT_COURSE: 'GET_CURRENT_COURSE',

  SET_SYLLABUS_HIDE: 'SET_SYLLABUS_HIDE',

  CART_ADD: 'CART_ADD',
  CART_FETCH_START: 'CART_FETCH_START',
  CART_FETCH_SUCCESS: 'CART_FETCH_SUCCESS',
  CART_FETCH_FAILURE: 'CART_FETCH_FAILURE',

  COURSE_FETCH_START: 'COURSE_FETCH_START',
  COURSE_TOKENS_FETCH_START: 'COURSE_TOKENS_FETCH_START',
  COURSE_FETCH_SUCCESS: 'COURSE_FETCH_SUCCESS',
  COURSE_FETCH_FAILURE: 'COURSE_FETCH_FAILURE',

  COURSES_FETCH_START: 'COURSES_FETCH_START',
  COURSES_FETCH_SUCCESS: 'COURSES_FETCH_SUCCESS',
  COURSES_TOKENS_FETCH_SUCCESS: 'COURSES_TOKENS_FETCH_SUCCESS',
  COURSES_FETCH_FAILURE: 'COURSES_FETCH_FAILURE',

  FINAL_FETCH_START: 'FINAL_FETCH_START',
  FINAL_FETCH_SUCCESS: 'FINAL_FETCH_SUCCESS',
  FINAL_FETCH_FAILURE: 'FINAL_FETCH_FAILURE',

  PAGE_FETCH_START: 'PAGE_FETCH_START',
  PAGE_FETCH_SUCCESS: 'PAGE_FETCH_SUCCESS',
  PAGE_FETCH_FAILURE: 'PAGE_FETCH_FAILURE',
  PAGE_JUMP_TO_POSITION: 'PAGE_JUMP_TO_POSITION',
  PAGE_SET_TEXT: 'PAGE_SET_TEXT',
  PAGE_SET_POSITION: 'PAGE_SET_POSITION',

  QUIZ_FETCH_START: 'QUIZ_FETCH_START',
  QUIZ_FETCH_SUCCESS: 'QUIZ_FETCH_SUCCESS',
  QUIZ_FETCH_FAILURE: 'QUIZ_FETCH_FAILURE',
  QUIZ_SCORE_SUCCESS: 'QUIZ_SCORE_SUCCESS',
  QUIZ_SCORE_FAILURE: 'QUIZ_SCORE_FAILURE',

  SECURITY_SUCCESS: 'SECURITY_SUCCESS',
  SECURITY_CONFIRM_FAILURE: 'SECURITY_CONFIRM_FAILURE',

  TOKENS_ADD: 'TOKENS_ADD',
  TOKENS_FETCH_START: 'TOKENS_FETCH_START',
  TOKENS_FETCH_SUCCESS: 'TOKENS_FETCH_SUCCESS',
  TOKENS_FETCH_FAILURE: 'TOKENS_FETCH_FAILURE',

  INVITE_ERROR: 'INVITE_ERROR'
};

export enum CourseStatus {
  New = 'new',
  Available = 'available',
  Invited = 'invited',
  NotYet = 'not-yet',
  InProgress = 'in-progress',
  FinalQuiz = 'final-quiz',
  SecurityConfirmResultFail = 'security-course-lock',
  Complete = 'completed',
  LoggedIn = 'logged-in',
  QuizResultSectionLock = 'quiz-section-lock',
  QuizResultCourseLock = 'quiz-course-lock',
  Marketing = 'marketing'
}

export enum CourseShow {
  Course = 'course',
  End = 'end',
  FinalQuiz = 'final-quiz',
  FinalResult = 'final-result',
  SecurityInitial = 'security-initial',
  SecurityInitialResult = 'security-result',
  SecurityAnswers = 'security-answers',
  SecurityConfirm = 'security-confirm',
  SecurityConfirmResultPass = 'security-confirm-result-pass',
  SecurityConfirmResultFail = 'security-course-lock',
  Profile = 'profile',
  ProfileResult = 'profile-result',
  Quiz = 'quiz',
  QuizResultSectionLock = 'quiz-section-lock',
  QuizResultCourseLock = 'quiz-course-lock',
  QuizResult = 'quiz-result',
  SectionQuiz = 'section-quiz'
}

export interface TokenModel {
  success: boolean;
  data: Token[];
}

export interface TokenResponse {
  success: boolean;
  data: Token;
}

export class Token {
  id: string;
  user_id: string;
  course: string;
  created_at: Date | number;
  redeemed_at: number;
  completed_at: Date | number;
  expires_at: Date | number;
  renewal_eligible_at: Date | number;
  token_type: string;
  status: string;
  on_page: string;
  on_page_id: string;
  code_used?: boolean;
  score_results: {
    score: number;
    passed: boolean;
    type: CourseStatus;
  };
  status_percentage: number;
  record_locator?: string;
  elapsed_time: number;
  language: string;
  security: {
    initial: boolean;
    verify: boolean;
    timestamp?: number;
  };

  // Additional items we use locally not saved
  currentPageIndex = 0;
  currentChapterIndex = 0;
  currentSectionIndex = 0;
  currentSequenceNumber = 0;
  highestSection = 1;
  highestChapter: number;
  highestPageId: number;
  show: string = CourseShow.Course;
  questions: Question[];
  purchased_by_subscriber_id?: string;
}

export interface Cart {
  id?: string;
  course: string;
  qty: number;
  type: string;
  items?: CourseItem[];
  total?: number;
  paymentIntent?: string;
  clientSecret?: string;
  auto?: string;
}

export interface CourseList {
  id: string;
  title: string;
  lineItems: CourseItem[];
  description: string;
  purchase: string;
  language: string;
  image: string;
  price: number;
  state: string;
  status: CourseStatus;
  tokens: Token[];
  certificate_id: string;
}

export interface CourseItem {
  [key: string]: string;
}

export class Question {
  id: string;
  attached: string;
  question: string;
  correct?: string;
  incorrect_1?: string;
  incorrect_2?: string;
  incorrect_3?: string;
  explanation?: string;
  answers: any[];
  chapter?: string;
  section?: string;
}

export class PageClip {
  static PAGE = 'page';
  static QUESTION = 'question';
  static PROFILE = 'profile';
  static SECURITY = 'security';
  static VERIFY = 'verify';

  id: string;
  sequence_no: number;
  slug: string;
  title: string;
  chapter: number;
  section: number;
  type: string;
  question?: Question;

  contents?: string | Question;
  image?: string;
  image_position?: string;
}

export interface Course {
  locations?: IEmployerShort[];
  id: string;
  chapters: CoursePathChapter[];
  sections: Sections[];
  sequence: Sequence[];
  course_path: CoursePath[];
  profile: string[];
}

export interface Sections {
  name: string;
  id: string;
  order: number;
}

export interface Sequence {
  slug: string;
  id: string;
}

export interface CoursePath {
  section: object;
  chapters: CoursePathChapter[];
  questions: {
    security: boolean;
    reinforcement: boolean;
  };
}

export interface CoursePathChapter {
  id: string;
  name: string;
  index: number;
  section: {
    name: string;
    id: string;
    order: number;
  };
  pages?: PageClip[];
  questions: {
    security: boolean;
    reinforcement: boolean;
  };
}

export interface ISecurityQuestions {
  initial: Question[];
  a1: { id: string; answer: string; answers: string[] }[];
  a2: { id: string; answer: string; answers: string[] }[];
  b1: { id: string; answer: string; answers: string[] }[];
  b2: { id: string; answer: string; answers: string[] }[];
  c1: { id: string; answer: string; answers: string[] }[];
  c2: { id: string; answer: string; answers: string[] }[];
  d1: { id: string; answer: string; answers: string[] }[];
  d2: { id: string; answer: string; answers: string[] }[];
}
