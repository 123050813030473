import { Component, Input, OnInit } from '@angular/core';
import { LoginStep } from '../../shared/models/login-step.model';
import { ApiService, Service } from '../../service/api.service';

@Component({
  selector: 'app-cent',
  templateUrl: './cent.component.html',
  styleUrls: ['./cent.component.scss']
})
export class CentComponent implements OnInit {
  @Input() loggingIn = false;
  @Input() loggedIn = false;
  @Input() welcome = false;
  @Input() forgot = false;
  @Input() timeout = false;
  @Input() loginStep = LoginStep.NEW;
  @Input() loginStepChoices = LoginStep;

  showPlug;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService
      .getUrl(Service.PUBLIC, `/home-page/recent`)
      .then((response) => (this.showPlug = response.data))
      .catch((response) => {
        console.log(response);
      });
  }

  setChoice($event: any) {}
}
