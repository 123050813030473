/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logged-in-inset.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./logged-in-inset.component";
var styles_LoggedInInsetComponent = [i0.styles];
var RenderType_LoggedInInsetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedInInsetComponent, data: {} });
export { RenderType_LoggedInInsetComponent as RenderType_LoggedInInsetComponent };
function View_LoggedInInsetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "form-group mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "button", [["class", "btn btn-secondary btn-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/training/course-list"); _ck(_v, 2, 0, currVal_0); var currVal_3 = _ck(_v, 6, 0, "/training/course-list"); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("courses.courses-menu")); _ck(_v, 7, 0, currVal_4); }); }
function View_LoggedInInsetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "form-group mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["class", "btn btn-secondary btn-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["Business Dashboard"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/admin/tokens"); _ck(_v, 2, 0, currVal_0); var currVal_3 = _ck(_v, 6, 0, "/admin/tokens"); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_LoggedInInsetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "login-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "form", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["class", "sign-in social text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LoggedInInsetComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LoggedInInsetComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 5, "p", [["class", "text-center text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [["class", "dropdown-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = !i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.isManager$)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.isManager$)); _ck(_v, 9, 0, currVal_2); var currVal_5 = _ck(_v, 14, 0, "/logout"); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("home.already-logged-in")); _ck(_v, 3, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 13).target; var currVal_4 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("menu.logout")); _ck(_v, 15, 0, currVal_6); }); }
export function View_LoggedInInsetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logged-in", [], null, null, null, View_LoggedInInsetComponent_0, RenderType_LoggedInInsetComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoggedInInsetComponent, [], null, null)], null, null); }
var LoggedInInsetComponentNgFactory = i1.ɵccf("app-logged-in", i5.LoggedInInsetComponent, View_LoggedInInsetComponent_Host_0, {}, {}, []);
export { LoggedInInsetComponentNgFactory as LoggedInInsetComponentNgFactory };
