import { CourseShow, CourseStatus, Token, TrainingActionTypes } from './training.types';
import { INITIAL_STATE } from './training.state';
import {
  applyScoring,
  initializeToken,
  removeProfileQuestion,
  updateCoursesBasedOnTokens,
  upsertTokens
} from './training.utils';

let tokens;

const trainingReducer = (state = INITIAL_STATE, action) => {
  let currentToken: Token;

  switch (action.type) {
    case TrainingActionTypes.CART_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingCart: false
      };
    case TrainingActionTypes.CART_FETCH_START:
      return {
        ...state,
        error: undefined,
        isLoadingCart: true
      };
    case TrainingActionTypes.CART_FETCH_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        error: undefined,
        isLoadingCart: false
      };
    case TrainingActionTypes.CHAPTER_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case TrainingActionTypes.CHAPTER_FETCH_START:
      return {
        ...state,
        isLoadingChapter: true,
        chapter: undefined,
        error: null
      };
    case TrainingActionTypes.CHAPTER_FETCH_SUCCESS:
      const chapter = action.payload.data;
      chapter.index = action.payload.index;
      return {
        ...state,
        chapter,
        isLoadingChapter: false,
        error: null
      };
    case TrainingActionTypes.CLEAR_PROFILE_QUESTION:
      return {
        ...state,
        course: removeProfileQuestion(state.course, action.payload),
        currentToken: {
          ...state.currentToken,
          show: CourseShow.ProfileResult
        }
      };
    case TrainingActionTypes.COURSE_FETCH_FAILURE:
      return {
        ...state,
        error: false,
        isLoadingCourse: false
      };
    case TrainingActionTypes.COURSE_FETCH_START:
      return {
        ...state,
        course: undefined,
        coursesTokens: undefined,
        chapter: undefined,
        isLoadingCourse: true
      };
    case TrainingActionTypes.COURSE_FETCH_SUCCESS:
      return {
        ...state,
        course: action.payload,
        isLoadingCourse: false
      };
    case TrainingActionTypes.COURSES_FETCH_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        isLoadingCourse: false
      };
    case TrainingActionTypes.COURSES_TOKENS_FETCH_SUCCESS:
      return {
        ...state,
        coursesTokens: action.payload,
        isLoadingCourse: false
      };
    case TrainingActionTypes.FINAL_FETCH_FAILURE:
      return {
        ...state,
        isLoadingFinal: false,
        error: action.payload
      };
    case TrainingActionTypes.FINAL_FETCH_START:
      return {
        ...state,
        isLoadingFinal: true,
        currentToken: {
          ...state.currentToken,
          questions: undefined
        }
      };
    case TrainingActionTypes.FINAL_FETCH_SUCCESS:
      return {
        ...state,
        isLoadingFinal: false,
        currentToken: {
          ...state.currentToken,
          questions: action.payload,
          show: CourseShow.FinalQuiz,
          status: CourseShow.FinalQuiz
        }
      };
    case TrainingActionTypes.SET_SYLLABUS_HIDE:
      return {
        ...state,
        hideSyllabus: !state.hideSyllabus
      };
    case TrainingActionTypes.PAGE_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case TrainingActionTypes.PAGE_FETCH_SUCCESS:
      return {
        ...state,
        currentPageClip: action.payload,
        isLoadingPage: false,
        error: null
      };
    case TrainingActionTypes.PAGE_FETCH_START:
      return {
        ...state,
        isLoadingPage: true,
        error: null
      };
    case TrainingActionTypes.PAGE_JUMP_TO_POSITION:
      return {
        ...state,
        currentToken: {
          ...state.currentToken,
          currentPageIndex: action.payload.page,
          currentChapterIndex: action.payload.chapter,
          currentSectionIndex: action.payload.section,
          show: CourseShow.Course
          // on_page: state.syllabus ? state.syllabus[action.payload.section].pages[action.payload.page].id : undefined
        }
      };
    case TrainingActionTypes.PAGE_SET_POSITION:
      return {
        ...state,
        currentToken: {
          ...state.currentToken,
          currentPageIndex: action.payload.page,
          currentChapterIndex: action.payload.chapter,
          currentSectionIndex: action.payload.section,
          show: CourseShow.Course
          // on_page: state.currentToken.course ?
          // state.currentToken.course[action.payload.section].pages[action.payload.page].id : undefined
        }
      };
    case TrainingActionTypes.PAGE_SET_TEXT:
      return {
        ...state,
        currentPageClip: state.chapter ? state.chapter.pages[action.payload] : state.currentPageClip
      };
    case TrainingActionTypes.QUIZ_FETCH_START:
      return {
        ...state,
        error: undefined
      };
    case TrainingActionTypes.QUIZ_FETCH_SUCCESS:
      return {
        ...state,
        error: undefined,
        currentToken: {
          ...state.currentToken,
          questions: action.payload.questions,
          security: {
            ...state.currentToken.security,
            timestamp: action.payload.timestamp
          }
        }
      };
    case TrainingActionTypes.QUIZ_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case TrainingActionTypes.QUIZ_SCORE_SUCCESS:
      currentToken = {
        ...state.currentToken,
        score_results: action.payload.score ? action.payload.score : undefined,
        show: CourseShow.QuizResult,
        status:
          action.payload.passed && action.payload.type === CourseStatus.FinalQuiz
            ? CourseStatus.Complete
            : state.currentToken.status,
        questions: state.currentToken.questions
          ? applyScoring(state.currentToken.questions, action.payload)
          : undefined
      };
      tokens = upsertTokens(state.tokens, currentToken);
      return {
        ...state,
        error: undefined,
        currentToken,
        tokens,
        coursesTokens: state.courses
          ? updateCoursesBasedOnTokens(state.courses, tokens)
          : state.coursesTokens
      };
    case TrainingActionTypes.QUIZ_SCORE_FAILURE:
      currentToken = {
        ...state.currentToken,
        questions: state.currentToken.questions
          ? applyScoring(state.currentToken.questions, action.payload.results)
          : undefined,
        score_results:
          action.payload.results && action.payload.results.score
            ? action.payload.results.score
            : undefined,
        show: action.payload.show
      };
      tokens = upsertTokens(state.tokens, currentToken);
      return {
        ...state,
        error: undefined,
        currentToken,
        tokens,
        coursesTokens: state.courses
          ? updateCoursesBasedOnTokens(state.courses, tokens)
          : state.coursesTokens
      };

    case TrainingActionTypes.SECURITY_CONFIRM_FAILURE:
      currentToken = {
        ...state.currentToken,
        questions: undefined,
        score_results: undefined,
        show: CourseShow.SecurityConfirmResultFail,
        status: CourseShow.SecurityConfirmResultFail
      };
      tokens = upsertTokens(state.tokens, currentToken);
      return {
        ...state,
        error: undefined,
        currentToken,
        tokens,
        coursesTokens: state.courses
          ? updateCoursesBasedOnTokens(state.courses, tokens)
          : state.coursesTokens
      };
    case TrainingActionTypes.SECURITY_SUCCESS:
      currentToken = {
        ...state.currentToken,
        questions: undefined,
        score_results: undefined,
        show: CourseShow.SecurityInitialResult,
        security: {
          initial: false,
          verify: true
        }
      };
      tokens = upsertTokens(state.tokens, currentToken);
      return {
        ...state,
        error: undefined,
        currentToken,
        tokens,
        coursesTokens: state.courses
          ? updateCoursesBasedOnTokens(state.courses, tokens)
          : state.coursesTokens
      };
    case TrainingActionTypes.SET_CURRENT_TOKEN:
      currentToken = action.payload;
      currentToken.currentChapterIndex = currentToken.currentChapterIndex || 0;
      currentToken.currentPageIndex = currentToken.currentPageIndex || 0;
      currentToken.currentSectionIndex = currentToken.currentSectionIndex || 0;
      currentToken.show = currentToken.show || CourseShow.Course;
      tokens = upsertTokens(state.tokens, currentToken);
      return {
        ...state,
        currentToken: { ...currentToken },
        tokens,
        coursesTokens: state.courses
          ? updateCoursesBasedOnTokens(state.courses, tokens)
          : state.coursesTokens
      };
    case TrainingActionTypes.SET_CURRENT_TOKEN_BY_ID:
      return {
        ...state,
        currentToken: state.tokens.find((item) => item.id === action.payload)
      };
    case TrainingActionTypes.TOKENS_ADD:
      tokens = upsertTokens(state.tokens || [], initializeToken(action.payload));
      return {
        ...state,
        tokens,
        coursesTokens: state.courses
          ? updateCoursesBasedOnTokens(state.courses, tokens)
          : state.coursesTokens
      };
    case TrainingActionTypes.TOKENS_FETCH_START:
      return {
        ...state,
        tokens: [],
        tokensLoading: true
      };
    case TrainingActionTypes.TOKENS_FETCH_SUCCESS:
      tokens = action.payload ? action.payload.map((item) => initializeToken(item)) : [];
      return {
        ...state,
        tokens: action.payload,
        error: undefined,
        tokensLoading: false
      };
    case TrainingActionTypes.TOKENS_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        tokensLoading: false
      };
    case TrainingActionTypes.INVITE_ERROR:
      return {
        ...state,
        inviteError: action.payload
      };
    default:
      return state;
  }
};

export default trainingReducer;
