import AuthTypes from './auth.types';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../app.module';
import { ApiService, Service } from '../../service/api.service';
import * as jwtDecode from 'jwt-decode';
import { ApiResponse } from '../../shared/models/api-response.model';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ILogin, IMe, IUser } from './auth.state';
import { GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { GoogleService } from 'social-login-oauth';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from '../../shared/string-helper';
import { UserStatus } from '../../shared/models/user-status.model';

const { detect } = require('detect-browser');

@Injectable()
export class AuthActions {
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private router: Router,
    private apiService: ApiService,
    private cookieService: CookieService,
    private translate: TranslateService
  ) {}

  changeLanguage = (language) => ({
    type: AuthTypes.CHANGE_LANGUAGE,
    payload: language
  });

  errorClear = () => ({
    type: AuthTypes.ERROR_CLEAR,
    payload: null
  });

  errorSet = (error) => ({
    type: AuthTypes.ERROR_SET,
    payload: error
  });

  loginSuccess = (user) => {
    try {
      console.log(user);
      localStorage.setItem('jwt', user.claims.token);
      localStorage.setItem('id', user.claims.sub);
      console.log(`LOGIN user_id ${user.claims.sub}`);
      return {
        type: AuthTypes.LOGIN,
        payload: user
      };
    } catch (error) {
      console.error(error);
      alert(
        'Browser error attempting to write cookies for login. Disabling cookies will cause this website to error.'
      );
    }
  };

  setEmployer = (employer_object: Address) => ({
    type: AuthTypes.SET_EMPLOYER,
    payload: employer_object
  });

  setSecondary = () => ({
    type: AuthTypes.SET_SECONDARY
  });

  setBounced = (value: boolean) => ({
    type: AuthTypes.SET_BOUNCED,
    payload: value
  });

  clearEmployer = () => ({
    type: AuthTypes.SET_EMPLOYER_CLEAR
  });

  logout = () => ({
    type: AuthTypes.LOGOUT
  });

  checkJwt = () => ({
    type: AuthTypes.CHECK_JWT
  });

  userSet = (objUser: IMe) => ({
    type: AuthTypes.USER_SET,
    payload: objUser
  });

  userSetField = (field: string, value: any) => ({
    type: AuthTypes.USER_SET_FIELD,
    payload: { field, value }
  });

  userFailure = (error) => ({
    type: AuthTypes.PROFILE_SAVE_FAILURE,
    payload: error
  });

  setBrowserInfo = (data) => ({
    type: AuthTypes.SET_BROWSER,
    payload: data
  });

  signUpNextStep = (step?: number) => {
    return {
      type: AuthTypes.SIGNUP_NEXT_STEP,
      payload: step ? step : undefined
    };
  };

  userStartAction = () => ({
    type: AuthTypes.USER_ACTION_START
  });

  userStopAction = () => ({
    type: AuthTypes.USER_ACTION_END
  });

  changeLanguageAsync = (language) => {
    return (dispatch, state) => {
      dispatch(this.changeLanguage(language));
      this.translate.use(language);
      const globalState = state();
      if (globalState.auth.user) {
        const objUser: IMe = {
          ...globalState.auth.user,
          language
        };
        dispatch(this.userProfileSaveAsync(objUser));
      }
    };
  };

  checkJwtRefreshAsync = (forceRenewal = false, callback?) => {
    return (dispatch) => {
      let exp = 0,
        now = 0;
      const token = localStorage.getItem('jwt');
      if (!forceRenewal) {
        const objUser: IUser = jwtDecode(token);
        exp = objUser.exp;
        now = Date.now().valueOf() / 1000;
      }
      if (forceRenewal || exp - 300 < now) {
        if (!forceRenewal) {
          console.log('expired token');
          dispatch(this.logout());
          this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
          return;
        }
        if (forceRenewal) {
          console.log('forced refresh');
        }
        this.apiService
          .getUrl(Service.PUBLIC, '/users/refresh')
          .then((response: ApiResponse) => {
            console.log(response);
            if (response.statusCode) {
              switch (response.statusCode) {
                case 200:
                  const data: ILogin = <ILogin>response.data;
                  console.log(data);
                  dispatch(this.loginSuccess(data));
                  this.translate.use(data.me.language || 'en');
                  if (callback) {
                    callback();
                  }
                  break;
                case 403:
                  dispatch(this.logout());
                  this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
                  break;
                default:
                  dispatch(this.logout());
              }
            } else {
              const data: ILogin = <ILogin>response.data;
              dispatch(this.loginSuccess(data));
            }
          })
          .catch((response) => {
            console.log(response);
            if (response.statusCode) {
              switch (response.statusCode) {
                case 403:
                  dispatch(this.logout());
                  this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
                  break;
                default:
                  dispatch(this.logout());
              }
            }
          });
      }
    };
  };

  checkBounceBackAsync = () => {
    return (dispatch, state) => {
      const globalState = state();
      let email =
        globalState.auth.user && globalState.auth.user.email
          ? globalState.auth.user.email
          : undefined;
      if (email) {
        this.apiService
          .postUrl(Service.PUBLIC, '/user/confirmed', { email }, false)
          .then((response) => {
            if (response.data === UserStatus.BOUNCED) {
              dispatch(this.setBounced(true));
            }
            if (response.data === UserStatus.CONFIRMED) {
              dispatch(this.setBounced(false));
              if (!globalState.auth.user.email_verified) {
                dispatch(this.checkJwtRefreshAsync(true));
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };
  };

  findLocationAsync = () => {
    return async (dispatch) => {
      const objData = {};
      try {
        const browser = detect();
        if (browser) {
          objData['browser'] = browser.name + ',' + browser.version + ',' + browser.os;
          objData['browser'] = navigator.userAgent;
        }
        dispatch(this.setBrowserInfo(navigator.userAgent));
      } catch (error) {
        // ignore if blocked
      }

      try {
        let locationResult: any = await this.apiService.getIUrl(
          'https://api.ipdata.co/?api-key=5e2dd317db394a508da972257841d91027a2d032aaba3f9f04ef2e2c'
        );
        objData['location'] = locationResult.city + ', ' + locationResult.region_code;
      } catch (error) {
        // ignore if blocked
      }

      try {
        this.apiService.postUrl(Service.PUBLIC, '/users/me', objData).then().catch();
      } catch (error) {
        // ignore if blocked
      }
    };
  };

  userLoginAsync = (email: string, password: string) => {
    console.log(`👍 userLoginAsync`);
    return (dispatch) => {
      dispatch(this.userStartAction());
      this.apiService
        .postUrl(Service.PUBLIC, '/users/login', { email, password })
        .then((response) => {
          console.log(response);
          if (response.success) {
            const data: ILogin = response.data;
            dispatch(this.loginSuccess(data));
            dispatch(this.findLocationAsync());
            dispatch(this.userStopAction());
          } else {
            dispatch(this.errorSet(response.error));
            dispatch(this.userStopAction());
          }
        })
        .catch((response) => {
          console.log(response);
          dispatch(this.errorSet(response.error.error));
          dispatch(this.userStopAction());
        });
    };
  };

  userLoginThirdPartyAsync = (provider: string) => {
    console.log(`👍 userLoginThirdPartyAsync`);
    return (dispatch) => {
      dispatch(this.userStartAction());
      console.log('this.socialLogin.signIn');
      console.log(GoogleLoginProvider.PROVIDER_ID);

      GoogleService.signIn(environment.googleLogin)
        .then((socialResponse: SocialUser) => {
          this.apiService
            .postUrl(Service.PUBLIC, `/users/login/${provider}`, socialResponse)
            .then((response) => {
              console.log(response);
              if (response.success) {
                const data: ILogin = response.data;
                dispatch(this.loginSuccess(data));
                dispatch(this.findLocationAsync());
                dispatch(this.userStopAction());
              } else {
                dispatch(this.errorSet(response.error));
                dispatch(this.userStopAction());
              }
            })
            .catch((error) => {
              console.log(error);
              dispatch(this.errorSet(error.message));
              dispatch(this.userStopAction());
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.errorSet(error.details));
          dispatch(this.userStopAction());
        });
    };
  };

  userRegisterAsync = (profile) => {
    console.log(`👍 userRegisterAsync`);
    return (dispatch) => {
      dispatch(this.userStartAction());

      this.apiService
        .postUrl(Service.PUBLIC, '/users', profile, false)
        .then((response) => {
          if (response.success) {
            setTimeout(() => {}, 2000);
            dispatch(this.userLoginAsync(profile.email, profile.password));
          } else {
            dispatch(this.errorSet(response.error));
            dispatch(this.userStopAction());
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.userFailure(StringHelper.extractError(error)));
        });
    };
  };

  UserResendVerifyEmailAsync = (email?: string) => {
    return async (dispatch) => {
      try {
        let response: ApiResponse = await this.apiService.postUrl(
          Service.PUBLIC,
          '/users/verify-email',
          { email }
        );
        if (response.data.refresh) {
          dispatch(this.checkJwtRefreshAsync(true));
        }
        return true;
      } catch (error) {
        console.error(error);
        alert(StringHelper.extractError(error));
        return false;
      }
    };
  };

  userProfileSaveAsync = (profile, nextStep?: number) => {
    return async (dispatch) => {
      dispatch(this.userStartAction());

      try {
        let response: ApiResponse = await this.apiService.postUrl(
          Service.PUBLIC,
          '/users/me',
          profile
        );
        dispatch(this.userSet(<IMe>response.data));
        dispatch(this.checkJwtRefreshAsync(true));
        dispatch(this.userStopAction());
        if (nextStep) {
          this.ngRedux.dispatch(this.signUpNextStep(nextStep));
        }
        return AuthTypes.SUCCESS;
      } catch (error) {
        console.log('userProfileSaveAsync error');
        console.log(error);
        dispatch(this.userFailure(StringHelper.extractError(error)));
        dispatch(this.userStopAction());
        return AuthTypes.FAILURE;
      }
    };
  };

  validateSession = () => {
    return (dispatch, state) => {
      const globalState = state();
      if (globalState.auth.user && !localStorage.getItem('jwt')) {
        // Somehow we have a logged in user yet no token, so run a logout
        console.log('validateSession finds user with no jwt token');
        dispatch(this.logout());
      }
      if (globalState.auth.user && localStorage.getItem('jwt')) {
        const objUser: IUser = jwtDecode(localStorage.getItem('jwt'));
        if (objUser.exp < Date.now().valueOf() / 1000) {
          console.log('validateSession finds Expired token');
          this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
          dispatch(this.logout());
        }
      }
    };
  };
}
