import { Token } from '../../training/redux/training.types';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

let lang = navigator.language || 'en';
lang = lang.substr(0, 2);
// If your default language is not one we support, go to English
if (!['en', 'es'].includes(lang)) {
  lang = 'en';
}

export interface IAuthState {
  blnRunningAction: boolean;
  bounced: boolean;
  browserInfo: string;
  claims: IClaims;
  error: string;
  language: string;
  loggedIn: boolean;
  signUpStep: number;
  tokens: Token[];
  user: IMe;
}

export const INITIAL_STATE: IAuthState = {
  blnRunningAction: false,
  bounced: false,
  browserInfo: undefined,
  claims: undefined,
  error: undefined,
  language: lang || 'en',
  loggedIn: false,
  signUpStep: 1,
  tokens: [],
  user: undefined
};

export interface IPasswordChange {
  password: string;
  passwordConfirm: string;
}

export interface ILogin {
  claims: IClaims;
  me: IMe;
  tokens?: Token[];
}

export interface IClaims {
  email: string;
  employer_id: string;
  employer_name: string;
  first_name: string;
  is_manager: boolean;
  language: string;
  last_name: string;
  name: string;
  profile_pic: string;
  sub: string;
  subscriber_id: string;
  token: string;
}

export interface IMe {
  birth_date: string;
  birth_year: string;
  created_at: number;
  email: string;
  emailBounced: boolean;
  email_verified: boolean;
  do_not_email_marketing: boolean;
  do_not_email_expiration: boolean;
  employer: string;
  employer_object: Address;
  first_name: string;
  id: string;
  language: string;
  last_logged_on: number;
  last_name: string;
  manager_email: string;
  manager_name: string;
  manager_phone: string;
  name: string;
  password_last_set: number;
  phone_number: string;
  phone_type: string;
  profile_pic?: string;
  status: string;
  tabc: string;
  twitter_handle?: string;
  secondary_id: string;
  secondary_id_required: boolean;
}

export interface TempUser {
  sub: string;
  email: string;
  password: string;
}

export interface IUser {
  sub: string;
  name: string;
  language: string;
  email: string;
  exp: number;
  iat: number;
  profile_pic?: string;
  is_manager?: boolean;
  employer_name?: string;
}
