/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/bounced-alert/bounced-alert.component.ngfactory";
import * as i3 from "./shared/bounced-alert/bounced-alert.component";
import * as i4 from "@angular/common";
import * as i5 from "./shared/navbar/navbar.component.ngfactory";
import * as i6 from "./shared/navbar/navbar.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i9 from "./auth/redux/auth.actions";
import * as i10 from "./shared/footer/footer.component.ngfactory";
import * as i11 from "./shared/footer/footer.component";
import * as i12 from "./app.component";
import * as i13 from "angulartics2/ga";
import * as i14 from "angulartics2";
import * as i15 from "@ngx-translate/core";
import * as i16 from "./shared/sticky-service";
import * as i17 from "./training/redux/training.actions";
import * as i18 from "ngx-cookie-service";
import * as i19 from "./service/api.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "message message-alert"], ["data-id", "system-alert-new-deploy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "message-icon fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The logged in user has changed on another tab or window."])), (_l()(), i1.ɵted(-1, null, [" Please click "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["reload"])), (_l()(), i1.ɵted(-1, null, [" to reload user session. "]))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bounced-alert", [], null, null, null, i2.View_BouncedAlertComponent_0, i2.RenderType_BouncedAlertComponent)), i1.ɵdid(1, 245760, null, 0, i3.BouncedAlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "message message-alert"], ["data-id", "system-alert-new-deploy"], ["style", "width: 330px; opacity: 1; position: relative; left: 0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "message-icon fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your browser has an old version of 99CentFoodHandler cached."])), (_l()(), i1.ɵted(-1, null, [" Please click "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["reload"])), (_l()(), i1.ɵted(-1, null, [" to use our updated site. "]))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-navbar", [], null, null, null, i5.View_NavbarComponent_0, i5.RenderType_NavbarComponent)), i1.ɵdid(3, 245760, null, 0, i6.NavbarComponent, [i4.Location, i7.Router, i8.NgRedux, i9.AuthActions], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i11.FooterComponent, [i7.Router], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNewUser; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.bounced$)); _ck(_v, 5, 0, currVal_1); _ck(_v, 8, 0); _ck(_v, 10, 0); var currVal_2 = _co.showNewVersion; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 1294336, null, 0, i12.AppComponent, [i1.Renderer2, i7.Router, i13.Angulartics2GoogleAnalytics, i14.Angulartics2, i15.TranslateService, i4.DOCUMENT, i16.StickyService, i1.ElementRef, i4.Location, i8.NgRedux, i9.AuthActions, i17.TrainingActions, i18.CookieService, i19.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
