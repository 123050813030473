const AuthTypes = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHECK_JWT: 'CHECK_JWT',
  ERROR_CLEAR: 'ERROR_CLEAR',
  ERROR_SET: 'ERROR_SET',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_EMPLOYER: 'SET_EMPLOYER',
  SET_SECONDARY: 'SET_SECONDARY',
  SET_BOUNCED: 'SET_BOUNCED',
  SET_EMPLOYER_CLEAR: 'SET_EMPLOYER_CLEAR',
  SET_BROWSER: 'SET_BROWSER',
  PROFILE_SAVE_FAILURE: 'PROFILE_SAVE_FAILURE',
  SIGNUP_NEXT_STEP: 'SIGNUP_NEXT_STEP',
  USER_ACTION_END: 'USER_ACTION_END',
  USER_ACTION_START: 'USER_ACTION_START',
  USER_SET: 'USER_SET',
  USER_SET_FIELD: 'USER_SET_FIELD',
  SUCCESS: 'success',
  FAILURE: 'failure'
};

export default AuthTypes;
