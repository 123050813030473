import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/internal/Observable';
import { selectIsBounced, selectIsLoggedIn, selectIsManager, selectLanguage, selectUser, selectUserName } from '../../auth/redux/auth.selectors';
import { AuthActions } from '../../auth/redux/auth.actions';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(location, router, ngRedux, authActions) {
        this.location = location;
        this.router = router;
        this.ngRedux = ngRedux;
        this.authActions = authActions;
        this.subscriptions = [];
        this.isCollapsed = true;
        this.features = 'Features';
        this.yScrollStack = [];
        this.environment = environment;
        this.branding = environment.branding;
        if (this.branding === 'cent') {
            this.features = 'For Businesses';
        }
    }
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.name$.subscribe(function (name) {
            _this.name = name;
        }));
        this.subscriptions.push(this.user$.subscribe(function (user) {
            if (user && user.employer) {
                _this.employer = user.employer.substring(0, 13);
            }
            else {
                _this.employer = undefined;
            }
        }));
        this.subscriptions.push(this.loggedIn$.subscribe(function (loggedIn) {
            _this.loggedIn = loggedIn;
        }));
        this.subscriptions.push(this.isManager$.subscribe(function (isManager) {
            _this.isManager = isManager;
        }));
        this.subscriptions.push(this.language$.subscribe(function (language) {
            _this.language = language;
        }));
        this.subscriptions.push(this.router.events.subscribe(function (event) {
            _this.isCollapsed = true;
            if (event instanceof NavigationStart) {
                if (event.url !== _this.lastPoppedUrl) {
                    _this.yScrollStack.push(window.scrollY);
                }
            }
            else if (event instanceof NavigationEnd) {
                if (event.url === _this.lastPoppedUrl) {
                    _this.lastPoppedUrl = undefined;
                    window.scrollTo(0, _this.yScrollStack.pop());
                }
                else {
                    window.scrollTo(0, 0);
                }
            }
        }));
        this.subscriptions.push(this.location.subscribe(function (ev) {
            _this.lastPoppedUrl = ev.url;
        }));
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    NavbarComponent.prototype.changeLanguage = function (language) {
        this.ngRedux.dispatch(this.authActions.changeLanguageAsync(language));
    };
    NavbarComponent.prototype.isHome = function () {
        var title = this.location.prepareExternalUrl(this.location.path());
        return title === '/';
    };
    NavbarComponent.prototype.isTraining = function () {
        var title = this.location.prepareExternalUrl(this.location.path());
        return title.substr(0, 14) === '/training/play';
    };
    NavbarComponent.prototype.inProgress = function () {
        return localStorage.getItem('url');
    };
    NavbarComponent.prototype.isStudent = function () {
        var title = this.location.prepareExternalUrl(this.location.path());
        return title.substr(0, 8) === '/student';
    };
    NavbarComponent.prototype.isDocumentation = function () {
        var title = this.location.prepareExternalUrl(this.location.path());
        return title === '/documentation';
    };
    NavbarComponent.prototype.isDashboard = function () {
        return this.router.url.substr(0, 6) === '/admin';
    };
    tslib_1.__decorate([
        select(selectIsLoggedIn),
        tslib_1.__metadata("design:type", Observable)
    ], NavbarComponent.prototype, "loggedIn$", void 0);
    tslib_1.__decorate([
        select(selectIsManager),
        tslib_1.__metadata("design:type", Observable)
    ], NavbarComponent.prototype, "isManager$", void 0);
    tslib_1.__decorate([
        select(selectLanguage),
        tslib_1.__metadata("design:type", Observable)
    ], NavbarComponent.prototype, "language$", void 0);
    tslib_1.__decorate([
        select(selectUser),
        tslib_1.__metadata("design:type", Observable)
    ], NavbarComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        select(selectUserName),
        tslib_1.__metadata("design:type", Observable)
    ], NavbarComponent.prototype, "name$", void 0);
    tslib_1.__decorate([
        select(selectIsBounced),
        tslib_1.__metadata("design:type", Observable)
    ], NavbarComponent.prototype, "bounced$", void 0);
    return NavbarComponent;
}());
export { NavbarComponent };
