import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginStep } from '../shared/models/login-step.model';
import { Observable } from 'rxjs/internal/Observable';
import { selectIsLoggedIn } from '../auth/redux/auth.selectors';
import { select } from '@angular-redux/store';
import { ActivatedRoute } from '@angular/router';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(route) {
        this.route = route;
        this.subscriptions = [];
        this.loggingIn = false;
        this.loggedIn = false;
        this.welcome = false;
        this.forgot = false;
        this.timeout = false;
        this.loginStep = LoginStep.NEW;
        this.loginStepChoices = LoginStep;
        this.branding = environment.branding;
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.timeout = params['timeout'] === 'true';
        });
        this.subscriptions.push(this.loggedIn$.subscribe(function (loggedIn) {
            _this.loggedIn = loggedIn;
        }));
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    tslib_1.__decorate([
        select(selectIsLoggedIn),
        tslib_1.__metadata("design:type", Observable)
    ], HomeComponent.prototype, "loggedIn$", void 0);
    return HomeComponent;
}());
export { HomeComponent };
