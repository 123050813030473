import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  showFaq = null;

  constructor(private meta: Meta) {}

  ngOnInit() {}

  setFaq(number: number) {
    this.showFaq = number;
  }
}
