import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  OnDestroy,
  AfterContentInit,
  Renderer2
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import 'rxjs/add/operator/filter';

import { Location, DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2 } from 'angulartics2';
import { StickyService } from './shared/sticky-service';
import { environment } from '../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from './app.module';
import { AuthActions } from './auth/redux/auth.actions';
import { CookieService } from 'ngx-cookie-service';
import { TrainingActions } from './training/redux/training.actions';
import {
  selectIsBounced,
  selectJwtToken,
  selectLanguage,
  selectUser
} from './auth/redux/auth.selectors';
import { Observable, timer, from, Subject } from 'rxjs';
import { concatMap, filter, share, take } from 'rxjs/operators';
import { ApiService, Service } from './service/api.service';
import { IMe } from './auth/redux/auth.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  @select(selectLanguage) readonly language$: Observable<string>;
  @select(selectJwtToken) readonly jwt$: Observable<string>;
  @select(selectUser) readonly user$: Observable<IMe>;
  @select(selectIsBounced) readonly bounced$: Observable<boolean>;

  public onSubject = new Subject<{ key: string; value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());
  subscriptions: Subscription[] = [];
  checkAuthTimerHandle: number;
  checkBounceTimerHandle: number;
  jwt: string;
  user: IMe;
  showNewVersion = false;
  showNewUser = false;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private angulartics2: Angulartics2,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any,
    private stickyService: StickyService,
    private element: ElementRef,
    public location: Location,
    private ngRedux: NgRedux<IAppState>,
    private authActions: AuthActions,
    private trainingActions: TrainingActions,
    private cookieService: CookieService,
    private apiService: ApiService
  ) {
    console.log(`📅 ${environment.version}`);
    angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit() {
    if (environment.production) {
      this.checkVersion(environment.version);
    }

    this.subscriptions.push(this.jwt$.subscribe((value: string) => (this.jwt = value)));
    this.subscriptions.push(this.user$.subscribe((value: IMe) => (this.user = value)));

    this.subscriptions.push(
      this.language$.subscribe((value: string) => {
        this.translate.setDefaultLang(value);
        this.translate.use(value);
        this.angulartics2.setUserProperties.next({ page_language: value });
      })
    );

    this.start();
    this.setUpAuthCheck();

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      console.log('🔆 You prefer dark mode');
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      console.log('🔆 You prefer light mode');
    } else {
      console.log('🔆 Cannot detect color preference with matchMedia');
    }

    // if (window.matchMedia) {
    //   const mql = window.matchMedia('(prefers-color-scheme: dark)');
    //   mql.addEventListener('change', () => {
    //     const newColorScheme = e.matches ? 'dark' : 'light';
    //     console.log(`🔆 newColorScheme ${newColorScheme}`);
    //   });
    // }

    const navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
    this.subscriptions.push(
      this.router.events
        .filter((event) => event instanceof NavigationEnd)
        .subscribe(() => {
          this.renderer.listen('window', 'scroll', () => {
            const scrollY = window.scrollY;
            if (scrollY > 0 || window.pageYOffset > 0) {
              // add logic
              navbar.classList.add('headroom--not-top');
            } else {
              // remove logic
              navbar.classList.remove('headroom--not-top');
            }
          });
        })
    );
    // this.hasScrolled();
    this.ngRedux.dispatch(this.trainingActions.fetchCoursesAsync());
  }

  ngOnDestroy() {
    this.stop();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.checkAuthTimerHandle !== undefined) {
      clearInterval(this.checkAuthTimerHandle);
    }
    if (this.checkBounceTimerHandle !== undefined) {
      clearInterval(this.checkBounceTimerHandle);
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // hasScrolled() {
  //   const st = window.pageYOffset;
  //   // Make sure they scroll more than delta
  //   if (Math.abs(lastScrollTop - st) <= delta) {
  //     return;
  //   }
  //
  //   const navbar = document.getElementsByTagName('nav')[0];
  //
  //   // If they scrolled down and are past the navbar, add class .headroom--unpinned.
  //   // This is necessary so you never see what is "behind" the navbar.
  //   if (st > lastScrollTop && st > navbarHeight) {
  //     // Scroll Down
  //     if (navbar.classList.contains('headroom--pinned')) {
  //       navbar.classList.remove('headroom--pinned');
  //       navbar.classList.add('headroom--unpinned');
  //       this.stickyService.toggle('unpinned');
  //     }
  //     // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
  //   } else {
  //     // Scroll Up
  //     //  $(window).height()
  //     if (st + window.innerHeight < document.body.scrollHeight) {
  //       // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
  //       if (navbar.classList.contains('headroom--unpinned')) {
  //         navbar.classList.remove('headroom--unpinned');
  //         navbar.classList.add('headroom--pinned');
  //         this.stickyService.toggle('pinned');
  //       }
  //     }
  //   }
  //
  //   lastScrollTop = st;
  // }

  ngAfterContentInit() {}

  setUpAuthCheck() {
    if (this.checkAuthTimerHandle === undefined) {
      this.checkAuthTimerHandle = <any>setInterval(() => {
        if (this.jwt) {
          this.ngRedux.dispatch(this.authActions.checkJwtRefreshAsync());
        }
      }, 60000);
      this.ngRedux.dispatch(this.authActions.validateSession());
    }
    if (this.checkBounceTimerHandle === undefined) {
      this.checkBounceTimerHandle = <any>setInterval(() => {
        if (this.user && this.user.email_verified) {
          clearInterval(this.checkBounceTimerHandle);
        }
        if (this.jwt) {
          this.ngRedux.dispatch(this.authActions.checkBounceBackAsync());
        }
      }, 5000);
    }

    // If we are logged in, reload tokens
    if (this.jwt) {
      this.ngRedux.dispatch(this.trainingActions.fetchTokensAsync());
    }
  }

  switchLanguage(lang: string) {
    localStorage.setItem('language', lang);
    this.translate.use(lang);
  }

  reload() {
    window.location.reload();
    return false;
  }

  checkVersion(currentVersion) {
    timer(0, 30000)
      .pipe(
        concatMap(() =>
          from(this.apiService.getUrl(Service.PUBLIC, '/version', { p: environment.project }))
        )
      )
      .pipe(filter((result) => result.success && result.data !== currentVersion))
      .pipe(take(1))
      .subscribe(() => (this.showNewVersion = true));
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue ? event.newValue : undefined;
      }
      this.onSubject.next({ key: event.key, value: v });
      if (event.key === 'id') {
        this.showNewUser = true;
      }
    }
  }
}
