import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { CookieService } from 'ngx-cookie-service';
export class AuthGuard {
    constructor(router, cookieService) {
        this.router = router;
        this.cookieService = cookieService;
    }
    canActivate(next, state) {
        return this.auth$.pipe(map((e) => {
            if (localStorage.getItem('jwt') && e.loggedIn) {
                return true;
            }
            else {
                this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } }).then();
            }
        }), catchError((err) => {
            console.log(err);
            this.router.navigate(['/auth/login']).then();
            return of(false);
        }));
    }
}
tslib_1.__decorate([
    select(),
    tslib_1.__metadata("design:type", Observable)
], AuthGuard.prototype, "auth$", void 0);
