import { Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
const ɵ0 = () => import("./home/home.module.ngfactory").then((m) => m.HomeModuleNgFactory), ɵ1 = () => import("./about/about.module.ngfactory").then((m) => m.AboutModuleNgFactory), ɵ2 = () => import("./admin/admin.module.ngfactory").then((m) => m.AdminModuleNgFactory), ɵ3 = () => import("./auth/auth.module.ngfactory").then((m) => m.AuthModuleNgFactory), ɵ4 = () => import("./business/business.module.ngfactory").then((m) => m.BusinessModuleNgFactory), ɵ5 = () => import("./profile/profile.module.ngfactory").then((m) => m.ProfileModuleNgFactory), ɵ6 = () => import("./signup/signup.module.ngfactory").then((m) => m.SignupModuleNgFactory), ɵ7 = () => import("./training/training.module.ngfactory").then((m) => m.TrainingModuleNgFactory), ɵ8 = () => import("./verify/verify.module.ngfactory").then((m) => m.VerifyModuleNgFactory);
const routes = [
    { path: 'courses/employer-tokens', redirectTo: '/admin/tokens', pathMatch: 'full' },
    { path: 'courses/employee-tokens', redirectTo: '/admin/tokens', pathMatch: 'full' },
    {
        path: 'employer-bulk-token-packages',
        redirectTo: '/business/bulk-token-packages',
        pathMatch: 'full'
    },
    {
        path: 'courses/employer-purchase',
        redirectTo: '/business/bulk-token-packages',
        pathMatch: 'full'
    },
    { path: 'signin', redirectTo: '/auth/login', pathMatch: 'full' },
    { path: 'signout', redirectTo: '/auth/logout', pathMatch: 'full' },
    { path: 'login', redirectTo: '/auth/login', pathMatch: 'full' },
    { path: 'logout', redirectTo: '/auth/logout', pathMatch: 'full' },
    {
        path: '',
        pathMatch: 'full',
        loadChildren: ɵ0
    },
    { path: 'about', loadChildren: ɵ1 },
    { path: 'admin', loadChildren: ɵ2 },
    { path: 'auth', loadChildren: ɵ3 },
    {
        path: 'business',
        loadChildren: ɵ4
    },
    {
        path: 'profile',
        loadChildren: ɵ5
    },
    {
        path: 'signup',
        loadChildren: ɵ6
    },
    {
        path: 'training',
        loadChildren: ɵ7
    },
    {
        path: 'verify',
        loadChildren: ɵ8
    },
    { path: '**', component: NotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
