import AdminTypes from './admin.types';
import { INITIAL_STATE, IParameters } from './admin.state';
import {
  buildName,
  filterDataByShowing,
  sortData,
  updateTokenInCollection,
  updateWebhookInCollection
} from './admin.utils';
import { AdminEmployees } from '../admin-tokens/admin-tokens.component';

const adminReducer = (state = INITIAL_STATE, action: any) => {
  let sortedData: AdminEmployees[];
  let p: IParameters;
  switch (action.type) {
    case AdminTypes.CLEAR_ADMIN_ERROR:
      return {
        ...state,
        error: undefined
      };
    case AdminTypes.SUBSCRIBER_CREDIT_CARD_FETCH_SUCCESS:
      return {
        ...state,
        creditCard: action.payload
      };
    case AdminTypes.SUBSCRIBER_CREDIT_CARD_FETCH_FAILURE:
      return {
        ...state,
        creditCard: undefined,
        error: action.payload
      };
    case AdminTypes.SUBSCRIBER_ACCOUNT_FETCH_START:
      return {
        ...state,
        loading: true
      };
    case AdminTypes.SUBSCRIBER_ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        saving: false,
        subscriberProfile: action.payload
      };
    case AdminTypes.SUBSCRIBER_ACCOUNT_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case AdminTypes.SUBSCRIBER_PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        subscriberProfile: action.payload
      };
    case AdminTypes.SUBSCRIBER_TOKEN_UPDATE:
      sortedData = updateTokenInCollection(state.tokens, action.payload);
      return {
        ...state,
        tokens: sortedData
      };
    case AdminTypes.SUBSCRIBER_TOKENS_FETCH_START:
      return {
        ...state,
        loadingTokens: true
      };
    case AdminTypes.SUBSCRIBER_TOKENS_FETCH_PROGRESS:
      return {
        ...state,
        error: null,
        tokens: action.payload
      };
    case AdminTypes.SUBSCRIBER_TOKENS_FETCH_SUCCESS:
      return {
        ...state,
        error: null,
        tokens: action.payload.data,
        loadingTokens: false,
        total_tokens: {
          ...state.total_tokens,
          ...action.payload.counts
        }
      };

    case AdminTypes.SUBSCRIBER_TOKENS_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingTokens: false
      };
    case AdminTypes.SUBSCRIBER_TOKENS_PAGINATE:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          start: action.payload.start,
          limit: action.payload.limit ? action.payload.limit : state.parameters.limit
        }
      };
    case AdminTypes.SUBSCRIBER_TOKENS_UPDATE_SORT:
      const parameters = state.parameters;
      parameters.sortDirection = action.payload.sortDirection;
      parameters.sortField = action.payload.sortField;
      parameters.start = 0;
      sortedData = sortData(state.tokens, parameters);
      return {
        ...state,
        tokens: sortedData,
        parameters
      };
    case AdminTypes.SUBSCRIBER_TOKENS_SHOWING:
      p = state.parameters;
      p.status = action.payload;
      return {
        ...state,
        parameters: {
          ...state.parameters,
          start: 0,
          status: action.payload
        }
      };
    case AdminTypes.SUBSCRIBER_TOKENS_SEARCH:
      p = state.parameters;
      p.search = action.payload;
      return {
        ...state,
        parameters: {
          ...state.parameters,
          start: 0,
          search: action.payload
        }
      };
    case AdminTypes.SUBSCRIBER_PURCHASES_CLEAR:
      return {
        ...state,
        purchases: []
      };

    case AdminTypes.SUBSCRIBER_PURCHASES_FETCH_START:
      return {
        ...state,
        loadingTokens: true
      };

    case AdminTypes.SUBSCRIBER_PURCHASES_FETCH_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          error: null,
          purchases: [...state.purchases, ...action.payload.data.records],
          loadingTokens: false,
          purchasesPagination: {
            ...state.purchasesPagination,
            next: action.payload.next,
            count: action.payload.count
          }
        };
      }
      return {
        ...state,
        error: null,
        purchases: action.payload.data.records,
        loadingTokens: false,
        purchasesPagination: {
          ...state.purchasesPagination,
          next: action.payload.next,
          count: action.payload.count
        }
      };
    case AdminTypes.SUBSCRIBER_WEBHOOK_CLEAR:
      return {
        ...state,
        webhooks: []
      };

    case AdminTypes.SUBSCRIBER_WEBHOOK_FETCH_START:
      return {
        ...state,
        loadingTokens: true
      };

    case AdminTypes.SUBSCRIBER_WEBHOOK_FETCH_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          error: null,
          webhooks: [...state.webhooks, ...action.payload.data.records],
          loadingTokens: false,
          webhookPagination: {
            ...state.webhookPagination,
            next: action.payload.next,
            count: action.payload.count
          }
        };
      }
      return {
        ...state,
        error: null,
        webhooks: action.payload.data.records,
        loadingTokens: false,
        webhookPagination: {
          ...state.webhookPagination,
          next: action.payload.next,
          count: action.payload.count
        }
      };
    case AdminTypes.SUBSCRIBER_WEBHOOK_UPDATE:
      return {
        ...state,
        error: null,
        webhooks: updateWebhookInCollection(state.webhooks, action.payload.data)
      };
    default:
      return state;
  }
};

export default adminReducer;
