import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, Service } from '../../service/api.service';
import { RegistrationUser } from '../models/registration-user.model';
import { AuthActions } from '../../auth/redux/auth.actions';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/internal/Observable';
import { selectError, selectLanguage, selectRunningAction } from '../../auth/redux/auth.selectors';
import { CourseStatus } from '../../training/redux/training.types';
export class LoginInsetComponent {
    constructor(fb, router, apiService, activatedRoute, ngRedux, authActions) {
        this.fb = fb;
        this.router = router;
        this.apiService = apiService;
        this.activatedRoute = activatedRoute;
        this.ngRedux = ngRedux;
        this.authActions = authActions;
        this.subscriptions = [];
        this.goAfter = false;
        this.fromInvite = false;
        this.loginEvent = new EventEmitter();
        this.userNotFound = false;
        this.loggingIn = null;
        this.regText = 'Signup/login';
        this.signInDisabled = true;
        this.emailFound = false;
        this.forgot = false;
        this.passwordPlaceholder = 'Password';
        this.showInvalid = false;
    }
    ngOnInit() {
        this.subscriptions.push(this.language$.subscribe((value) => (this.language = value)));
        this.subscriptions.push(this.error$.subscribe((value) => {
            return (this.errorMessage = value);
        }));
        this.ngRedux.dispatch(this.authActions.errorClear());
        this.ngRedux.dispatch(this.authActions.userStopAction());
        this.subscriptions.push(this.$isLoggingIn.subscribe((value) => {
            this.loggingIn = value;
        }));
        this.subscriptions.push(this.auth$.subscribe((value) => {
            if (value.loggedIn) {
                this.redirectAfterLogin(value.claims, value.user);
            }
        }));
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => (this.forgot = params['forgot'] === 'true')));
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || undefined;
        this.registrationUser = new RegistrationUser();
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => {
            this.registrationUser.email = params['u'];
            this.timeout = params['timeout'] === 'true';
        }));
        this.initForm();
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    initForm() {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            language: this.language,
            rememberMe: [false]
        });
    }
    async onSubmit(action) {
        this.errorLocal = null;
        if (this.loggingIn) {
            console.log('already logging in');
            return;
        }
        if (!this.form.valid) {
            this.errorLocal = 'login.all-fields';
            if (action === 'signup' && !this.emailFound && !this.form.get('password').valid) {
                this.errorLocal = 'login.sign-up-error';
            }
            return;
        }
        this.errorLocal = null;
        this.userNotFound = false;
        switch (action) {
            case 'login':
                this.ngRedux.dispatch(this.authActions.userLoginAsync(this.form.get('email').value, this.form.get('password').value));
                break;
            case 'signup':
            default:
                this.ngRedux.dispatch(this.authActions.userRegisterAsync({
                    email: this.form.get('email').value.toLowerCase(),
                    password: this.form.get('password').value,
                    passwordConfirm: this.form.get('password').value,
                    language: this.language,
                    fromInvite: this.fromInvite
                }));
                break;
        }
    }
    blurEmail() {
        this.errorMessage = undefined;
        this.errorLocal = null;
        this.signInDisabled = true;
        this.showInvalid = false;
        if (!this.form.get('email').valid) {
            this.regText = 'Signup/login';
            this.emailFound = false;
            console.log('Form not valid');
            this.errorMessage = 'Invalid entry in form';
            return;
        }
        this.regText = 'Checking email address...';
        console.log('Checking email address...');
        this.apiService
            .postUrl(Service.PUBLIC, '/user/confirmed', { email: this.form.get('email').value })
            .then((result) => {
            console.log(result);
            this.errorMessage = undefined;
            result.error === 'Not Found' ? this.setNewUser() : this.setExistingUser();
        })
            .catch((error) => {
            console.log(error);
            if (error.status === 406) {
                this.loggingIn = null;
                this.errorMessage = error.error.error;
                this.signInDisabled = true;
            }
            else {
                this.errorMessage = undefined;
                this.setNewUser();
            }
        });
        this.apiService
            .postUrl(Service.PUBLIC, `/user/email`, {
            email: this.form.get('email').value
        })
            .then((result) => {
            console.log(result);
            this.showInvalid = !result.data;
        })
            .catch((error) => {
            console.log(error);
            this.showInvalid = false;
        });
    }
    setExistingUser() {
        this.regText = 'Sign into existing account';
        this.signInDisabled = false;
        this.passwordPlaceholder = 'Found you! Enter your password.';
        this.emailFound = true;
        this.loggingIn = null;
    }
    setNewUser() {
        this.regText = 'Register new user';
        this.passwordPlaceholder = 'Create a password';
        this.signInDisabled = false;
        this.emailFound = false;
    }
    onGoogle() {
        if (this.loggingIn) {
            return false;
        }
        this.errorLocal = undefined;
        console.log('logging in google');
        this.ngRedux.dispatch(this.authActions.userLoginThirdPartyAsync('google'));
    }
    onLinkedIn() { }
    redirectAfterLogin(claims, user) {
        if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl).then();
        }
        else {
            if (this.goAfter) {
                if (claims && claims.is_manager) {
                    let route = '/admin/tokens';
                    if (!user.email_verified) {
                        route = '/profile/validate';
                    }
                    this.router.navigate([route]).then();
                }
                else {
                    let route = '/training/course-list';
                    if (!user.email_verified) {
                        route = '/profile/validate';
                    }
                    this.router.navigate([route]).then();
                }
            }
            else {
                this.loginEvent.next(CourseStatus.LoggedIn);
            }
        }
    }
}
tslib_1.__decorate([
    select(),
    tslib_1.__metadata("design:type", Observable)
], LoginInsetComponent.prototype, "auth$", void 0);
tslib_1.__decorate([
    select(selectLanguage),
    tslib_1.__metadata("design:type", Observable)
], LoginInsetComponent.prototype, "language$", void 0);
tslib_1.__decorate([
    select(selectError),
    tslib_1.__metadata("design:type", Observable)
], LoginInsetComponent.prototype, "error$", void 0);
tslib_1.__decorate([
    select(selectRunningAction),
    tslib_1.__metadata("design:type", Observable)
], LoginInsetComponent.prototype, "$isLoggingIn", void 0);
