import { Component, OnDestroy, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { selectUser } from '../../auth/redux/auth.selectors';
import { Observable } from 'rxjs/internal/Observable';
import { IMe } from '../../auth/redux/auth.state';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-bounced-alert',
  templateUrl: './bounced-alert.component.html',
  styleUrls: ['./bounced-alert.component.scss']
})
export class BouncedAlertComponent implements OnInit, OnDestroy {
  @select(selectUser) readonly user$: Observable<IMe>;
  subscriptions: Subscription[] = [];
  objUser: IMe;
  partiallyHidden = false;

  constructor() {}

  ngOnInit() {
    this.subscriptions.push(this.user$.subscribe((value) => (this.objUser = value)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  dismissError() {
    this.partiallyHidden = !this.partiallyHidden;
  }
}
