import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/internal/Observable';
import { selectIsBounced, selectIsLoggedIn, selectIsManager, selectLanguage, selectUser, selectUserName } from '../../auth/redux/auth.selectors';
import { AuthActions } from '../../auth/redux/auth.actions';
export class NavbarComponent {
    constructor(location, router, ngRedux, authActions) {
        this.location = location;
        this.router = router;
        this.ngRedux = ngRedux;
        this.authActions = authActions;
        this.subscriptions = [];
        this.isCollapsed = true;
        this.features = 'Features';
        this.yScrollStack = [];
        this.environment = environment;
        this.branding = environment.branding;
        if (this.branding === 'cent') {
            this.features = 'For Businesses';
        }
    }
    ngOnInit() {
        this.subscriptions.push(this.name$.subscribe((name) => {
            this.name = name;
        }));
        this.subscriptions.push(this.user$.subscribe((user) => {
            if (user && user.employer) {
                this.employer = user.employer.substring(0, 13);
            }
            else {
                this.employer = undefined;
            }
        }));
        this.subscriptions.push(this.loggedIn$.subscribe((loggedIn) => {
            this.loggedIn = loggedIn;
        }));
        this.subscriptions.push(this.isManager$.subscribe((isManager) => {
            this.isManager = isManager;
        }));
        this.subscriptions.push(this.language$.subscribe((language) => {
            this.language = language;
        }));
        this.subscriptions.push(this.router.events.subscribe((event) => {
            this.isCollapsed = true;
            if (event instanceof NavigationStart) {
                if (event.url !== this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            }
            else if (event instanceof NavigationEnd) {
                if (event.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                }
                else {
                    window.scrollTo(0, 0);
                }
            }
        }));
        this.subscriptions.push(this.location.subscribe((ev) => {
            this.lastPoppedUrl = ev.url;
        }));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    changeLanguage(language) {
        this.ngRedux.dispatch(this.authActions.changeLanguageAsync(language));
    }
    isHome() {
        const title = this.location.prepareExternalUrl(this.location.path());
        return title === '/';
    }
    isTraining() {
        const title = this.location.prepareExternalUrl(this.location.path());
        return title.substr(0, 14) === '/training/play';
    }
    inProgress() {
        return localStorage.getItem('url');
    }
    isStudent() {
        const title = this.location.prepareExternalUrl(this.location.path());
        return title.substr(0, 8) === '/student';
    }
    isDocumentation() {
        const title = this.location.prepareExternalUrl(this.location.path());
        return title === '/documentation';
    }
    isDashboard() {
        return this.router.url.substr(0, 6) === '/admin';
    }
}
tslib_1.__decorate([
    select(selectIsLoggedIn),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "loggedIn$", void 0);
tslib_1.__decorate([
    select(selectIsManager),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "isManager$", void 0);
tslib_1.__decorate([
    select(selectLanguage),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "language$", void 0);
tslib_1.__decorate([
    select(selectUser),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "user$", void 0);
tslib_1.__decorate([
    select(selectUserName),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "name$", void 0);
tslib_1.__decorate([
    select(selectIsBounced),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "bounced$", void 0);
