<div class="card bg-secondary shadow border-2">
  <div class="card-header bg-white pb-4">
    <div class="alert alert-success text-center mb-3" *ngIf="forgot">
      {{ 'login.password-resent' | translate }}
    </div>

    <div class="text-muted text-center mb-3">
      <small>{{ 'login.register-social' | translate }}</small>
    </div>
    <div class="btn-wrapper text-center">
      <!--      <a (click)="onLinkedIn()" class="btn btn-neutral btn-icon">-->
      <!--                  <span class="btn-inner&#45;&#45;icon">-->
      <!--                     <img [src]="'/assets/img/icons/common/linkedin.svg'" alt="linkedin-logo">-->
      <!--                  </span>-->
      <!--        <span class="btn-inner&#45;&#45;text">LinkedIn</span>-->
      <!--      </a>-->
      <a (click)="onGoogle()" class="btn btn-neutral btn-icon">
        <span class="btn-inner--icon">
          <img [src]="'/assets/img/icons/common/google.svg'" alt="google-logo" />
        </span>
        <span class="btn-inner--text">Google</span
        ><img
          *ngIf="loggingIn == 'google'"
          [src]="'/assets/img/loading.gif'"
          alt="loading"
          height="20px"
        />
      </a>
    </div>
  </div>
  <div class="card-body px-lg-3 py-lg-3">
    <div class="text-center text-muted mb-4">
      <small>{{ 'login.sign-up' | translate }}</small>
    </div>
    <form [formGroup]="form">
      <div *ngIf="errorLocal" class="alert alert-danger">
        <strong>{{ errorLocal | translate }}</strong>
      </div>
      <div *ngIf="errorMessage" class="alert alert-danger">
        <strong>{{ errorMessage }}</strong>
      </div>
      <div class="form-group mb-3" [ngClass]="{ focused: focus === true }">
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text" [ngClass]="emailFound ? 'green' : ''"
              ><i class="ni ni-email-83"></i
            ></span>
          </div>
          <input
            class="form-control"
            placeholder="{{ 'login.email-address' | translate }}"
            id="email"
            name="email"
            type="email"
            (focus)="focus = true"
            (blur)="focus = false; blurEmail()"
            formControlName="email"
          />
        </div>
      </div>
      <div *ngIf="showInvalid && !emailFound" class="alert alert-warning">
        <strong>{{ 'login.email-invalid' | translate }}</strong>
      </div>
      <div class="form-group" [ngClass]="{ focused: focus1 === true }">
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
          </div>
          <input
            class="form-control"
            id="password"
            name="password"
            placeholder="{{ 'login.password' | translate }}"
            type="password"
            (focus)="focus1 = true"
            (blur)="focus1 = false"
            formControlName="password"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6"></div>
        <div class="col-6">
          <a [routerLink]="['/auth/forgot-password']" class="text-light">
            <small>{{ 'login.forgot-password' | translate }}</small>
          </a>
        </div>
      </div>

      <div class="row mt-3" *ngIf="loggingIn">
        <div class="col-12 text-center">
          <i class="fa-4x fa fa-circle-o-notch fa-spin text-yellow" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!loggingIn">
        <div class="col-md-6 col-sm-12 text-right">
          <button
            (click)="onSubmit('login').then()"
            id="login"
            type="submit"
            class="btn my-4 col-12"
            [ngClass]="emailFound ? 'btn-primary' : 'btn-outline-primary'"
          >
            {{ 'login.login-button' | translate }}
          </button>
        </div>
        <div class="col-md-6 col-sm-12">
          <button
            (click)="onSubmit('signup').then()"
            id="signup"
            type="submit"
            class="btn my-md-4 col-12"
            [ngClass]="emailFound ? 'btn-outline-primary' : 'btn-primary'"
          >
            {{ 'login.signup-button' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
