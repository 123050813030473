import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, Service } from '../../service/api.service';
import { RegistrationUser } from '../models/registration-user.model';
import { AuthActions } from '../../auth/redux/auth.actions';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/internal/Observable';
import { selectError, selectLanguage, selectRunningAction } from '../../auth/redux/auth.selectors';
import { CourseStatus } from '../../training/redux/training.types';
var LoginInsetComponent = /** @class */ (function () {
    function LoginInsetComponent(fb, router, apiService, activatedRoute, ngRedux, authActions) {
        this.fb = fb;
        this.router = router;
        this.apiService = apiService;
        this.activatedRoute = activatedRoute;
        this.ngRedux = ngRedux;
        this.authActions = authActions;
        this.subscriptions = [];
        this.goAfter = false;
        this.fromInvite = false;
        this.loginEvent = new EventEmitter();
        this.userNotFound = false;
        this.loggingIn = null;
        this.regText = 'Signup/login';
        this.signInDisabled = true;
        this.emailFound = false;
        this.forgot = false;
        this.passwordPlaceholder = 'Password';
        this.showInvalid = false;
    }
    LoginInsetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.language$.subscribe(function (value) { return (_this.language = value); }));
        this.subscriptions.push(this.error$.subscribe(function (value) {
            return (_this.errorMessage = value);
        }));
        this.ngRedux.dispatch(this.authActions.errorClear());
        this.ngRedux.dispatch(this.authActions.userStopAction());
        this.subscriptions.push(this.$isLoggingIn.subscribe(function (value) {
            _this.loggingIn = value;
        }));
        this.subscriptions.push(this.auth$.subscribe(function (value) {
            if (value.loggedIn) {
                _this.redirectAfterLogin(value.claims, value.user);
            }
        }));
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(function (params) { return (_this.forgot = params['forgot'] === 'true'); }));
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || undefined;
        this.registrationUser = new RegistrationUser();
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(function (params) {
            _this.registrationUser.email = params['u'];
            _this.timeout = params['timeout'] === 'true';
        }));
        this.initForm();
    };
    LoginInsetComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    LoginInsetComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            language: this.language,
            rememberMe: [false]
        });
    };
    LoginInsetComponent.prototype.onSubmit = function (action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.errorLocal = null;
                if (this.loggingIn) {
                    console.log('already logging in');
                    return [2 /*return*/];
                }
                if (!this.form.valid) {
                    this.errorLocal = 'login.all-fields';
                    if (action === 'signup' && !this.emailFound && !this.form.get('password').valid) {
                        this.errorLocal = 'login.sign-up-error';
                    }
                    return [2 /*return*/];
                }
                this.errorLocal = null;
                this.userNotFound = false;
                switch (action) {
                    case 'login':
                        this.ngRedux.dispatch(this.authActions.userLoginAsync(this.form.get('email').value, this.form.get('password').value));
                        break;
                    case 'signup':
                    default:
                        this.ngRedux.dispatch(this.authActions.userRegisterAsync({
                            email: this.form.get('email').value.toLowerCase(),
                            password: this.form.get('password').value,
                            passwordConfirm: this.form.get('password').value,
                            language: this.language,
                            fromInvite: this.fromInvite
                        }));
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    LoginInsetComponent.prototype.blurEmail = function () {
        var _this = this;
        this.errorMessage = undefined;
        this.errorLocal = null;
        this.signInDisabled = true;
        this.showInvalid = false;
        if (!this.form.get('email').valid) {
            this.regText = 'Signup/login';
            this.emailFound = false;
            console.log('Form not valid');
            this.errorMessage = 'Invalid entry in form';
            return;
        }
        this.regText = 'Checking email address...';
        console.log('Checking email address...');
        this.apiService
            .postUrl(Service.PUBLIC, '/user/confirmed', { email: this.form.get('email').value })
            .then(function (result) {
            console.log(result);
            _this.errorMessage = undefined;
            result.error === 'Not Found' ? _this.setNewUser() : _this.setExistingUser();
        })
            .catch(function (error) {
            console.log(error);
            if (error.status === 406) {
                _this.loggingIn = null;
                _this.errorMessage = error.error.error;
                _this.signInDisabled = true;
            }
            else {
                _this.errorMessage = undefined;
                _this.setNewUser();
            }
        });
        this.apiService
            .postUrl(Service.PUBLIC, "/user/email", {
            email: this.form.get('email').value
        })
            .then(function (result) {
            console.log(result);
            _this.showInvalid = !result.data;
        })
            .catch(function (error) {
            console.log(error);
            _this.showInvalid = false;
        });
    };
    LoginInsetComponent.prototype.setExistingUser = function () {
        this.regText = 'Sign into existing account';
        this.signInDisabled = false;
        this.passwordPlaceholder = 'Found you! Enter your password.';
        this.emailFound = true;
        this.loggingIn = null;
    };
    LoginInsetComponent.prototype.setNewUser = function () {
        this.regText = 'Register new user';
        this.passwordPlaceholder = 'Create a password';
        this.signInDisabled = false;
        this.emailFound = false;
    };
    LoginInsetComponent.prototype.onGoogle = function () {
        if (this.loggingIn) {
            return false;
        }
        this.errorLocal = undefined;
        console.log('logging in google');
        this.ngRedux.dispatch(this.authActions.userLoginThirdPartyAsync('google'));
    };
    LoginInsetComponent.prototype.onLinkedIn = function () { };
    LoginInsetComponent.prototype.redirectAfterLogin = function (claims, user) {
        if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl).then();
        }
        else {
            if (this.goAfter) {
                if (claims && claims.is_manager) {
                    var route = '/admin/tokens';
                    if (!user.email_verified) {
                        route = '/profile/validate';
                    }
                    this.router.navigate([route]).then();
                }
                else {
                    var route = '/training/course-list';
                    if (!user.email_verified) {
                        route = '/profile/validate';
                    }
                    this.router.navigate([route]).then();
                }
            }
            else {
                this.loginEvent.next(CourseStatus.LoggedIn);
            }
        }
    };
    tslib_1.__decorate([
        select(),
        tslib_1.__metadata("design:type", Observable)
    ], LoginInsetComponent.prototype, "auth$", void 0);
    tslib_1.__decorate([
        select(selectLanguage),
        tslib_1.__metadata("design:type", Observable)
    ], LoginInsetComponent.prototype, "language$", void 0);
    tslib_1.__decorate([
        select(selectError),
        tslib_1.__metadata("design:type", Observable)
    ], LoginInsetComponent.prototype, "error$", void 0);
    tslib_1.__decorate([
        select(selectRunningAction),
        tslib_1.__metadata("design:type", Observable)
    ], LoginInsetComponent.prototype, "$isLoggingIn", void 0);
    return LoginInsetComponent;
}());
export { LoginInsetComponent };
