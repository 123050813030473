<li class="nav-item dropdown">
  <a
    class="nav-link dropdown-toggle"
    data-toggle="dropdown"
    role="button"
    [routerLink]="[
      branding == 'fs' ? 'business/free-food-handler-training' : 'business/bulk-token-packages'
    ]"
  >
    <i class="ni ni-ui-04 d-lg-none"></i>
    <span class="">For Businesses</span>
  </a>
  <div class="dropdown-menu-xl dropdown-menu">
    <div class="dropdown-menu-inner">
      <a
        routerLinkActive="active"
        [routerLink]="['admin/tokens']"
        *ngIf="loggedIn && manager"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-yellow rounded-circle text-white">
          <i class="fa fa-dashboard"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">My Dashboard</h6>
        </div>
      </a>

      <a
        routerLinkActive="active"
        [routerLink]="['business/bulk-token-packages']"
        *ngIf="branding == 'cent'"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
          <i class="ni ni-paper-diploma"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">Bulk Token Packages</h6>
          <p class="description d-none d-md-inline-block mb-0">
            Buy Food Handler tokens in bulk to give your employees and new-hires. From 20 to 100
            tokens at a time and optional refill. Click here to buy.
          </p>
        </div>
      </a>

      <a
        routerLinkActive="active"
        [routerLink]="['/verify']"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
          <i class="ni ni-key-25"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">Verify a Certificate</h6>
          <p class="description d-none d-md-inline-block mb-0">
            Click here to find out how to make sure an employee certificate is valid.
          </p>
        </div>
      </a>

      <a
        routerLinkActive="active"
        [routerLink]="['business/free-food-handler-training']"
        *ngIf="branding == 'fs'"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
          <i class="ni ni-paper-diploma"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">Free Training</h6>
          <p class="description d-none d-md-inline-block mb-0">
            Free Food Handler <span *ngIf="branding == 'fs'">and TABC</span> certification for your
            employees for businesses with our low-cost (${{ environment.fh_subscription }}/month!)
            tracker subscription.
          </p>
        </div>
      </a>

      <a
        routerLinkActive="active"
        [routerLink]="['business/tracker']"
        *ngIf="branding == 'fs'"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
          <i class="ni ni-time-alarm"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">Tracking Expirations</h6>
          <p class="description d-none d-md-inline-block mb-0">
            Keep track of restaurant employee certification expirations and deadlines easily! We
            hound your employees for you.
          </p>
        </div>
      </a>

      <a
        routerLinkActive="active"
        [routerLink]="['business/low-cost-subscription']"
        *ngIf="branding == 'fs'"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
          <i class="ni ni-satisfied"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">All-Inclusive Subscription</h6>
          <p class="description d-none d-md-inline-block mb-0">
            Our low ${{ environment.fh_subscription }}/month subscription fee includes all your
            certification tracking, and the employee training is included free!
          </p>
        </div>
      </a>

      <a
        *ngIf="branding == 'fs'"
        routerLinkActive="active"
        [routerLink]="['/components']"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
          <i class="ni ni-ui-04"></i>
        </div>
        <div class="media-body ml-3">
          <h5 class="heading text-warning mb-md-1">Government Reporting</h5>
          <p class="description d-none d-md-inline-block mb-0">
            We send all updates to applicable municipalities to keep you in compliance.
          </p>
        </div>
      </a>
    </div>
  </div>
</li>

<li class="nav-item dropdown">
  <a
    class="nav-link dropdown-toggle"
    data-toggle="dropdown"
    role="button"
    [routerLink]="['/training/course-list']"
  >
    <i class="ni ni-ui-04 d-lg-none"></i>
    <span class="nav-link-inner--text">Certifications</span>
  </a>
  <div class="dropdown-menu-xl dropdown-menu">
    <div class="dropdown-menu-inner">
      <a
        routerLinkActive="active"
        [routerLink]="['training/course-list']"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
          <i class="ni ni-paper-diploma"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">Texas Food Handler</h6>
          <p class="description d-none d-md-inline-block mb-0">
            Food Handler Certification Card for the State of Texas.
          </p>
        </div>
      </a>
      <a
        routerLinkActive="active"
        [routerLink]="['training/course-list']"
        class="media d-flex align-items-center"
      >
        <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
          <i class="ni ni-curved-next"></i>
        </div>
        <div class="media-body ml-3">
          <h6 class="heading text-primary mb-md-1">TABC</h6>
          <p class="description d-none d-md-inline-block mb-0">
            <strong>Coming Soon!</strong> TABC Alcohol Serving Certification Card for the State of
            Texas.
          </p>
        </div>
      </a>
    </div>
  </div>
</li>

<li class="nav-item dropdown">
  <a class="nav-link" href="#" [routerLink]="'/about/faq'" data-toggle="tooltip" title="FAQ">
    <span class="nav-link-inner--text">FAQ</span>
  </a>
</li>
