<app-full-schedule *ngIf="branding == 'fs'"></app-full-schedule>
<app-cent
  *ngIf="branding == 'cent'"
  [loggingIn]="loggingIn"
  [loggedIn]="loggedIn"
  [welcome]="welcome"
  [forgot]="forgot"
  [timeout]="timeout"
  [loginStep]="loginStep"
  [loginStepChoices]="loginStepChoices"
></app-cent>
