import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseInsetComponent } from './components/purchase-inset/purchase-inset.component';
import { MinuteSecondsPipe } from './pipes/minute-seconds-pipe';
import { MinutesPipe } from './pipes/minutes-pipe';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule,
  MatRadioModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { CarouselModule, WavesModule } from 'angular-bootstrap-md';
import { PhonePipe } from './pipes/phone-pipe';
import { GoogleEmployerComponent } from './components/google-employer/google-employer.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CourseActionButtonsComponent } from './components/course-action-buttons/course-action-buttons.component';
import { FaqComponent } from './components/faq/faq.component';
import { ShapesComponent } from './components/shapes/shapes.component';
import { EmployerManualEntryComponent } from './components/employer-manual-entry/employer-manual-entry.component';
import { FieldErrorDisplayComponent } from './components/field-error-display/field-error-display.component';
import { ProfileQuestionComponent } from './components/profile-question/profile-question.component';
import { LoginInsetComponent } from './login-inset/login-inset.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SafeHTMLPipe } from './pipes/safehtml-pipe';
import { SplitPipe } from './pipes/split-pipe';
import { DashPipe } from './pipes/dash-pipe';
import { ValidateInsetComponent } from './validate-inset/validate-inset.component';
import { MyCourseListComponent } from './components/my-course-list/my-course-list.component';

const MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  CarouselModule,
  WavesModule
];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    GooglePlaceModule,
    ...MATERIAL_MODULES,
    TextMaskModule
  ],
  declarations: [
    CourseActionButtonsComponent,
    GoogleEmployerComponent,
    EmployerManualEntryComponent,
    FieldErrorDisplayComponent,
    ProfileQuestionComponent,
    PurchaseInsetComponent,
    SocialLoginComponent,
    LoginInsetComponent,
    MinuteSecondsPipe,
    MinutesPipe,
    DashPipe,
    FaqComponent,
    PhonePipe,
    SafeHTMLPipe,
    ShapesComponent,
    SplitPipe,
    ValidateInsetComponent,
    MyCourseListComponent
  ],
  exports: [
    MinuteSecondsPipe,
    MinutesPipe,
    PhonePipe,
    SafeHTMLPipe,
    SplitPipe,
    TranslateModule,
    DashPipe,
    GooglePlaceModule,
    CourseActionButtonsComponent,
    GoogleEmployerComponent,
    EmployerManualEntryComponent,
    FieldErrorDisplayComponent,
    ProfileQuestionComponent,
    PurchaseInsetComponent,
    SocialLoginComponent,
    LoginInsetComponent,
    FaqComponent,
    ShapesComponent,
    ValidateInsetComponent,
    MyCourseListComponent,
    ...MATERIAL_MODULES
  ]
})
export class SharedModule {}
