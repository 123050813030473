import * as tslib_1 from "tslib";
import AuthTypes from './auth.types';
import { INITIAL_STATE } from './auth.state';
var authReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case AuthTypes.ERROR_CLEAR:
            return tslib_1.__assign({}, state, { error: null });
        case AuthTypes.ERROR_SET:
            return tslib_1.__assign({}, state, { error: action.payload });
        case AuthTypes.LOGIN:
            return tslib_1.__assign({}, state, { user: action.payload.me, bounced: action.payload.me.emailBounced, claims: action.payload.claims, loggedIn: true, language: action.payload.me.language });
        case AuthTypes.LOGOUT:
            return tslib_1.__assign({}, state, { loggedIn: false });
        case AuthTypes.CHANGE_LANGUAGE:
            if (state.loggedIn) {
                state.user.language = action.payload;
            }
            return tslib_1.__assign({}, state, { language: action.payload, user: state.user });
        case AuthTypes.SET_BOUNCED:
            return tslib_1.__assign({}, state, { bounced: action.payload });
        case AuthTypes.SET_EMPLOYER:
            return tslib_1.__assign({}, state, { user: tslib_1.__assign({}, state.user, { employer_object: action.payload, employer: action.payload.name }) });
        case AuthTypes.SET_SECONDARY:
            return tslib_1.__assign({}, state, { user: tslib_1.__assign({}, state.user, { secondary_id_required: true }) });
        case AuthTypes.SET_EMPLOYER_CLEAR:
            return tslib_1.__assign({}, state, { user: tslib_1.__assign({}, state.user, { employer_object: undefined, employer: action.undefined }) });
        case AuthTypes.SIGNUP_NEXT_STEP:
            return tslib_1.__assign({}, state, { signUpStep: action.payload });
        case AuthTypes.SET_BROWSER:
            return tslib_1.__assign({}, state, { browserInfo: action.payload });
        case AuthTypes.CHECK_JWT:
            // We only check this for a logged in user
            if (state.loggedIn) {
                var user = state.user;
                // state.user.language = action.payload;
                return tslib_1.__assign({}, state, { language: action.payload, user: user });
            }
            else {
                return tslib_1.__assign({}, state);
            }
        case AuthTypes.PROFILE_SAVE_FAILURE:
            return tslib_1.__assign({}, state, { blnRunningAction: false, error: action.payload });
        case AuthTypes.USER_ACTION_START:
            return tslib_1.__assign({}, state, { blnRunningAction: true, error: null });
        case AuthTypes.USER_ACTION_END:
            return tslib_1.__assign({}, state, { blnRunningAction: false });
        case AuthTypes.USER_SET:
            return tslib_1.__assign({}, state, { user: action.payload, bounced: action.payload.emailBounced });
        case AuthTypes.USER_SET_FIELD:
            return tslib_1.__assign({}, state, { user: tslib_1.__assign({}, state.user, (_a = {}, _a[action.payload.field] = action.payload.value, _a)) });
        default:
            return state;
    }
};
var ɵ0 = authReducer;
export default authReducer;
export { ɵ0 };
