/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bounced-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./bounced-alert.component";
var styles_BouncedAlertComponent = [i0.styles];
var RenderType_BouncedAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BouncedAlertComponent, data: {} });
export { RenderType_BouncedAlertComponent as RenderType_BouncedAlertComponent };
function View_BouncedAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close btn btn-primary"], ["data-dismiss", "alert"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_BouncedAlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0ERROR: Email "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" rejected as invalid (bounced). Go to "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["fragment", "edit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { fragment: [0, "fragment"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["Edit Profile"])), (_l()(), i1.ɵted(-1, null, [" and correct your email address to continue."]))], function (_ck, _v) { var currVal_3 = "edit"; var currVal_4 = _ck(_v, 7, 0, "/profile/me"); _ck(_v, 6, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.objUser.email; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).target; var currVal_2 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
function View_BouncedAlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\u00A0ADVERTENCIA: Su direcci\u00F3n de correo electr\u00F3nico ", " no es v\u00E1lida. Haga clic en "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["fragment", "edit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { fragment: [0, "fragment"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Editar perfil"])), (_l()(), i1.ɵted(-1, null, [" y corrija su correo electr\u00F3nico. "]))], function (_ck, _v) { var currVal_3 = "edit"; var currVal_4 = _ck(_v, 4, 0, "/profile/me"); _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.objUser.email; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_BouncedAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "on-top alert alert-danger alert-dismissible"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BouncedAlertComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "message-icon fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BouncedAlertComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BouncedAlertComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "on-top alert alert-danger alert-dismissible"; var currVal_1 = (_co.partiallyHidden ? "partially-hide" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.partiallyHidden; _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_co.objUser.language === "en") && !_co.partiallyHidden); _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.objUser.language === "es") && !_co.partiallyHidden); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_BouncedAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bounced-alert", [], null, null, null, View_BouncedAlertComponent_0, RenderType_BouncedAlertComponent)), i1.ɵdid(1, 245760, null, 0, i4.BouncedAlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BouncedAlertComponentNgFactory = i1.ɵccf("app-bounced-alert", i4.BouncedAlertComponent, View_BouncedAlertComponent_Host_0, {}, {}, []);
export { BouncedAlertComponentNgFactory as BouncedAlertComponentNgFactory };
