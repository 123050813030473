<nav
  id="navbar-main"
  class="
    navbar navbar-main navbar-expand-lg navbar-transparent navbar-light
    headroom headroom--top headroom--pinned
  "
>
  <a *ngIf="branding == 'cent'" class="navbar-brand" [routerLink]="['/']"
    >{{ 'brand' | translate }}<br />
    <span class="hint text-left service-caption d-none d-sm-block">{{
      'home.service-of' | translate
    }}</span>
    <span class="hint text-left service-caption d-block d-sm-none">{{
      'home.service-of-sm' | translate
    }}</span></a
  >

  <a class="navbar-brand mr-lg-5" [routerLink]="['/']" *ngIf="branding == 'fs' && !loggedIn">
    <img [src]="'/assets/img/logo.png'" class="nav-logo" alt="logo" />
  </a>
  <a
    class="navbar-brand mr-lg-5"
    [routerLink]="['/admin/tokens']"
    *ngIf="branding == 'fs' && loggedIn && isManager"
  >
    <img [src]="'/assets/img/logo.png'" class="nav-logo" alt="logo" />
  </a>
  <a
    class="navbar-brand mr-lg-5"
    [routerLink]="['/training/course-list']"
    *ngIf="branding == 'fs' && loggedIn && !isManager"
  >
    <img [src]="'/assets/img/logo.png'" class="nav-logo" alt="logo" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="navbar_global"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
    <div class="navbar-collapse-header">
      <div class="row">
        <div class="col-6 collapse-brand">
          <!-- Only on collapsed menu -->
          <a *ngIf="branding == 'cent'" class="navbar-brand" [routerLink]="['/']"
            >99CentFoodHandler<br />
            <span class="hint text-left service-caption d-none d-sm-block">{{
              'home.service-of' | translate
            }}</span>
            <span class="hint text-left service-caption d-block d-sm-none">{{
              'home.service-of-sm' | translate
            }}</span>
          </a>

          <a [routerLink]="['/']" *ngIf="branding == 'fs' && !loggedIn">
            <img [src]="'/assets/img/logo.png'" alt="logo" />
          </a>
          <a [routerLink]="['/admin/tokens']" *ngIf="branding == 'fs' && loggedIn && isManager">
            <img [src]="'/assets/img/logo.png'" alt="logo" />
          </a>
          <a [routerLink]="['/course-list']" *ngIf="branding == 'fs' && loggedIn && !isManager">
            <img [src]="'/assets/img/logo.png'" alt="logo" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <button
            type="button"
            class="navbar-toggler"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="navbar_global"
          >
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>

    <ul class="navbar-nav navbar-nav-hover ml-auto">
      <app-not-play
        [branding]="branding"
        [loggedIn]="loggedIn"
        [student]="isStudent()"
        [manager]="isManager"
        [dashboard]="isDashboard()"
        [environment]="environment"
      ></app-not-play>

      <li class="nav-item dropdown lang-menu">
        <a class="nav-link line" href="mailto:support@fullschedule.com?subject=Support%20Request"
          >Contact</a
        >
      </li>
      <li class="nav-item dropdown lang-menu" *ngIf="language == 'en'">
        <a class="nav-link line" (click)="changeLanguage('es')">Español</a>
      </li>
      <li class="nav-item dropdown lang-menu" *ngIf="language == 'es'">
        <a class="nav-link line" (click)="changeLanguage('en')">English</a>
      </li>

      <!-- User Menu -->
      <li class="nav-item dropdown" *ngIf="loggedIn">
        <a
          class="btn btn-round btn-light dropdown-toggle pr-5 dr-height"
          data-toggle="dropdown"
          role="button"
          [routerLink]="['/profile/me']"
        >
          <i class="ni ni-ui-04 d-lg-none"></i>
          <span class="nav-link-inner--text">
            <span *ngIf="profile_pic" class="profile_pic"
              ><img [src]="profile_pic" alt="Profile Pic"
            /></span>
            <span *ngIf="!profile_pic" class="profile_pic"
              ><img [src]="'/assets/img/avataaars.png'" alt="Avatar"
            /></span>

            <span *ngIf="name" class="no-transform">{{ name }}</span>
            <span *ngIf="employer" class="no-transform"><br />{{ employer }}</span>

            <span *ngIf="!name">User Menu</span></span
          >
        </a>
        <div class="dropdown-menu-xl dropdown-menu">
          <div class="dropdown-menu-inner">
            <a
              *ngIf="loggedIn && isManager"
              routerLinkActive="active"
              [routerLink]="['/admin/tokens']"
              class="media d-flex align-items-center"
            >
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">
                  {{ 'menu.my-admin-panel' | translate }}
                </h6>
              </div>
            </a>
            <a
              routerLinkActive="active"
              [routerLink]="['/training/my-courses']"
              class="media d-flex align-items-center"
            >
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">{{ 'menu.my-courses' | translate }}</h6>
              </div>
            </a>
            <a
              routerLinkActive="active"
              [routerLink]="['/profile/me']"
              class="media d-flex align-items-center"
            >
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">{{ 'menu.my-profile' | translate }}</h6>
              </div>
            </a>
            <a
              routerLinkActive="active"
              [routerLink]="['/logout']"
              class="media d-flex align-items-center"
            >
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">{{ 'menu.logout' | translate }}</h6>
              </div>
            </a>
          </div>
        </div>
      </li>

      <!-- Login -->
      <li class="nav-item dropdown" *ngIf="!loggedIn">
        <a [routerLink]="'/login'" class="btn btn-sm btn-neutral btn-manager btn-icon">
          <span class="btn-inner--icon">
            <i class="fa fa-sign-in mr-2"></i>
          </span>
          <span class="nav-link-inner--text">{{ 'menu.login' | translate }}</span>
        </a>
      </li>

      <!-- Logout Mobile -->
      <li class="nav-item dropdown d-lg-none" *ngIf="loggedIn">
        <a class="nav-link" href="#" [routerLink]="'/logout'" data-toggle="tooltip" title="Logout">
          <span class="nav-link-inner--text">{{ 'menu.logout' | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
