import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { ChoicesComponent } from './choices/choices.component';
import { CentComponent } from './cent/cent.component';
import { FullScheduleComponent } from './full-schedule/full-schedule.component';
import { SharedModule } from '../shared/shared-module';
import { LoggedInInsetComponent } from './logged-in-inset/logged-in-inset.component';

// Components Routing
const routes: Routes = [{ path: '', component: HomeComponent }];

@NgModule({
  declarations: [
    CentComponent,
    ChoicesComponent,
    FullScheduleComponent,
    LoggedInInsetComponent,
    HomeComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
  exports: [
    CentComponent,
    FullScheduleComponent,
    LoggedInInsetComponent,
    HomeComponent,
    RouterModule
  ]
})
export class HomeModule {}
