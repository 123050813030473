import * as tslib_1 from "tslib";
import { OnInit, ElementRef, OnDestroy, AfterContentInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2 } from 'angulartics2';
import { StickyService } from './shared/sticky-service';
import { environment } from '../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { AuthActions } from './auth/redux/auth.actions';
import { CookieService } from 'ngx-cookie-service';
import { TrainingActions } from './training/redux/training.actions';
import { selectIsBounced, selectJwtToken, selectLanguage, selectUser } from './auth/redux/auth.selectors';
import { Observable, timer, from, Subject } from 'rxjs';
import { concatMap, filter, share, take } from 'rxjs/operators';
import { ApiService, Service } from './service/api.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(renderer, router, angulartics2GoogleAnalytics, angulartics2, translate, document, stickyService, element, location, ngRedux, authActions, trainingActions, cookieService, apiService) {
        this.renderer = renderer;
        this.router = router;
        this.angulartics2 = angulartics2;
        this.translate = translate;
        this.document = document;
        this.stickyService = stickyService;
        this.element = element;
        this.location = location;
        this.ngRedux = ngRedux;
        this.authActions = authActions;
        this.trainingActions = trainingActions;
        this.cookieService = cookieService;
        this.apiService = apiService;
        this.onSubject = new Subject();
        this.changes = this.onSubject.asObservable().pipe(share());
        this.subscriptions = [];
        this.showNewVersion = false;
        this.showNewUser = false;
        console.log("\uD83D\uDCC5 " + environment.version);
        angulartics2GoogleAnalytics.startTracking();
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (environment.production) {
            this.checkVersion(environment.version);
        }
        this.subscriptions.push(this.jwt$.subscribe(function (value) { return (_this.jwt = value); }));
        this.subscriptions.push(this.user$.subscribe(function (value) { return (_this.user = value); }));
        this.subscriptions.push(this.language$.subscribe(function (value) {
            _this.translate.setDefaultLang(value);
            _this.translate.use(value);
            _this.angulartics2.setUserProperties.next({ page_language: value });
        }));
        this.start();
        this.setUpAuthCheck();
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            console.log('🔆 You prefer dark mode');
        }
        else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
            console.log('🔆 You prefer light mode');
        }
        else {
            console.log('🔆 Cannot detect color preference with matchMedia');
        }
        // if (window.matchMedia) {
        //   const mql = window.matchMedia('(prefers-color-scheme: dark)');
        //   mql.addEventListener('change', () => {
        //     const newColorScheme = e.matches ? 'dark' : 'light';
        //     console.log(`🔆 newColorScheme ${newColorScheme}`);
        //   });
        // }
        var navbar = this.element.nativeElement.children[0].children[0];
        this.subscriptions.push(this.router.events
            .filter(function (event) { return event instanceof NavigationEnd; })
            .subscribe(function () {
            _this.renderer.listen('window', 'scroll', function () {
                var scrollY = window.scrollY;
                if (scrollY > 0 || window.pageYOffset > 0) {
                    // add logic
                    navbar.classList.add('headroom--not-top');
                }
                else {
                    // remove logic
                    navbar.classList.remove('headroom--not-top');
                }
            });
        }));
        // this.hasScrolled();
        this.ngRedux.dispatch(this.trainingActions.fetchCoursesAsync());
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.stop();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        if (this.checkAuthTimerHandle !== undefined) {
            clearInterval(this.checkAuthTimerHandle);
        }
        if (this.checkBounceTimerHandle !== undefined) {
            clearInterval(this.checkBounceTimerHandle);
        }
    };
    // @HostListener('window:scroll', ['$event'])
    // hasScrolled() {
    //   const st = window.pageYOffset;
    //   // Make sure they scroll more than delta
    //   if (Math.abs(lastScrollTop - st) <= delta) {
    //     return;
    //   }
    //
    //   const navbar = document.getElementsByTagName('nav')[0];
    //
    //   // If they scrolled down and are past the navbar, add class .headroom--unpinned.
    //   // This is necessary so you never see what is "behind" the navbar.
    //   if (st > lastScrollTop && st > navbarHeight) {
    //     // Scroll Down
    //     if (navbar.classList.contains('headroom--pinned')) {
    //       navbar.classList.remove('headroom--pinned');
    //       navbar.classList.add('headroom--unpinned');
    //       this.stickyService.toggle('unpinned');
    //     }
    //     // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
    //   } else {
    //     // Scroll Up
    //     //  $(window).height()
    //     if (st + window.innerHeight < document.body.scrollHeight) {
    //       // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
    //       if (navbar.classList.contains('headroom--unpinned')) {
    //         navbar.classList.remove('headroom--unpinned');
    //         navbar.classList.add('headroom--pinned');
    //         this.stickyService.toggle('pinned');
    //       }
    //     }
    //   }
    //
    //   lastScrollTop = st;
    // }
    AppComponent.prototype.ngAfterContentInit = function () { };
    AppComponent.prototype.setUpAuthCheck = function () {
        var _this = this;
        if (this.checkAuthTimerHandle === undefined) {
            this.checkAuthTimerHandle = setInterval(function () {
                if (_this.jwt) {
                    _this.ngRedux.dispatch(_this.authActions.checkJwtRefreshAsync());
                }
            }, 60000);
            this.ngRedux.dispatch(this.authActions.validateSession());
        }
        if (this.checkBounceTimerHandle === undefined) {
            this.checkBounceTimerHandle = setInterval(function () {
                if (_this.user && _this.user.email_verified) {
                    clearInterval(_this.checkBounceTimerHandle);
                }
                if (_this.jwt) {
                    _this.ngRedux.dispatch(_this.authActions.checkBounceBackAsync());
                }
            }, 5000);
        }
        // If we are logged in, reload tokens
        if (this.jwt) {
            this.ngRedux.dispatch(this.trainingActions.fetchTokensAsync());
        }
    };
    AppComponent.prototype.switchLanguage = function (lang) {
        localStorage.setItem('language', lang);
        this.translate.use(lang);
    };
    AppComponent.prototype.reload = function () {
        window.location.reload();
        return false;
    };
    AppComponent.prototype.checkVersion = function (currentVersion) {
        var _this = this;
        timer(0, 30000)
            .pipe(concatMap(function () {
            return from(_this.apiService.getUrl(Service.PUBLIC, '/version', { p: environment.project }));
        }))
            .pipe(filter(function (result) { return result.success && result.data !== currentVersion; }))
            .pipe(take(1))
            .subscribe(function () { return (_this.showNewVersion = true); });
    };
    AppComponent.prototype.start = function () {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    };
    AppComponent.prototype.stop = function () {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this.onSubject.complete();
    };
    AppComponent.prototype.storageEventListener = function (event) {
        if (event.storageArea === localStorage) {
            var v = void 0;
            try {
                v = JSON.parse(event.newValue);
            }
            catch (e) {
                v = event.newValue ? event.newValue : undefined;
            }
            this.onSubject.next({ key: event.key, value: v });
            if (event.key === 'id') {
                this.showNewUser = true;
            }
        }
    };
    tslib_1.__decorate([
        select(selectLanguage),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "language$", void 0);
    tslib_1.__decorate([
        select(selectJwtToken),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "jwt$", void 0);
    tslib_1.__decorate([
        select(selectUser),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        select(selectIsBounced),
        tslib_1.__metadata("design:type", Observable)
    ], AppComponent.prototype, "bounced$", void 0);
    return AppComponent;
}());
export { AppComponent };
