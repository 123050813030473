const AdminTypes = {
  CLEAR_ADMIN_ERROR: 'CLEAR_ADMIN_ERROR',
  SUBSCRIBER_CREDIT_CARD_FETCH_START: 'SUBSCRIBER_CREDIT_CARD_FETCH_START',
  SUBSCRIBER_CREDIT_CARD_FETCH_SUCCESS: 'SUBSCRIBER_CREDIT_CARD_FETCH_SUCCESS',
  SUBSCRIBER_CREDIT_CARD_FETCH_FAILURE: 'SUBSCRIBER_CREDIT_CARD_FETCH_FAILURE',
  SUBSCRIBER_CREDIT_CARD_UPDATE_START: 'SUBSCRIBER_CREDIT_CARD_UPDATE_START',

  SUBSCRIBER_ACCOUNT_FETCH_START: 'SUBSCRIBER_ACCOUNT_FETCH_START',
  SUBSCRIBER_ACCOUNT_FETCH_SUCCESS: 'SUBSCRIBER_ACCOUNT_FETCH_SUCCESS',
  SUBSCRIBER_ACCOUNT_FETCH_FAILURE: 'SUBSCRIBER_ACCOUNT_FETCH_FAILURE',

  SUBSCRIBER_PURCHASES_CLEAR: 'SUBSCRIBER_PURCHASES_CLEAR',
  SUBSCRIBER_PURCHASES_FETCH_START: 'SUBSCRIBER_PURCHASES_FETCH_START',
  SUBSCRIBER_PURCHASES_FETCH_PROGRESS: 'SUBSCRIBER_PURCHASES_FETCH_PROGRESS',
  SUBSCRIBER_PURCHASES_SET_LAZY: 'SUBSCRIBER_PURCHASES_SET_LAZY',
  SUBSCRIBER_PURCHASES_FETCH_SUCCESS: 'SUBSCRIBER_PURCHASES_FETCH_SUCCESS',
  SUBSCRIBER_PURCHASES_FETCH_FAILURE: 'SUBSCRIBER_PURCHASES_FETCH_FAILURE',
  SUBSCRIBER_PURCHASES_PAGINATE: 'SUBSCRIBER_PURCHASES_PAGINATE',
  SUBSCRIBER_PURCHASES_SHOWING: 'SUBSCRIBER_PURCHASES_SHOWING',
  SUBSCRIBER_PURCHASES_SEARCH: 'SUBSCRIBER_PURCHASES_SEARCH',
  SUBSCRIBER_PURCHASES_UPDATE: 'SUBSCRIBER_PURCHASES_UPDATE',
  SUBSCRIBER_PURCHASES_UPDATE_SORT: 'SUBSCRIBER_PURCHASES_UPDATE_SORT',

  SUBSCRIBER_TOKEN_UPDATE: 'SUBSCRIBER_TOKEN_UPDATE',
  SUBSCRIBER_TOKENS_FETCH_START: 'SUBSCRIBER_TOKENS_FETCH_START',
  SUBSCRIBER_TOKENS_FETCH_PROGRESS: 'SUBSCRIBER_TOKENS_FETCH_PROGRESS',
  SUBSCRIBER_TOKENS_FETCH_SUCCESS: 'SUBSCRIBER_TOKENS_FETCH_SUCCESS',
  SUBSCRIBER_TOKENS_FETCH_FAILURE: 'SUBSCRIBER_TOKENS_FETCH_FAILURE',
  SUBSCRIBER_TOKENS_PAGINATE: 'SUBSCRIBER_TOKENS_PAGINATE',
  SUBSCRIBER_TOKENS_SET_TOTAL: 'SUBSCRIBER_TOKENS_SET_TOTAL',
  SUBSCRIBER_TOKENS_SHOWING: 'SUBSCRIBER_TOKENS_SHOWING',
  SUBSCRIBER_TOKENS_SEARCH: 'SUBSCRIBER_TOKENS_SEARCH',
  SUBSCRIBER_TOKENS_UPDATE_SORT: 'SUBSCRIBER_TOKENS_UPDATE_SORT',

  SUBSCRIBER_WEBHOOK_CLEAR: 'SUBSCRIBER_WEBHOOK_CLEAR',
  SUBSCRIBER_WEBHOOK_FETCH_START: 'SUBSCRIBER_WEBHOOK_FETCH_START',
  SUBSCRIBER_WEBHOOK_FETCH_PROGRESS: 'SUBSCRIBER_WEBHOOK_FETCH_PROGRESS',
  SUBSCRIBER_WEBHOOK_SET_LAZY: 'SUBSCRIBER_WEBHOOK_SET_LAZY',
  SUBSCRIBER_WEBHOOK_FETCH_SUCCESS: 'SUBSCRIBER_WEBHOOK_FETCH_SUCCESS',
  SUBSCRIBER_WEBHOOK_FETCH_FAILURE: 'SUBSCRIBER_WEBHOOK_FETCH_FAILURE',
  SUBSCRIBER_WEBHOOK_PAGINATE: 'SUBSCRIBER_WEBHOOK_PAGINATE',
  SUBSCRIBER_WEBHOOK_SHOWING: 'SUBSCRIBER_WEBHOOK_SHOWING',
  SUBSCRIBER_WEBHOOK_SEARCH: 'SUBSCRIBER_WEBHOOK_SEARCH',
  SUBSCRIBER_WEBHOOK_UPDATE: 'SUBSCRIBER_WEBHOOK_UPDATE',
  SUBSCRIBER_WEBHOOK_UPDATE_SORT: 'SUBSCRIBER_WEBHOOK_UPDATE_SORT',

  SUBSCRIBER_USER_ACTION_START: 'SUBSCRIBER_USER_ACTION_START',
  SUBSCRIBER_PROFILE_SAVE_SUCCESS: 'SUBSCRIBER_PROFILE_SAVE_SUCCESS',
  SUBSCRIBER_PROFILE_SAVE_FAILURE: 'SUBSCRIBER_PROFILE_SAVE_FAILURE'
};

export default AdminTypes;
