/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./full-schedule/full-schedule.component.ngfactory";
import * as i3 from "./full-schedule/full-schedule.component";
import * as i4 from "./cent/cent.component.ngfactory";
import * as i5 from "./cent/cent.component";
import * as i6 from "../service/api.service";
import * as i7 from "@angular/common";
import * as i8 from "./home.component";
import * as i9 from "@angular/router";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-schedule", [], null, null, null, i2.View_FullScheduleComponent_0, i2.RenderType_FullScheduleComponent)), i1.ɵdid(1, 114688, null, 0, i3.FullScheduleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cent", [], null, null, null, i4.View_CentComponent_0, i4.RenderType_CentComponent)), i1.ɵdid(1, 114688, null, 0, i5.CentComponent, [i6.ApiService], { loggingIn: [0, "loggingIn"], loggedIn: [1, "loggedIn"], welcome: [2, "welcome"], forgot: [3, "forgot"], timeout: [4, "timeout"], loginStep: [5, "loginStep"], loginStepChoices: [6, "loginStepChoices"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loggingIn; var currVal_1 = _co.loggedIn; var currVal_2 = _co.welcome; var currVal_3 = _co.forgot; var currVal_4 = _co.timeout; var currVal_5 = _co.loginStep; var currVal_6 = _co.loginStepChoices; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branding == "fs"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.branding == "cent"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 245760, null, 0, i8.HomeComponent, [i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i8.HomeComponent, View_HomeComponent_Host_0, { destination: "destination" }, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
