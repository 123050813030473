export enum PackageTypes {
  TYPE_INDIVIDUAL = 'indiv',
  TYPE_BULK = 'bulk',
  TYPE_SUBSCRIPTION = 'sub',
  TYPE_SAVING_CARD = 'save'
}

export enum PackageAuto {
  ONE = 'one',
  AUTO = 'auto',
  MANUAL = 'manual'
}
