import * as tslib_1 from "tslib";
import { select } from '@angular-redux/store';
import { selectIsManager } from '../../auth/redux/auth.selectors';
import { Observable } from 'rxjs/internal/Observable';
var LoggedInInsetComponent = /** @class */ (function () {
    function LoggedInInsetComponent() {
    }
    tslib_1.__decorate([
        select(selectIsManager),
        tslib_1.__metadata("design:type", Observable)
    ], LoggedInInsetComponent.prototype, "isManager$", void 0);
    return LoggedInInsetComponent;
}());
export { LoggedInInsetComponent };
