<main>
  <div class="position-relative">
    <!-- shape Hero -->
    <section class="section section-lg section-shaped section-landingx pb-250">
      <div class="shape shape-style-1 shape-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-lg-mdx d-flex home-page-formatting">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-12 headline">
              <div class="row alert-info align-content-center" *ngIf="welcome">
                <div class="col-md-12 text-center">
                  <h5 class="text-white">{{ 'home.email-confirmed' | translate }}</h5>
                </div>
              </div>
              <div class="row alert-info align-content-center" *ngIf="forgot && !loggedIn">
                <div class="col-md-12 text-center">
                  <h5 class="text-white">{{ 'home.email-resent' | translate }}</h5>
                </div>
              </div>

              <div class="row alert alert-danger align-content-center" *ngIf="timeout && !loggedIn">
                <div class="col-md-12 text-center">
                  <h5 class="text-white">{{ 'home.timed-out' | translate }}</h5>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h1 class="marketing-headline display-3 text-white text-center headline-box">
                    {{ 'home.headline' | translate }}<br />
                    {{ 'home.get-accredited' | translate }}
                  </h1>
                </div>
              </div>

              <div class="row pad-below">
                <div class="col-md-7">
                  <div
                    class="marketing-block text-white links-white"
                    [innerHTML]="'home.marketing-cent' | translate"
                  ></div>
                </div>
                <div class="col-md-5 order-first order-md-5">
                  <app-login-inset
                    *ngIf="!loggedIn"
                    (loginEvent)="setChoice($event)"
                    [goAfter]="true"
                  >
                  </app-login-inset>
                  <!--                  <app-register *ngIf="!loggedIn && loginStepChoices && loginStep == loginStepChoices.NEW" (afterChoicePicked)="setChoice($event)">-->
                  <!--                  </app-register>-->
                  <!--                  <app-choices *ngIf="loginStepChoices && loginStep == loginStepChoices.CHOICES"-->
                  <!--                               (choicePicked)="setChoice($event)"-->
                  <!--                               [loggedIn]="loggedIn"-->
                  <!--                  >-->
                  <!--                  </app-choices>-->
                  <!--                  <app-login-inset *ngIf="!loggedIn && loginStepChoices && loginStep == loginStepChoices.EXISTING">-->
                  <!--                  </app-login-inset>-->
                  <app-logged-in *ngIf="loggedIn"></app-logged-in>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 80"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 80 0 100"></polygon>
        </svg>
      </div>
    </section>
    <!-- 1st Hero Variation -->
  </div>

  <section class="section pt-lg-0 mt--150 d-none d-sm-block" *ngIf="showPlug">
    <div class="container">
      <div class="row justify-content-center mt-2">
        <div class="col-lg-12">
          <div class="row row-grid">
            <div class="card shadow border-0 width-100">
              <div class="card-body">
                <div class="row h5 business-plug">
                  We're busy! Our most recent students today work for businesses including:
                </div>
                <div class="row">
                  <div class="col-md-3 text-center" *ngFor="let item of showPlug">
                    <div class="btn btn-primary btn-business">
                      <p class="business-name">{{ item.name }}</p>
                      <p class="business-address">{{ item.city }}, {{ item.state }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lg pt-lg-0" [ngClass]="showPlug ? 'mt--250' : 'mt--150'">
    <div class="container">
      <div class="row justify-content-center mt-2">
        <div class="col-lg-12">
          <div class="row row-grid">
            <div class="card card-lift--hover shadow border-0">
              <div class="card-body py-4">
                <div class="icon icon-shape icon-shape-success rounded-circle mb-4 mr-3 float-left">
                  <i class="ni ni-check-bold"></i>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <h3>{{ 'home.course-title' | translate }}</h3>
                    <p>{{ 'home.course-description' | translate }}</p>
                  </div>
                  <div class="col-md-7">
                    <table class="table table-striped">
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 1:
                          {{ 'home.module.1' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 2:
                          {{ 'home.module.2' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 3:
                          {{ 'home.module.3' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 4:
                          {{ 'home.module.4' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 5:
                          {{ 'home.module.5' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 6:
                          {{ 'home.module.6' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ 'home.module.module' | translate }} 7:
                          {{ 'home.module.7' | translate }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img [src]="'assets/img/theme/sample-certs.png'" class="img-fluid floating" alt="promo" />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
              <i class="ni ni-settings-gear-65"></i>
            </div>
            <h3>{{ 'home.accreditation-title' | translate }}</h3>
            <p>{{ 'home.accreditation-description' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-12 order-md-1">
          <div class="pr-md-5">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
              <i class="ni ni-book-bookmark"></i>
            </div>
            <app-faq></app-faq>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
