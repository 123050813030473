import { Injectable } from '@angular/core';
import { NewPasswordUser } from '../auth/new-password/new-password.component';
import { ApiService, Service } from './api.service';
import { RegistrationUser } from '../shared/models/registration-user.model';

@Injectable()
export class UserRegistrationService {
  constructor(public apiService: ApiService) {}

  async register(user: RegistrationUser) {
    const attributeList = [];
    user.email = user.email.toLowerCase();

    return this.apiService.postUrl(Service.PUBLIC, '/users', user);
  }

  confirmRegistration(username: string, confirmationCode: string): void {}

  resendCode(username: string): void {}

  newPassword(newPasswordUser: NewPasswordUser): void {}
}
