import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: 'courses/employer-tokens', redirectTo: '/admin/tokens', pathMatch: 'full' },
  { path: 'courses/employee-tokens', redirectTo: '/admin/tokens', pathMatch: 'full' },
  {
    path: 'employer-bulk-token-packages',
    redirectTo: '/business/bulk-token-packages',
    pathMatch: 'full'
  },
  {
    path: 'courses/employer-purchase',
    redirectTo: '/business/bulk-token-packages',
    pathMatch: 'full'
  },
  { path: 'signin', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'signout', redirectTo: '/auth/logout', pathMatch: 'full' },
  { path: 'login', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'logout', redirectTo: '/auth/logout', pathMatch: 'full' },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
  {
    path: 'business',
    loadChildren: () => import('./business/business.module').then((m) => m.BusinessModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./training/training.module').then((m) => m.TrainingModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./verify/verify.module').then((m) => m.VerifyModule)
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule {}
