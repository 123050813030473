import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginStep } from '../shared/models/login-step.model';
import { Observable } from 'rxjs/internal/Observable';
import { selectIsLoggedIn } from '../auth/redux/auth.selectors';
import { select } from '@angular-redux/store';
import { ActivatedRoute } from '@angular/router';
export class HomeComponent {
    constructor(route) {
        this.route = route;
        this.subscriptions = [];
        this.loggingIn = false;
        this.loggedIn = false;
        this.welcome = false;
        this.forgot = false;
        this.timeout = false;
        this.loginStep = LoginStep.NEW;
        this.loginStepChoices = LoginStep;
        this.branding = environment.branding;
    }
    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.timeout = params['timeout'] === 'true';
        });
        this.subscriptions.push(this.loggedIn$.subscribe((loggedIn) => {
            this.loggedIn = loggedIn;
        }));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
tslib_1.__decorate([
    select(selectIsLoggedIn),
    tslib_1.__metadata("design:type", Observable)
], HomeComponent.prototype, "loggedIn$", void 0);
