import { Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./about/about.module.ngfactory").then(function (m) { return m.AboutModuleNgFactory; }); }, ɵ2 = function () { return import("./admin/admin.module.ngfactory").then(function (m) { return m.AdminModuleNgFactory; }); }, ɵ3 = function () { return import("./auth/auth.module.ngfactory").then(function (m) { return m.AuthModuleNgFactory; }); }, ɵ4 = function () { return import("./business/business.module.ngfactory").then(function (m) { return m.BusinessModuleNgFactory; }); }, ɵ5 = function () { return import("./profile/profile.module.ngfactory").then(function (m) { return m.ProfileModuleNgFactory; }); }, ɵ6 = function () { return import("./signup/signup.module.ngfactory").then(function (m) { return m.SignupModuleNgFactory; }); }, ɵ7 = function () { return import("./training/training.module.ngfactory").then(function (m) { return m.TrainingModuleNgFactory; }); }, ɵ8 = function () { return import("./verify/verify.module.ngfactory").then(function (m) { return m.VerifyModuleNgFactory; }); };
var routes = [
    { path: 'courses/employer-tokens', redirectTo: '/admin/tokens', pathMatch: 'full' },
    { path: 'courses/employee-tokens', redirectTo: '/admin/tokens', pathMatch: 'full' },
    {
        path: 'employer-bulk-token-packages',
        redirectTo: '/business/bulk-token-packages',
        pathMatch: 'full'
    },
    {
        path: 'courses/employer-purchase',
        redirectTo: '/business/bulk-token-packages',
        pathMatch: 'full'
    },
    { path: 'signin', redirectTo: '/auth/login', pathMatch: 'full' },
    { path: 'signout', redirectTo: '/auth/logout', pathMatch: 'full' },
    { path: 'login', redirectTo: '/auth/login', pathMatch: 'full' },
    { path: 'logout', redirectTo: '/auth/logout', pathMatch: 'full' },
    {
        path: '',
        pathMatch: 'full',
        loadChildren: ɵ0
    },
    { path: 'about', loadChildren: ɵ1 },
    { path: 'admin', loadChildren: ɵ2 },
    { path: 'auth', loadChildren: ɵ3 },
    {
        path: 'business',
        loadChildren: ɵ4
    },
    {
        path: 'profile',
        loadChildren: ɵ5
    },
    {
        path: 'signup',
        loadChildren: ɵ6
    },
    {
        path: 'training',
        loadChildren: ɵ7
    },
    {
        path: 'verify',
        loadChildren: ɵ8
    },
    { path: '**', component: NotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
