import AuthTypes from './auth.types';
import { INITIAL_STATE } from './auth.state';

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.ERROR_CLEAR:
      return {
        ...state,
        error: null
      };
    case AuthTypes.ERROR_SET:
      return {
        ...state,
        error: action.payload
      };
    case AuthTypes.LOGIN:
      return {
        ...state,
        user: action.payload.me,
        bounced: action.payload.me.emailBounced,
        claims: action.payload.claims,
        loggedIn: true,
        language: action.payload.me.language
      };
    case AuthTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false
      };
    case AuthTypes.CHANGE_LANGUAGE:
      if (state.loggedIn) {
        state.user.language = action.payload;
      }
      return {
        ...state,
        language: action.payload,
        user: state.user
      };
    case AuthTypes.SET_BOUNCED:
      return {
        ...state,
        bounced: action.payload
      };
    case AuthTypes.SET_EMPLOYER:
      return {
        ...state,
        user: {
          ...state.user,
          employer_object: action.payload,
          employer: action.payload.name
        }
      };
    case AuthTypes.SET_SECONDARY:
      return {
        ...state,
        user: {
          ...state.user,
          secondary_id_required: true
        }
      };
    case AuthTypes.SET_EMPLOYER_CLEAR:
      return {
        ...state,
        user: {
          ...state.user,
          employer_object: undefined,
          employer: action.undefined
        }
      };
    case AuthTypes.SIGNUP_NEXT_STEP:
      return {
        ...state,
        signUpStep: action.payload
      };
    case AuthTypes.SET_BROWSER:
      return {
        ...state,
        browserInfo: action.payload
      };
    case AuthTypes.CHECK_JWT:
      // We only check this for a logged in user
      if (state.loggedIn) {
        const user = state.user;
        // state.user.language = action.payload;
        return {
          ...state,
          language: action.payload,
          user
        };
      } else {
        return {
          ...state
        };
      }
    case AuthTypes.PROFILE_SAVE_FAILURE:
      return {
        ...state,
        blnRunningAction: false,
        error: action.payload
      };
    case AuthTypes.USER_ACTION_START:
      return {
        ...state,
        blnRunningAction: true,
        error: null
      };
    case AuthTypes.USER_ACTION_END:
      return {
        ...state,
        blnRunningAction: false
      };
    case AuthTypes.USER_SET:
      return {
        ...state,
        user: action.payload,
        bounced: action.payload.emailBounced
      };
    case AuthTypes.USER_SET_FIELD:
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.field]: action.payload.value
        }
      };
    default:
      return state;
  }
};

export default authReducer;
