<div
  class="on-top alert alert-danger alert-dismissible"
  [ngClass]="partiallyHidden ? 'partially-hide' : ''"
>
  <button
    *ngIf="!partiallyHidden"
    (click)="dismissError()"
    type="button"
    class="close btn btn-primary"
    data-dismiss="alert"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div (click)="dismissError()">
    <span class="message-icon fa fa-exclamation-triangle"></span>
  </div>
  <span class="message" *ngIf="objUser.language === 'en' && !partiallyHidden"
    >&nbsp;ERROR: Email <strong>{{ objUser.email }}</strong> rejected as invalid (bounced). Go to
    <a [routerLink]="['/profile/me']" fragment="edit">Edit Profile</a> and correct your email
    address to continue.</span
  >
  <span class="message" *ngIf="objUser.language === 'es' && !partiallyHidden"
    >&nbsp;ADVERTENCIA: Su dirección de correo electrónico {{ objUser.email }} no es válida. Haga
    clic en <a [routerLink]="['/profile/me']" fragment="edit">Editar perfil</a> y corrija su correo
    electrónico.
  </span>
</div>
