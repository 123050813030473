export const environment = {
  version: '2024-1201-1114',
  project: 'fs',
  branding: 'cent', // 'cent' or 'fs'
  region: 'us-east-2',
  rollbarKey: '4c5f62db0e634d8895c5888312fd9fef',
  apiHeaderName: 'x-api-key',
  apiHeaderValue: 'xxx',
  googleLogin: '936412002727-f0jjm3a8n1h7n9dmecv83bf4jajps1tk.apps.googleusercontent.com',
  apiVerify: 'live_dd951e83b788a312e8dd',
  production: true,
  env: 'prod',
  adminUrl: 'https://api.fullschedule.com/admin',
  publicUrl: 'https://api.fullschedule.com/public',
  stripeUrl: 'https://api.fullschedule.com/stripe',
  assetsUrl: 'https://assets.fullschedule.com/',
  docsUrl: 'https://developer.fullschedule.com/',
  stripeKey: 'pk_live_2nn4wnfTOvTuEovkmPGrSqY7',
  siteTitle: '99CentFoodHandler.com | Only $0.99 | Texas Food Handler Certificate 99 Cent',
  siteBrand: '99CentFoodHandler',
  gaCode: 'UA-136765783-1'
};
