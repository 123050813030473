import * as tslib_1 from "tslib";
import AuthTypes from './auth.types';
import { NgRedux } from '@angular-redux/store';
import { ApiService, Service } from '../../service/api.service';
import * as jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { GoogleLoginProvider } from 'angularx-social-login';
import { GoogleService } from 'social-login-oauth';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from '../../shared/string-helper';
import { UserStatus } from '../../shared/models/user-status.model';
var detect = require('detect-browser').detect;
var AuthActions = /** @class */ (function () {
    function AuthActions(ngRedux, router, apiService, cookieService, translate) {
        var _this = this;
        this.ngRedux = ngRedux;
        this.router = router;
        this.apiService = apiService;
        this.cookieService = cookieService;
        this.translate = translate;
        this.changeLanguage = function (language) { return ({
            type: AuthTypes.CHANGE_LANGUAGE,
            payload: language
        }); };
        this.errorClear = function () { return ({
            type: AuthTypes.ERROR_CLEAR,
            payload: null
        }); };
        this.errorSet = function (error) { return ({
            type: AuthTypes.ERROR_SET,
            payload: error
        }); };
        this.loginSuccess = function (user) {
            try {
                console.log(user);
                localStorage.setItem('jwt', user.claims.token);
                localStorage.setItem('id', user.claims.sub);
                console.log("LOGIN user_id " + user.claims.sub);
                return {
                    type: AuthTypes.LOGIN,
                    payload: user
                };
            }
            catch (error) {
                console.error(error);
                alert('Browser error attempting to write cookies for login. Disabling cookies will cause this website to error.');
            }
        };
        this.setEmployer = function (employer_object) { return ({
            type: AuthTypes.SET_EMPLOYER,
            payload: employer_object
        }); };
        this.setSecondary = function () { return ({
            type: AuthTypes.SET_SECONDARY
        }); };
        this.setBounced = function (value) { return ({
            type: AuthTypes.SET_BOUNCED,
            payload: value
        }); };
        this.clearEmployer = function () { return ({
            type: AuthTypes.SET_EMPLOYER_CLEAR
        }); };
        this.logout = function () { return ({
            type: AuthTypes.LOGOUT
        }); };
        this.checkJwt = function () { return ({
            type: AuthTypes.CHECK_JWT
        }); };
        this.userSet = function (objUser) { return ({
            type: AuthTypes.USER_SET,
            payload: objUser
        }); };
        this.userSetField = function (field, value) { return ({
            type: AuthTypes.USER_SET_FIELD,
            payload: { field: field, value: value }
        }); };
        this.userFailure = function (error) { return ({
            type: AuthTypes.PROFILE_SAVE_FAILURE,
            payload: error
        }); };
        this.setBrowserInfo = function (data) { return ({
            type: AuthTypes.SET_BROWSER,
            payload: data
        }); };
        this.signUpNextStep = function (step) {
            return {
                type: AuthTypes.SIGNUP_NEXT_STEP,
                payload: step ? step : undefined
            };
        };
        this.userStartAction = function () { return ({
            type: AuthTypes.USER_ACTION_START
        }); };
        this.userStopAction = function () { return ({
            type: AuthTypes.USER_ACTION_END
        }); };
        this.changeLanguageAsync = function (language) {
            return function (dispatch, state) {
                dispatch(_this.changeLanguage(language));
                _this.translate.use(language);
                var globalState = state();
                if (globalState.auth.user) {
                    var objUser = tslib_1.__assign({}, globalState.auth.user, { language: language });
                    dispatch(_this.userProfileSaveAsync(objUser));
                }
            };
        };
        this.checkJwtRefreshAsync = function (forceRenewal, callback) {
            if (forceRenewal === void 0) { forceRenewal = false; }
            return function (dispatch) {
                var exp = 0, now = 0;
                var token = localStorage.getItem('jwt');
                if (!forceRenewal) {
                    var objUser = jwtDecode(token);
                    exp = objUser.exp;
                    now = Date.now().valueOf() / 1000;
                }
                if (forceRenewal || exp - 300 < now) {
                    if (!forceRenewal) {
                        console.log('expired token');
                        dispatch(_this.logout());
                        _this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
                        return;
                    }
                    if (forceRenewal) {
                        console.log('forced refresh');
                    }
                    _this.apiService
                        .getUrl(Service.PUBLIC, '/users/refresh')
                        .then(function (response) {
                        console.log(response);
                        if (response.statusCode) {
                            switch (response.statusCode) {
                                case 200:
                                    var data = response.data;
                                    console.log(data);
                                    dispatch(_this.loginSuccess(data));
                                    _this.translate.use(data.me.language || 'en');
                                    if (callback) {
                                        callback();
                                    }
                                    break;
                                case 403:
                                    dispatch(_this.logout());
                                    _this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
                                    break;
                                default:
                                    dispatch(_this.logout());
                            }
                        }
                        else {
                            var data = response.data;
                            dispatch(_this.loginSuccess(data));
                        }
                    })
                        .catch(function (response) {
                        console.log(response);
                        if (response.statusCode) {
                            switch (response.statusCode) {
                                case 403:
                                    dispatch(_this.logout());
                                    _this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
                                    break;
                                default:
                                    dispatch(_this.logout());
                            }
                        }
                    });
                }
            };
        };
        this.checkBounceBackAsync = function () {
            return function (dispatch, state) {
                var globalState = state();
                var email = globalState.auth.user && globalState.auth.user.email
                    ? globalState.auth.user.email
                    : undefined;
                if (email) {
                    _this.apiService
                        .postUrl(Service.PUBLIC, '/user/confirmed', { email: email }, false)
                        .then(function (response) {
                        if (response.data === UserStatus.BOUNCED) {
                            dispatch(_this.setBounced(true));
                        }
                        if (response.data === UserStatus.CONFIRMED) {
                            dispatch(_this.setBounced(false));
                            if (!globalState.auth.user.email_verified) {
                                dispatch(_this.checkJwtRefreshAsync(true));
                            }
                        }
                    })
                        .catch(function (error) {
                        console.error(error);
                    });
                }
            };
        };
        this.findLocationAsync = function () {
            return function (dispatch) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var objData, browser, locationResult, error_1;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            objData = {};
                            try {
                                browser = detect();
                                if (browser) {
                                    objData['browser'] = browser.name + ',' + browser.version + ',' + browser.os;
                                    objData['browser'] = navigator.userAgent;
                                }
                                dispatch(this.setBrowserInfo(navigator.userAgent));
                            }
                            catch (error) {
                                // ignore if blocked
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, this.apiService.getIUrl('https://api.ipdata.co/?api-key=5e2dd317db394a508da972257841d91027a2d032aaba3f9f04ef2e2c')];
                        case 2:
                            locationResult = _a.sent();
                            objData['location'] = locationResult.city + ', ' + locationResult.region_code;
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            return [3 /*break*/, 4];
                        case 4:
                            try {
                                this.apiService.postUrl(Service.PUBLIC, '/users/me', objData).then().catch();
                            }
                            catch (error) {
                                // ignore if blocked
                            }
                            return [2 /*return*/];
                    }
                });
            }); };
        };
        this.userLoginAsync = function (email, password) {
            console.log("\uD83D\uDC4D userLoginAsync");
            return function (dispatch) {
                dispatch(_this.userStartAction());
                _this.apiService
                    .postUrl(Service.PUBLIC, '/users/login', { email: email, password: password })
                    .then(function (response) {
                    console.log(response);
                    if (response.success) {
                        var data = response.data;
                        dispatch(_this.loginSuccess(data));
                        dispatch(_this.findLocationAsync());
                        dispatch(_this.userStopAction());
                    }
                    else {
                        dispatch(_this.errorSet(response.error));
                        dispatch(_this.userStopAction());
                    }
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.errorSet(response.error.error));
                    dispatch(_this.userStopAction());
                });
            };
        };
        this.userLoginThirdPartyAsync = function (provider) {
            console.log("\uD83D\uDC4D userLoginThirdPartyAsync");
            return function (dispatch) {
                dispatch(_this.userStartAction());
                console.log('this.socialLogin.signIn');
                console.log(GoogleLoginProvider.PROVIDER_ID);
                GoogleService.signIn(environment.googleLogin)
                    .then(function (socialResponse) {
                    _this.apiService
                        .postUrl(Service.PUBLIC, "/users/login/" + provider, socialResponse)
                        .then(function (response) {
                        console.log(response);
                        if (response.success) {
                            var data = response.data;
                            dispatch(_this.loginSuccess(data));
                            dispatch(_this.findLocationAsync());
                            dispatch(_this.userStopAction());
                        }
                        else {
                            dispatch(_this.errorSet(response.error));
                            dispatch(_this.userStopAction());
                        }
                    })
                        .catch(function (error) {
                        console.log(error);
                        dispatch(_this.errorSet(error.message));
                        dispatch(_this.userStopAction());
                    });
                })
                    .catch(function (error) {
                    console.log(error);
                    dispatch(_this.errorSet(error.details));
                    dispatch(_this.userStopAction());
                });
            };
        };
        this.userRegisterAsync = function (profile) {
            console.log("\uD83D\uDC4D userRegisterAsync");
            return function (dispatch) {
                dispatch(_this.userStartAction());
                _this.apiService
                    .postUrl(Service.PUBLIC, '/users', profile, false)
                    .then(function (response) {
                    if (response.success) {
                        setTimeout(function () { }, 2000);
                        dispatch(_this.userLoginAsync(profile.email, profile.password));
                    }
                    else {
                        dispatch(_this.errorSet(response.error));
                        dispatch(_this.userStopAction());
                    }
                })
                    .catch(function (error) {
                    console.log(error);
                    dispatch(_this.userFailure(StringHelper.extractError(error)));
                });
            };
        };
        this.UserResendVerifyEmailAsync = function (email) {
            return function (dispatch) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var response, error_2;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.apiService.postUrl(Service.PUBLIC, '/users/verify-email', { email: email })];
                        case 1:
                            response = _a.sent();
                            if (response.data.refresh) {
                                dispatch(this.checkJwtRefreshAsync(true));
                            }
                            return [2 /*return*/, true];
                        case 2:
                            error_2 = _a.sent();
                            console.error(error_2);
                            alert(StringHelper.extractError(error_2));
                            return [2 /*return*/, false];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
        };
        this.userProfileSaveAsync = function (profile, nextStep) {
            return function (dispatch) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var response, error_3;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dispatch(this.userStartAction());
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, this.apiService.postUrl(Service.PUBLIC, '/users/me', profile)];
                        case 2:
                            response = _a.sent();
                            dispatch(this.userSet(response.data));
                            dispatch(this.checkJwtRefreshAsync(true));
                            dispatch(this.userStopAction());
                            if (nextStep) {
                                this.ngRedux.dispatch(this.signUpNextStep(nextStep));
                            }
                            return [2 /*return*/, AuthTypes.SUCCESS];
                        case 3:
                            error_3 = _a.sent();
                            console.log('userProfileSaveAsync error');
                            console.log(error_3);
                            dispatch(this.userFailure(StringHelper.extractError(error_3)));
                            dispatch(this.userStopAction());
                            return [2 /*return*/, AuthTypes.FAILURE];
                        case 4: return [2 /*return*/];
                    }
                });
            }); };
        };
        this.validateSession = function () {
            return function (dispatch, state) {
                var globalState = state();
                if (globalState.auth.user && !localStorage.getItem('jwt')) {
                    // Somehow we have a logged in user yet no token, so run a logout
                    console.log('validateSession finds user with no jwt token');
                    dispatch(_this.logout());
                }
                if (globalState.auth.user && localStorage.getItem('jwt')) {
                    var objUser = jwtDecode(localStorage.getItem('jwt'));
                    if (objUser.exp < Date.now().valueOf() / 1000) {
                        console.log('validateSession finds Expired token');
                        _this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
                        dispatch(_this.logout());
                    }
                }
            };
        };
    }
    return AuthActions;
}());
export { AuthActions };
