import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { selectUser } from '../../auth/redux/auth.selectors';
import { Observable } from 'rxjs/internal/Observable';
var BouncedAlertComponent = /** @class */ (function () {
    function BouncedAlertComponent() {
        this.subscriptions = [];
        this.partiallyHidden = false;
    }
    BouncedAlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.user$.subscribe(function (value) { return (_this.objUser = value); }));
    };
    BouncedAlertComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    BouncedAlertComponent.prototype.dismissError = function () {
        this.partiallyHidden = !this.partiallyHidden;
    };
    tslib_1.__decorate([
        select(selectUser),
        tslib_1.__metadata("design:type", Observable)
    ], BouncedAlertComponent.prototype, "user$", void 0);
    return BouncedAlertComponent;
}());
export { BouncedAlertComponent };
