import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class StickyService {
  private _toggle = new Subject();
  toggle$ = this._toggle.asObservable();

  toggle(todo) {
    this._toggle.next(todo);
  }
}
