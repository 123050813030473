import { Component, ErrorHandler, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RollbarService } from '../rollbar';
import Rollbar from 'rollbar';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(public router: Router, @Inject(RollbarService) private rollbar: Rollbar) {}

  ngOnInit() {
    console.log(`Not found ${this.router.url}`);
    this.rollbar.error(`Not found ${this.router.url}`);
  }
}
