<main>
  <div class="position-relative">
    <!-- shape Hero -->
    <section class="section section-lg section-shaped section-landingx pb-250">
      <div class="shape shape-style-1 shape-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <!--        <span></span>-->
        <!--        <span></span>-->
        <!--        <span></span>-->
        <!--        <span></span>-->
        <!--        <span></span>-->
      </div>
      <div class="container py-lg-mdx d-flex">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-12 headline">
              <h1 class="display-3 text-white text-center headline-box">
                Certification Training, plus Expiry Tracker.<br />Online Texas Food Handler &amp;
                TABC Courses.<br />All Included.
              </h1>
              <br clear="both" />
              <span></span>
              <div class="row">
                <div class="col-sm-6">
                  <p class="lead text-white">
                    For one low monthly fee, we not only keep track of all your employee
                    certification compliance, <u>we also handle training</u> all your employees who
                    need Food Handler and/or TABC, all for the lowest prices anywhere. We are your
                    full certification solution in Texas.
                  </p>
                </div>
                <div class="col-sm-6 d-none d-sm-block">
                  <mdb-carousel [isControls]="false" [animation]="'slide'">
                    <mdb-carousel-item>
                      <img
                        class="d-block w-100"
                        [src]="'/assets/img/home/slide1.jpg'"
                        alt="First slide"
                      />
                    </mdb-carousel-item>
                    <mdb-carousel-item>
                      <img
                        class="d-block w-100"
                        [src]="'/assets/img/home/slide2.jpg'"
                        alt="Second slide"
                      />
                    </mdb-carousel-item>
                    <mdb-carousel-item>
                      <img
                        class="d-block w-100"
                        [src]="'/assets/img/home/slide3.jpg'"
                        alt="Third slide"
                      />
                    </mdb-carousel-item>
                  </mdb-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 80"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 80 0 100"></polygon>
        </svg>
      </div>
    </section>
    <!-- 1st Hero Variation -->
  </div>
  <section class="section section-lg pt-lg-0 mt--200">
    <div class="container">
      <!--      <h4 class="text-white text-center mb-5">Packages</h4>-->
      <div class="row justify-content-center mt-2">
        <div class="col-lg-12">
          <div class="row row-grid">
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="card-body py-4">
                  <div
                    class="icon icon-shape icon-shape-success rounded-circle mb-4 mr-3 float-left"
                  >
                    <i class="ni ni-check-bold"></i>
                  </div>
                  <h6 class="text-success display-4">$12.99/month</h6>
                  <p class="description mt-3">Great for Small Restaurants</p>
                  <ul>
                    <li>Up to <strong class="text-success display-5">18</strong> employees</li>
                    <li>Food Handler Certification courses <strong>included</strong></li>
                    <li>TABC Certification courses <strong>included</strong></li>
                    <li>Expiry Tracker</li>
                    <li>Automatic municipality reporting</li>
                  </ul>
                  <a [routerLink]="'/learn-more'" href="#" class="btn btn-success mt-4"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-lift--hover-short card-lift-up shadow border-0 mt--2">
                <span class="badge badge-pill badge-primary text-uppercase badge-pill-popular"
                  >Most Popular</span
                >
                <div class="card-body py-4">
                  <div
                    class="icon icon-shape icon-shape-primary rounded-circle mb-4 mr-3 float-left"
                  >
                    <i class="ni ni-check-bold"></i>
                  </div>
                  <h6 class="text-primary display-4">$18.99/month</h6>
                  <p class="description mt-3 clearfix">Our Most Popular Package</p>
                  <ul>
                    <li>Up to <strong class="text-primary display-5">50</strong> employees</li>
                    <li>Food Handler Certification courses <strong>included</strong></li>
                    <li>TABC Certification courses <strong>included</strong></li>
                    <li>Expiry Tracker</li>
                    <li>Automatic municipality reporting</li>
                  </ul>
                  <a [routerLink]="'/learn-more'" href="#" class="btn btn-primary mt-4"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-lift--hover shadow border-0">
                <div class="card-body py-4">
                  <div
                    class="icon icon-shape icon-shape-warning rounded-circle mb-4 mr-3 float-left"
                  >
                    <i class="ni ni-check-bold"></i>
                  </div>
                  <h6 class="text-warning display-4">$35.99/month</h6>
                  <p class="description mt-3 clearfix">For Large operations</p>
                  <ul>
                    <li>Up to <strong class="text-warning display-5">100</strong> employees</li>
                    <li>Food Handler Certification courses <strong>included</strong></li>
                    <li>TABC Certification courses <strong>included</strong></li>
                    <li>Expiry Tracker</li>
                    <li>Automatic municipality reporting</li>
                  </ul>
                  <a [routerLink]="'/learn-more'" href="#" class="btn btn-warning mt-4"
                    >Learn more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img src="./assets/img/theme/promo-1.png" class="img-fluid floating" />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
              <i class="ni ni-settings-gear-65"></i>
            </div>
            <h3>Stay Trained and Legal</h3>
            <p>
              FullSchedule keeps your Texas Food Handler certifications and TABC certifications up
              to date. Not only do we track and report per Texas law, we have the training classes
              included for your employees at no additional cost!
            </p>
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-settings-gear-65"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Online Food Handler and TABC certifications included</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-html5"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">
                      Track your employee certifications, even if they were obtained elsewhere
                    </h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-satisfied"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">
                      We fulfill reporting obligations to cities and states to keep you in
                      compliance.
                    </h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
