<div class="row pad-above">
  <div class="col-md-6 offset-md-3 text-center">
    <h3>{{ 'menu.faq' | translate }}</h3>
    <p>{{ 'faq.headline' | translate }}</p>
  </div>
</div>

<div class="row pad-below">
  <div class="col-md-10 offset-md-1 text-center">
    <table class="table text-left faq">
      <tr (click)="setFaq(1)" class="question">
        <td>{{ 'faq.q1' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 1 ? '' : 'hidden'">
        <td>{{ 'faq.a1' | translate }}</td>
      </tr>
      <tr (click)="setFaq(2)" class="question">
        <td>{{ 'faq.q2' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 2 ? '' : 'hidden'">
        <td>{{ 'faq.a2' | translate }}</td>
      </tr>
      <tr (click)="setFaq(3)" class="question">
        <td>{{ 'faq.q3' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 3 ? '' : 'hidden'">
        <td>{{ 'faq.a3' | translate }}</td>
      </tr>
      <tr (click)="setFaq(4)" class="question">
        <td>{{ 'faq.q4' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 4 ? '' : 'hidden'">
        <td>{{ 'faq.a4' | translate }}</td>
      </tr>
      <tr (click)="setFaq(5)" class="question">
        <td>{{ 'faq.q5' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 5 ? '' : 'hidden'">
        <td>{{ 'faq.a5' | translate }}</td>
      </tr>
      <tr (click)="setFaq(6)" class="question">
        <td>{{ 'faq.q6' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 6 ? '' : 'hidden'">
        <td>
          {{ 'faq.a6' | translate }} Support:
          <a href="mailto:support@fullschedule.com">support@fullschedule.com</a>
        </td>
      </tr>
      <tr (click)="setFaq(7)" class="question">
        <td>{{ 'faq.q7' | translate }}</td>
      </tr>
      <tr [ngClass]="showFaq == 7 ? '' : 'hidden'">
        <td>
          <a class="underline" [routerLink]="['/about/terms-and-conditions']">{{
            'faq.tandc' | translate
          }}</a
          >&nbsp;&nbsp;<a class="underline" [routerLink]="['/about/privacy-policy']">{{
            'faq.privacy-policy' | translate
          }}</a>
        </td>
      </tr>
    </table>
  </div>
</div>
