import * as tslib_1 from "tslib";
import { CourseShow, CourseStatus, TrainingActionTypes } from './training.types';
import { ApiService, Service } from '../../service/api.service';
import { setTokenToNextItem, setTokenToPreviousItem } from './training.utils';
import { Router } from '@angular/router';
import { StringHelper } from '../../shared/string-helper';
import { AuthActions } from '../../auth/redux/auth.actions';
import { PackageAuto, PackageTypes } from '../../shared/models/packages';
var TrainingActions = /** @class */ (function () {
    function TrainingActions(apiService, router, authActions) {
        var _this = this;
        this.apiService = apiService;
        this.router = router;
        this.authActions = authActions;
        this.chapterStart = function () { return ({
            type: TrainingActionTypes.CHAPTER_FETCH_START
        }); };
        this.chapterSuccess = function (data, index) { return ({
            type: TrainingActionTypes.CHAPTER_FETCH_SUCCESS,
            payload: { data: data, index: index }
        }); };
        this.chapterFailure = function (status, error) { return ({
            type: TrainingActionTypes.CHAPTER_FETCH_FAILURE,
            payload: error
        }); };
        this.cartAdd = function (items) { return ({
            type: TrainingActionTypes.CART_ADD,
            payload: items
        }); };
        this.clearCourse = function () { return ({
            type: TrainingActionTypes.COURSE_FETCH_SUCCESS,
            payload: null
        }); };
        this.clearProfileQuestion = function (item) { return ({
            type: TrainingActionTypes.CLEAR_PROFILE_QUESTION,
            payload: item
        }); };
        this.courseStart = function () { return ({
            type: TrainingActionTypes.COURSE_FETCH_START
        }); };
        this.courseSuccess = function (course) { return ({
            type: TrainingActionTypes.COURSE_FETCH_SUCCESS,
            payload: course
        }); };
        this.courseFailure = function (status, error) { return ({
            type: TrainingActionTypes.COURSE_FETCH_FAILURE,
            payload: error
        }); };
        this.coursesSuccess = function (data) { return ({
            type: TrainingActionTypes.COURSES_FETCH_SUCCESS,
            payload: data
        }); };
        this.coursesTokensSuccess = function (data) { return ({
            type: TrainingActionTypes.COURSES_TOKENS_FETCH_SUCCESS,
            payload: data
        }); };
        this.coursesFailure = function (status, error) { return ({
            type: TrainingActionTypes.COURSES_FETCH_FAILURE,
            payload: error
        }); };
        this.finalStart = function () { return ({
            type: TrainingActionTypes.FINAL_FETCH_START
        }); };
        this.finalSuccess = function (data) { return ({
            type: TrainingActionTypes.FINAL_FETCH_SUCCESS,
            payload: data
        }); };
        this.finalFailure = function (status, error) { return ({
            type: TrainingActionTypes.FINAL_FETCH_FAILURE,
            payload: error
        }); };
        this.hideSyllabus = function () { return ({
            type: TrainingActionTypes.SET_SYLLABUS_HIDE
        }); };
        this.pageStart = function () { return ({
            type: TrainingActionTypes.PAGE_FETCH_START
        }); };
        this.pageFailure = function (status, error) { return ({
            type: TrainingActionTypes.PAGE_FETCH_FAILURE,
            payload: error
        }); };
        this.pageSetFromToken = function (pageIndex) { return ({
            type: TrainingActionTypes.PAGE_SET_TEXT,
            payload: pageIndex
        }); };
        this.quizStart = function () { return ({
            type: TrainingActionTypes.QUIZ_FETCH_START
        }); };
        this.quizFetchSuccess = function (questions, timestamp) { return ({
            type: TrainingActionTypes.QUIZ_FETCH_SUCCESS,
            payload: { questions: questions, timestamp: timestamp }
        }); };
        this.quizScoreSuccess = function (data) { return ({
            type: TrainingActionTypes.QUIZ_SCORE_SUCCESS,
            payload: data
        }); };
        this.quizScoreFailure = function (data) { return ({
            type: TrainingActionTypes.QUIZ_SCORE_FAILURE,
            payload: data
        }); };
        this.quizFailure = function (status, error) { return ({
            type: TrainingActionTypes.QUIZ_FETCH_FAILURE,
            payload: error
        }); };
        this.setCurrentToken = function (objToken) { return ({
            type: TrainingActionTypes.SET_CURRENT_TOKEN,
            payload: objToken
        }); };
        this.securityAnsweredSuccess = function (data) { return ({
            type: TrainingActionTypes.SECURITY_SUCCESS,
            payload: data
        }); };
        this.securityConfirmationFailure = function (data) { return ({
            type: TrainingActionTypes.SECURITY_CONFIRM_FAILURE,
            payload: data
        }); };
        this.tokenAdd = function (token) { return ({
            type: TrainingActionTypes.TOKENS_ADD,
            payload: token
        }); };
        this.tokensStart = function () { return ({
            type: TrainingActionTypes.TOKENS_FETCH_START
        }); };
        this.tokensSuccess = function (tokens) { return ({
            type: TrainingActionTypes.TOKENS_FETCH_SUCCESS,
            payload: tokens
        }); };
        this.tokensFailure = function (status, error) { return ({
            type: TrainingActionTypes.TOKENS_FETCH_FAILURE,
            payload: error
        }); };
        this.cartStart = function () { return ({
            type: TrainingActionTypes.CART_FETCH_START
        }); };
        this.cartSuccess = function (cart) { return ({
            type: TrainingActionTypes.CART_FETCH_SUCCESS,
            payload: cart
        }); };
        this.cartFailure = function (status, error) { return ({
            type: TrainingActionTypes.CART_FETCH_FAILURE,
            payload: error
        }); };
        this.inviteError = function (error) { return ({
            type: TrainingActionTypes.INVITE_ERROR,
            payload: error
        }); };
        /** Async functions **/
        this.fetchTokensAsync = function () {
            return function (dispatch) {
                dispatch(_this.tokensStart());
                _this.apiService
                    .getUrl(Service.PUBLIC, '/tokens')
                    .then(function (response) {
                    dispatch(_this.tokensSuccess(response.data));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.tokensFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.cartSetAsync = function (course, cart) {
            return function (dispatch) {
                dispatch(_this.cartStart());
                var params = {
                    qty: 1,
                    course: course.id,
                    type: PackageTypes.TYPE_INDIVIDUAL,
                    auto: PackageAuto.ONE
                };
                dispatch(_this.cartSuccess(tslib_1.__assign({}, cart, params, { items: course.lineItems })));
                _this.apiService
                    .postUrl(Service.STRIPE, '/cart', params)
                    .then(function (response) {
                    dispatch(_this.cartSuccess(response.data));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.cartFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.cartSet = function (cart) {
            return function (dispatch) {
                dispatch(_this.cartSuccess(cart));
            };
        };
        this.cartSetBulkAsync = function (cart) {
            return function (dispatch) {
                dispatch(_this.cartStart());
                _this.apiService
                    .postUrl(Service.STRIPE, '/cart', {
                    auto: cart.auto,
                    course: cart.course,
                    qty: cart.qty,
                    type: cart.type
                })
                    .then(function (response) {
                    dispatch(_this.cartSuccess(response.data));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.cartFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.cartClearAsync = function () {
            return function (dispatch) {
                dispatch(_this.cartStart());
                _this.apiService
                    .deleteUrl(Service.STRIPE, '/cart')
                    .then(function (response) {
                    if (response && response.data) {
                        dispatch(_this.cartSuccess(response.data));
                    }
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.cartFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.cartClear = function () {
            return function (dispatch) {
                dispatch(_this.cartSuccess(undefined));
            };
        };
        this.fetchCartAsync = function () {
            return function (dispatch) {
                dispatch(_this.cartStart());
                _this.apiService
                    .getUrl(Service.STRIPE, '/cart')
                    .then(function (response) {
                    console.info('🛒 Cart found');
                    dispatch(_this.cartSuccess(response.data));
                })
                    .catch(function (response) {
                    if (response.status === 404) {
                        console.info('🛒 No cart in progress');
                    }
                    else {
                        console.log(response);
                        dispatch(_this.cartFailure(response.status, StringHelper.extractError(response)));
                    }
                });
            };
        };
        this.fetchCourseAsync = function (token) {
            return function (dispatch) {
                dispatch(_this.courseStart());
                _this.apiService
                    .postUrl(Service.PUBLIC, "/course/" + token.course, {
                    token: token.id,
                    language: token.language
                })
                    .then(function (response) {
                    var data = response.data;
                    if (data.profile && data.profile.includes('secondary_id_required')) {
                        data.profile = data.profile.filter(function (item) { return item !== 'secondary_id_required'; });
                        console.log('Course requires secondary ID');
                        dispatch(_this.authActions.setSecondary());
                    }
                    dispatch(_this.courseSuccess(data));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.courseFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.fetchCoursesAsync = function () {
            return function (dispatch) {
                dispatch(_this.courseStart());
                _this.apiService
                    .getUrl(Service.PUBLIC, "/courses", {})
                    .then(function (response) {
                    dispatch(_this.coursesSuccess(response.data));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.coursesFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.fetchCoursesTokensAsync = function () {
            return function (dispatch) {
                dispatch(_this.courseStart());
                _this.apiService
                    .getUrl(Service.PUBLIC, "/tokens-courses", {})
                    .then(function (response) {
                    dispatch(_this.coursesTokensSuccess(response.data));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.coursesFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.fetchChapterAsync = function (token) {
            return function (dispatch) {
                dispatch(_this.chapterStart());
                _this.apiService
                    .postUrl(Service.PUBLIC, "/course/" + token.course + "/chapter/" + token.currentSectionIndex + "/" + token.currentChapterIndex + "/chapter", {
                    token: token.id,
                    language: token.language
                })
                    .then(function (response) { return dispatch(_this.chapterSuccess(response.data, token.currentChapterIndex)); })
                    .catch(function (response) {
                    console.log(response);
                    return dispatch(_this.chapterFailure(response.status, response.error.error));
                });
            };
        };
        this.fetchPriorPageAsync = function () {
            return function (dispatch, globalState) {
                console.log('🔔 fetchPriorPageAsync()');
                var language = globalState().auth.language;
                var state = globalState().training;
                var oldIndex = state.currentToken.currentChapterIndex;
                // Set our token to the prior item
                var objToken = setTokenToPreviousItem(state.currentToken, state.course);
                objToken.language = language;
                dispatch(_this.setCurrentToken(objToken));
                // If we need to load something as a result of that, do it now
                if (objToken.show === CourseShow.Course && oldIndex !== objToken.currentChapterIndex) {
                    dispatch(_this.fetchChapterAsync(objToken));
                }
                dispatch(_this.statusUpdatePush(objToken, state));
            };
        };
        this.fetchNextPageAsync = function () {
            return function (dispatch, globalState) {
                console.log('🔔 fetchNextPageAsync()');
                var language = globalState().auth.language;
                var state = globalState().training;
                var oldIndex = state.currentToken.currentChapterIndex;
                // Set our token to the next item
                var objToken = setTokenToNextItem(state.currentToken, state.course);
                objToken.language = language;
                dispatch(_this.setCurrentToken(objToken));
                // If we need to load something as a result of that, do it now
                if (objToken.show === CourseShow.Course && oldIndex !== objToken.currentChapterIndex) {
                    dispatch(_this.fetchChapterAsync(objToken));
                }
                if (!objToken.questions &&
                    [
                        CourseShow.Quiz,
                        CourseShow.SectionQuiz,
                        CourseShow.SecurityInitial,
                        CourseShow.SecurityConfirm
                    ].includes(objToken.show)) {
                    dispatch(_this.quizAsync(objToken));
                }
                dispatch(_this.statusUpdatePush(objToken, state));
            };
        };
        this.quizAsync = function (token, answers) {
            return function (dispatch) {
                dispatch(_this.quizStart());
                var params = {
                    course: token.course,
                    id: token.id,
                    show: token.show
                };
                if (answers) {
                    params.answers = answers;
                    if (token.show === CourseShow.SecurityInitial) {
                        params.show = CourseShow.SecurityAnswers;
                    }
                }
                if (token.show === CourseShow.End) {
                    params.show = CourseShow.FinalQuiz;
                }
                _this.apiService
                    .postUrl(Service.PUBLIC, "/quiz", params)
                    .then(function (response) {
                    switch (response.data.show) {
                        case CourseShow.SecurityInitial:
                            return dispatch(_this.quizFetchSuccess(response.data.questions));
                        case CourseShow.SecurityConfirm:
                            return dispatch(_this.quizFetchSuccess(response.data.questions, response.data.timestamp));
                        case CourseShow.SecurityAnswers:
                        case CourseShow.SecurityConfirmResultPass:
                            return dispatch(_this.securityAnsweredSuccess(response.data));
                        case CourseShow.SecurityConfirmResultFail:
                            return dispatch(_this.securityConfirmationFailure(response.data));
                        case CourseShow.Quiz:
                            return dispatch(_this.quizFetchSuccess(response.data.questions));
                        case CourseShow.QuizResultCourseLock:
                        case CourseShow.QuizResultSectionLock:
                            return dispatch(_this.quizScoreFailure(response.data));
                        case CourseShow.FinalQuiz:
                            return dispatch(_this.finalSuccess(response.data.questions));
                        case CourseShow.QuizResult:
                        case CourseShow.SecurityInitialResult:
                            return dispatch(_this.quizScoreSuccess(response.data.results));
                        case CourseShow.FinalResult:
                            return dispatch(_this.quizScoreSuccess(response.data.results));
                        default:
                            return dispatch(_this.fetchNextPageAsync());
                    }
                })
                    .catch(function (response) {
                    console.log(response);
                    if (response.status === 409) {
                        _this.router.navigate(['/profile/certificate/', token.id]).then();
                    }
                    dispatch(_this.quizFailure(response.status, StringHelper.extractError(response)));
                });
            };
        };
        this.redeemInvite = function (token) {
            return function (dispatch) {
                _this.apiService
                    .postUrl(Service.PUBLIC, '/token/redeem-invite', { token: token })
                    .then(function (response) {
                    console.log(response);
                    dispatch(_this.authActions.userSet(response.data.user));
                    dispatch(_this.afterPurchase(response.data.token));
                })
                    .catch(function (response) {
                    console.log(response);
                    dispatch(_this.inviteError(StringHelper.extractError(response)));
                });
            };
        };
        this.resetCourse = function (objToken) {
            return function (dispatch) {
                var progress = {
                    language: objToken.language,
                    reset: true
                };
                _this.apiService
                    .postUrl(Service.PUBLIC, "/token-update-progress/" + objToken.id, progress)
                    .then(function (response) {
                    var objNewToken = response.data;
                    console.log(objNewToken);
                    dispatch(_this.setCurrentToken(objNewToken));
                    dispatch(_this.fetchChapterAsync(objNewToken));
                    _this.router
                        .navigate([
                        '/training',
                        objNewToken.course,
                        'page',
                        objNewToken.on_page || 'introduction'
                    ])
                        .then();
                })
                    .catch(function (error) { return console.log(error); });
            };
        };
        this.statusUpdatePush = function (objToken, state) {
            return function () {
                var progress = {
                    language: objToken.language,
                    pageClip: {
                        id: objToken.on_page_id || state.currentToken.show,
                        slug: objToken.on_page || state.currentToken.show,
                        language: objToken.language,
                        sequence_no: state.currentToken.currentSequenceNumber,
                        status_percentage: state.currentToken.status_percentage,
                        currentPageIndex: state.currentToken.currentPageIndex,
                        currentChapterIndex: state.currentToken.currentChapterIndex,
                        currentSectionIndex: state.currentToken.currentSectionIndex,
                        currentSequenceNumber: state.currentToken.currentSequenceNumber,
                        show: state.currentToken.show
                    }
                };
                _this.apiService
                    .postUrl(Service.PUBLIC, "/token-update-progress/" + state.currentToken.id, progress)
                    .then()
                    .catch(function (error) { return console.log(error); });
            };
        };
        this.subscriberSetCreditCardAsync = function (params) {
            return function (dispatch) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var response, error_1;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.apiService.postUrl(Service.ADMIN, '/subscriber/account/cc', params)];
                        case 1:
                            response = _a.sent();
                            dispatch(this.cartSuccess(response.data));
                            return [2 /*return*/, true];
                        case 2:
                            error_1 = _a.sent();
                            console.log(error_1);
                            dispatch(this.cartFailure(error_1.status, StringHelper.extractError(error_1)));
                            return [2 /*return*/, false];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
        };
        this.subscriberDeleteCreditCardAsync = function () {
            return function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var response, error_2;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.apiService.deleteUrl(Service.ADMIN, '/subscriber/account/cc')];
                        case 1:
                            response = _a.sent();
                            console.log(response);
                            return [2 /*return*/, true];
                        case 2:
                            error_2 = _a.sent();
                            console.error(error_2);
                            return [2 /*return*/, false];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
        };
        this.afterPurchase = function (objToken, blnRedirectAfter) {
            if (blnRedirectAfter === void 0) { blnRedirectAfter = true; }
            return function (dispatch) {
                if (objToken.token_type === 'initial' && blnRedirectAfter) {
                    objToken.status = CourseStatus.InProgress;
                    dispatch(_this.tokenAdd(objToken));
                    dispatch(_this.setCurrentToken(objToken));
                }
                else {
                    dispatch(_this.tokenAdd(objToken));
                }
                dispatch(_this.cartClear());
                if (blnRedirectAfter) {
                    _this.router.navigate(['/training', objToken.course, 'page', 'introduction']).then();
                }
            };
        };
    }
    return TrainingActions;
}());
export { TrainingActions };
