import { Injectable } from '@angular/core';

import { SettingsService } from './settings.service';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../shared/models/api-response.model';
import { CertificateResponse } from '../shared/models/certificate.model';
import { HttpClient } from '@angular/common/http';
import sizeof from 'object-sizeof';
import { DateTime } from 'luxon';

interface CustomRequestInfo {
  readonly httpMethod: string;
  readonly url: string;
  readonly sessionId: string;
  readonly requestId: number | string;
  readonly start: number;
}

export enum Service {
  ADMIN = 'admin',
  PUBLIC = 'public',
  STRIPE = 'stripe'
}

@Injectable()
export class ApiService {
  private adminUrl: string;
  private publicUrl: string;
  private stripeUrl: string;
  private apiHeaderName: string;
  private apiHeaderVal: string;
  private environment;

  constructor(
    private settings: SettingsService,
    private http: HttpClient
  ) {
    this.adminUrl = settings.adminUrl;
    this.publicUrl = settings.publicUrl;
    this.stripeUrl = settings.stripeUrl;
    this.apiHeaderName = settings.apiHeaderName;
    this.apiHeaderVal = settings.apiHeaderValue;

    this.environment = environment;
  }

  static logRequestComplete = (r: CustomRequestInfo, endTime: number, byteLength?: number) => {
    if (!environment.production) {
      console.group(`✅ ${r.httpMethod} ${r.url}`);
      console.info(`Session: ${r.sessionId}`);
      console.info(`Request: ${r.requestId}`);
      console.info(
        `Time: ${Math.round(endTime - r.start)} ms`,
        typeof byteLength === 'number' && `Bytes: ${byteLength}`
      );
      console.groupEnd();
    }
  };

  static logRequestError = (
    r: CustomRequestInfo,
    endTime: number,
    statusCode: number,
    statusText
  ) => {
    // if (!environment.production) {
    console.group(`❌ (${statusCode} ${statusText}) ${r.httpMethod} ${r.url}`);
    console.info(`Session: ${r.sessionId}`);
    console.info(`Request: ${r.requestId}`);
    console.info(`Time: ${Math.round(endTime - r.start)} ms`);
    console.groupEnd();
    // }
  };

  getToken() {
    try {
      return localStorage.getItem('jwt');
    } catch (error) {
      console.error(error);
      alert(
        'Browser error attempting to read cookies. Disabling cookies will cause this website to error.'
      );
    }
  }

  getUrl(service: Service, url: string, params = {}, auth = true): Promise<ApiResponse> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Host: environment.publicUrl
    };

    if (auth) {
      headers['Authorization'] = this.getToken();
    }
    const obj: RequestInit = {
      method: 'GET',
      headers
    };

    return this.runFetch(service, url, obj, params);
  }

  getIUrl(url: string): Promise<ApiResponse> {
    return this.http.get<any>(url).toPromise();
  }

  postIUrl(url: string, data: object): Promise<ApiResponse> {
    return this.http.post<any>(url, data).toPromise();
  }

  postUrl(service: Service, url: string, objData: object, auth = true): Promise<ApiResponse> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (auth) {
      try {
        headers['Authorization'] = this.getToken();
      } catch (error) {
        console.error(error);
        alert(
          'Browser error attempting to read cookies. Disabling cookies will cause this website to error.'
        );
      }
    }
    const obj: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(objData)
    };

    return this.runFetch(service, url, obj);
  }

  putUrl(service: Service, url: string, objData: object, auth = true): Promise<ApiResponse> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (auth) {
      try {
        headers['Authorization'] = this.getToken();
      } catch (error) {
        console.error(error);
        alert(
          'Browser error attempting to read cookies. Disabling cookies will cause this website to error.'
        );
      }
    }
    const obj: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(objData)
    };

    return this.runFetch(service, url, obj);
  }

  patchUrl(service: Service, url: string, objData: object, auth = true): Promise<ApiResponse> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (auth) {
      try {
        headers['Authorization'] = this.getToken();
      } catch (error) {
        console.error(error);
        alert(
          'Browser error attempting to read cookies. Disabling cookies will cause this website to error.'
        );
      }
    }
    const obj: RequestInit = {
      method: 'PATCH',
      headers,
      body: JSON.stringify(objData)
    };

    return this.runFetch(service, url, obj);
  }

  deleteUrl(service: Service, url: string, auth = true): Promise<ApiResponse> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (auth) {
      try {
        headers['Authorization'] = this.getToken();
      } catch (error) {
        console.error(error);
        alert(
          'Browser error attempting to read cookies. Disabling cookies will cause this website to error.'
        );
      }
    }
    const obj: RequestInit = {
      method: 'DELETE',
      headers
    };

    return this.runFetch(service, url, obj);
  }

  postCertificateManagerSend(token: string, objData: object): Promise<CertificateResponse> {
    return <Promise<CertificateResponse>>(
      this.postUrl(Service.PUBLIC, `/certificate-manager-send/${token}`, objData)
    );
  }

  postCertificateWalletSend(token: string, objData: object): Promise<CertificateResponse> {
    return <Promise<CertificateResponse>>(
      this.postUrl(Service.PUBLIC, `/certificate-wallet-send/${token}`, objData)
    );
  }

  getCertificateSend(token: string): Promise<CertificateResponse> {
    return <Promise<CertificateResponse>>this.getUrl(Service.PUBLIC, `/certificate-send/${token}`);
  }

  requestTokenResults(token: string): Promise<CertificateResponse> {
    return <Promise<CertificateResponse>>this.getUrl(Service.PUBLIC, `/token-results/${token}`, {});
  }

  private runFetch(
    service: string,
    url: string,
    obj: RequestInit,
    params: object = {}
  ): Promise<ApiResponse> {
    const esc = encodeURIComponent;
    const query = Object.keys(params).length
      ? '?' +
        Object.keys(params)
          .filter((k) => !(params[k] === undefined))
          .map((k) => esc(k) + '=' + esc(params[k]))
          .join('&')
      : '';
    const requestId = DateTime.now().setZone('America/Chicago').toFormat('HH:mm:ss.SSS');
    const logR: CustomRequestInfo = {
      httpMethod: obj.method,
      requestId,
      sessionId: obj.headers['Authorization']
        ? obj.headers['Authorization'].toString().substr(-10)
        : 'none',
      start: DateTime.now().valueOf() / 1000,
      url
    };

    return new Promise((resolve, reject) => {
      fetch(`${environment[service + 'Url']}${url}${query}`, obj)
        .then((response) => {
          if (response.status === 200) {
            const responseJson = response.json();
            ApiService.logRequestComplete(
              logR,
              DateTime.now().valueOf() / 1000,
              sizeof(responseJson)
            );
            resolve(<ApiResponse>(<unknown>responseJson));
          } else if (response.status >= 201 && response.status <= 299) {
            const responseJson = undefined;
            ApiService.logRequestComplete(
              logR,
              DateTime.now().valueOf() / 1000,
              sizeof(responseJson)
            );
            resolve(<ApiResponse>(<unknown>responseJson));
          } else {
            ApiService.logRequestError(logR, 0, response.status, response.statusText);
            response.text().then((res: any) =>
              reject({
                status: response.status,
                error: res ? (res[0] === '{' ? JSON.parse(res) : res) : response.statusText
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
          ApiService.logRequestError(
            logR,
            0,
            error.response ? error.response.status : 'unknown',
            error.response ? error.response.statusText : 'unknownText'
          );
          console.log('No internet connection found. App is running in offline mode.');
          reject({
            status: 502,
            error: 'Your internet connection appears to have been lost. Try refreshing page.'
          });
        });
    });
  }
}
