import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { environment } from '../../../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  selectIsBounced,
  selectIsLoggedIn,
  selectIsManager,
  selectLanguage,
  selectUser,
  selectUserName
} from '../../auth/redux/auth.selectors';
import { IAppState } from '../../app.module';
import { AuthActions } from '../../auth/redux/auth.actions';
import { IMe } from '../../auth/redux/auth.state';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @select(selectIsLoggedIn) readonly loggedIn$: Observable<boolean>;
  @select(selectIsManager) readonly isManager$: Observable<boolean>;
  @select(selectLanguage) readonly language$: Observable<string>;
  @select(selectUser) readonly user$: Observable<IMe>;
  @select(selectUserName) readonly name$: Observable<string>;
  @select(selectIsBounced) readonly bounced$: Observable<boolean>;
  subscriptions: Subscription[] = [];

  public isCollapsed = true;

  loggedIn: boolean;
  isManager: boolean;
  name: string;
  employer: string;
  language: string;
  profile_pic: string;

  error: string;
  branding: string;
  environment: any;

  features = 'Features';

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  constructor(
    public location: Location,
    public router: Router,
    private ngRedux: NgRedux<IAppState>,
    private authActions: AuthActions
  ) {
    this.environment = environment;
    this.branding = environment.branding;
    if (this.branding === 'cent') {
      this.features = 'For Businesses';
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.name$.subscribe((name: string) => {
        this.name = name;
      })
    );
    this.subscriptions.push(
      this.user$.subscribe((user: IMe) => {
        if (user && user.employer) {
          this.employer = user.employer.substring(0, 13);
        } else {
          this.employer = undefined;
        }
      })
    );

    this.subscriptions.push(
      this.loggedIn$.subscribe((loggedIn: boolean) => {
        this.loggedIn = loggedIn;
      })
    );

    this.subscriptions.push(
      this.isManager$.subscribe((isManager: boolean) => {
        this.isManager = isManager;
      })
    );

    this.subscriptions.push(
      this.language$.subscribe((language: string) => {
        this.language = language;
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
          if (event.url !== this.lastPoppedUrl) {
            this.yScrollStack.push(window.scrollY);
          }
        } else if (event instanceof NavigationEnd) {
          if (event.url === this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined;
            window.scrollTo(0, this.yScrollStack.pop());
          } else {
            window.scrollTo(0, 0);
          }
        }
      })
    );

    this.subscriptions.push(
      <Subscription>this.location.subscribe((ev: PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  changeLanguage(language: string) {
    this.ngRedux.dispatch(this.authActions.changeLanguageAsync(language));
  }

  isHome() {
    const title = this.location.prepareExternalUrl(this.location.path());
    return title === '/';
  }

  isTraining() {
    const title = this.location.prepareExternalUrl(this.location.path());
    return title.substr(0, 14) === '/training/play';
  }

  inProgress() {
    return localStorage.getItem('url');
  }

  isStudent() {
    const title = this.location.prepareExternalUrl(this.location.path());
    return title.substr(0, 8) === '/student';
  }

  isDocumentation() {
    const title = this.location.prepareExternalUrl(this.location.path());
    return title === '/documentation';
  }

  isDashboard() {
    return this.router.url.substr(0, 6) === '/admin';
  }
}
