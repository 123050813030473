<footer
  class="footer"
  [ngClass]="{
    'has-cards':
      getPath() !== '/user-profile' && getPath() !== '/register' && getPath() !== '/login',
    'left-menu-block': isDashboard()
  }"
>
  <div class="container">
    <hr />
    <div class="row align-items-center justify-content-md-between">
      <div class="col-12">
        <div class="copyright pull-right">
          &copy; {{ test | date: 'yyyy' }}
          Full Schedule LLC
        </div>
      </div>
    </div>
  </div>
</footer>
