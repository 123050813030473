import * as tslib_1 from "tslib";
import { ApiService, Service } from './api.service';
var UserRegistrationService = /** @class */ (function () {
    function UserRegistrationService(apiService) {
        this.apiService = apiService;
    }
    UserRegistrationService.prototype.register = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attributeList;
            return tslib_1.__generator(this, function (_a) {
                attributeList = [];
                user.email = user.email.toLowerCase();
                return [2 /*return*/, this.apiService.postUrl(Service.PUBLIC, '/users', user)];
            });
        });
    };
    UserRegistrationService.prototype.confirmRegistration = function (username, confirmationCode) { };
    UserRegistrationService.prototype.resendCode = function (username) { };
    UserRegistrationService.prototype.newPassword = function (newPasswordUser) { };
    return UserRegistrationService;
}());
export { UserRegistrationService };
