import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { WINDOW_PROVIDERS } from './service/window.service';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ApiService, Service } from './service/api.service';
import { PaymentService } from './service/payment.service';
import { AuthGuard } from './service/auth.guard';
import { UserRegistrationService } from './service/user-registration.service';
import { SettingsService } from './service/settings.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BsDropdownModule } from 'ngx-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomFormsModule } from 'ng2-validation';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageDirective } from './shared/directives/language.directive';
import { FooterComponent } from './shared/footer/footer.component';
import { SignUpComponent } from './signup/signup.component';
import { SharedModule } from './shared/shared-module';
import { StickyService } from './shared/sticky-service';
import { HomeModule } from './home/home.module';
import { environment } from '../environments/environment';
import { NotPlayComponent } from './shared/navbar/not-play/not-play.component';
import { DuringPlayComponent } from './shared/navbar/during-play/during-play.component';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgReduxModule, NgRedux } from '@angular-redux/store';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from './app.root-reducer';
import { compose, applyMiddleware, Store, createStore, AnyAction } from 'redux';
import { persistStore, autoRehydrate, PersistorConfig } from 'redux-persist';
import { asyncLocalStorage } from 'redux-persist/storages';
import { IAuthState } from './auth/redux/auth.state';
import { CookieService } from 'ngx-cookie-service';
import { AuthActions } from './auth/redux/auth.actions';
import { TrainingActions } from './training/redux/training.actions';
import { BouncedAlertComponent } from './shared/bounced-alert/bounced-alert.component';

export interface IAppState {
  readonly auth: IAuthState;
}

const persistConfig: PersistorConfig = {
  // whitelist: ['auth'],
  // whitelist: ['auth', 'admin', 'training'],
  keyPrefix: 'cent-',
  storage: asyncLocalStorage
};

// @ts-ignore
export const store: Store<IAppState> =
  process.env.APP_ENV === 'prod'
    ? createStore(
        rootReducer,
        compose(
          // @ts-ignore
          applyMiddleware<ThunkDispatch<IAppState, void, Action>>(thunk),
          autoRehydrate()
        )
      )
    : createStore(
        rootReducer,
        compose(
          // @ts-ignore
          applyMiddleware<ThunkDispatch<IAppState, void, Action>>(thunk, createLogger()),
          autoRehydrate()
        )
      );

@Injectable()
export class AppConfigService {
  public load() {
    return new Promise<void>((resolve) => {
      persistStore(store, persistConfig, () => {
        resolve();
      });
    });
  }
}

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleLogin)
  }
]);

export function provideConfig() {
  return config;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LanguageDirective,
    SignUpComponent,
    FooterComponent,
    NotPlayComponent,
    DuringPlayComponent,
    NotFoundComponent,
    BouncedAlertComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgReduxModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    SocialLoginModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    Angulartics2Module.forRoot(),
    InfiniteScrollModule,
    CustomFormsModule,
    NgxSkeletonLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HomeModule
  ],
  providers: [
    AppConfigService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: (factoryConfig: AppConfigService) => () => factoryConfig.load(),
      deps: [AppConfigService],
      multi: true
    },
    AuthGuard,
    UserRegistrationService,
    ApiService,
    SettingsService,
    AuthActions,
    TrainingActions,
    PaymentService,
    StickyService,
    WINDOW_PROVIDERS,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    ngRedux.provideStore(store);
  }
}
