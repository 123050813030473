import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginStep } from '../shared/models/login-step.model';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { selectIsLoggedIn } from '../auth/redux/auth.selectors';
import { select } from '@angular-redux/store';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @select(selectIsLoggedIn) readonly loggedIn$: Observable<boolean>;
  subscriptions: Subscription[] = [];

  focus: any;
  focus1: any;
  branding: string;

  @Input() destination: string;

  email: string;
  password: string;
  errorMessage: string;

  loggingIn = false;
  loggedIn = false;
  welcome = false;
  forgot = false;
  timeout = false;
  loginStep = LoginStep.NEW;
  loginStepChoices = LoginStep;

  constructor(private route: ActivatedRoute) {
    this.branding = environment.branding;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.timeout = params['timeout'] === 'true';
    });

    this.subscriptions.push(
      this.loggedIn$.subscribe((loggedIn: boolean) => {
        this.loggedIn = loggedIn;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
