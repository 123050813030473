import { DateTime } from 'luxon';

export class StringHelper {
  static camelToDash(str: string) {
    return str.replace(/\W+/g, '-').replace(/([a-z\d])([A-Z])/g, '$1-$2');
  }

  static onlyNumbers(str: string) {
    return str.replace(/[^0-9]/g, '');
  }

  static onlyAlphaNumeric(str: string) {
    return str.replace(/[^a-zA-Z0-9\-_]/g, '');
  }

  static cleanPhone(str: string) {
    str = str.replace(/[^0-9]+/g, '');
    if (str.substr(0, 1) === '1') {
      str = str.substr(1, 100);
    }
    return str.trim();
  }

  static titleCase(str: string) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  static dashToProperUnderscore(str: string) {
    return StringHelper.titleCase(str.replace(/\W+/g, ' '))
      .replace(/([a-z\d])([A-Z])/g, '$1_$2')
      .replace(/ /g, '_');
  }

  static extractError(error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return error.response.data.error;
    } else if (error.error && error.error.error) {
      return error.error.error;
    } else if (error.error) {
      return error.error;
    } else if (error.message) {
      return error.message;
    } else {
      if (typeof error === 'string') {
        return error;
      } else {
        return 'Unknown error';
      }
    }
  }

  static makeDateFromSeconds(value: number): Date {
    return DateTime.fromSeconds(value).setZone('America/Chicago').toJSDate();
  }
  static makeDateFromMilliseconds(value: number): Date {
    return DateTime.fromMillis(value).setZone('America/Chicago').toJSDate();
  }
}
