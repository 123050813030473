import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-schedule',
  templateUrl: './full-schedule.component.html',
  styleUrls: ['./full-schedule.component.scss']
})
export class FullScheduleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
