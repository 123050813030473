import { AdminEmployees } from '../admin-tokens/admin-tokens.component';
import { IParameters } from './admin.state';
import { DateTime } from 'luxon';

export const addTokensToCollection = (
  tokenItems: AdminEmployees[],
  tokenItemToAdd: AdminEmployees
) => {
  return [...tokenItems, tokenItemToAdd];
};

export const updateTokenInCollection = (Tokens: AdminEmployees[], newToken: AdminEmployees) => {
  const existingCartItem = Tokens.find((item) => item.id === newToken.id);

  if (existingCartItem) {
    return Tokens.map((item) => (item.id === newToken.id ? newToken : item));
  }

  return [...Tokens, newToken];
};

export const updateWebhookInCollection = (Tokens: AdminEmployees[], newToken: AdminEmployees) => {
  const existingCartItem = Tokens.find((item) => item.created_at === newToken.created_at);

  if (existingCartItem) {
    return Tokens.map((item) => (item.created_at === newToken.created_at ? newToken : item));
  }

  return [...Tokens, newToken];
};

export const buildName = (arrTokens: AdminEmployees[]) => {
  return arrTokens.map((item) => {
    return {
      ...item,
      name:
        item.user && item.user.first_name
          ? item.user.first_name + ' ' + item.user.last_name
          : item.user
            ? item.user.email
            : ''
    };
  });
};

export const filterDataByShowing = (
  tokenItems: AdminEmployees[],
  params: IParameters
): AdminEmployees[] => {
  let filteredRecords: AdminEmployees[];
  switch (params.status) {
    case 'available':
    case 'completed':
    case 'invited':
      filteredRecords = tokenItems.filter(
        (item: AdminEmployees) => item.status === params.status && !item.hidden
      );
      break;
    case 'in-progress':
      filteredRecords = tokenItems.filter((item: AdminEmployees) => {
        return item.status === params.status && item.redeemed_at && !item.hidden;
      });
      break;
    case 'redeemed':
      filteredRecords = tokenItems.filter(
        (item: AdminEmployees) => item.status !== 'available' && !item.hidden
      );
      break;
    case 'hidden':
      filteredRecords = tokenItems.filter((item: AdminEmployees) => item.hidden);
      break;
    case 'expired':
      filteredRecords = tokenItems.filter((item: AdminEmployees) => {
        return (
          item.status === 'completed' &&
          item.expires_at < DateTime.now().toUnixInteger() &&
          !item.hidden
        );
      });
      break;
    case 'all':
      filteredRecords = tokenItems.filter((item: AdminEmployees) => !(item.hidden !== false));
      break;
    default:
      filteredRecords = tokenItems;
  }

  if (params.search) {
    console.log(params.search);
    return filteredRecords.filter((item: AdminEmployees) => {
      return (
        (item.name && item.name.toLowerCase().indexOf(params.search.toLowerCase()) > -1) ||
        (item.email && item.email.toLowerCase().indexOf(params.search.toLowerCase()) > -1) ||
        (item.invite_email &&
          item.invite_email.toLowerCase().indexOf(params.search.toLowerCase()) > -1)
      );
    });
  }

  return filteredRecords;
};

export const sortData = (tokenItems: AdminEmployees[], params: IParameters) => {
  let sortField = params.sortField;
  let sortDirection = params.sortDirection;
  return tokenItems.sort((a: AdminEmployees, b: AdminEmployees) => {
    let textA: number;
    let textB: number;
    switch (sortField) {
      case 'test_score':
      case 'elapsed_time':
      case 'created_at':
      case 'expires_at':
        textA = a[sortField] ? a[sortField] : null;
        textB = b[sortField] ? b[sortField] : null;
        break;
      default:
        textA = a[sortField] ? a[sortField].toUpperCase() : '';
        textB = b[sortField] ? b[sortField].toUpperCase() : '';
    }

    if (sortDirection === 'asc') {
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
    return textA > textB ? -1 : textA > textB ? 1 : 0;
  });
};
