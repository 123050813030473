import { Component } from '@angular/core';
import { select } from '@angular-redux/store';
import { selectIsManager } from '../../auth/redux/auth.selectors';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in-inset.component.html',
  styleUrls: ['./logged-in-inset.component.scss']
})
export class LoggedInInsetComponent {
  @select(selectIsManager) readonly isManager$: Observable<boolean>;
}
