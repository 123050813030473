<div *ngIf="showNewUser" id="user">
  <div class="message message-alert" data-id="system-alert-new-deploy">
    <span class="message-icon fa fa-exclamation-triangle"></span>
    <div class="text">
      <strong>The logged in user has changed on another tab or window.</strong> Please click
      <a href="" (click)="reload()">reload</a> to reload user session.
    </div>
  </div>
</div>
<app-navbar></app-navbar>
<app-bounced-alert *ngIf="bounced$ | async"></app-bounced-alert>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div *ngIf="showNewVersion" id="messages">
  <div
    class="message message-alert"
    data-id="system-alert-new-deploy"
    style="width: 330px; opacity: 1; position: relative; left: 0"
  >
    <span class="message-icon fa fa-exclamation-triangle"></span>
    <div class="text">
      <strong>Your browser has an old version of 99CentFoodHandler cached.</strong> Please click
      <a href="" (click)="reload()">reload</a>
      to use our updated site.
    </div>
  </div>
</div>
