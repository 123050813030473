import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { CookieService } from 'ngx-cookie-service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, cookieService) {
        this.router = router;
        this.cookieService = cookieService;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.auth$.pipe(map(function (e) {
            if (localStorage.getItem('jwt') && e.loggedIn) {
                return true;
            }
            else {
                _this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } }).then();
            }
        }), catchError(function (err) {
            console.log(err);
            _this.router.navigate(['/auth/login']).then();
            return of(false);
        }));
    };
    tslib_1.__decorate([
        select(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthGuard.prototype, "auth$", void 0);
    return AuthGuard;
}());
export { AuthGuard };
