import * as Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '../environments/environment';
import { IUser } from './auth/redux/auth.state';
import * as jwtDecode from 'jwt-decode';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  let objUser: IUser;
  if (localStorage.getItem('jwt')) {
    objUser = jwtDecode(localStorage.getItem('jwt'));
  }
  return new Rollbar({
    enabled: environment.env === 'prod',
    // enabled: true,
    accessToken: environment.rollbarKey,
    environment: environment.env,
    codeVersion: `${environment.version}`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: `${environment.version}`
        }
      },
      person: objUser
        ? {
            id: localStorage.getItem('id'),
            username: objUser.name,
            email: objUser.email
          }
        : {
            id: 'not-logged-in'
          }
    }
  });
}
