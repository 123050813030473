import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class SettingsService {
  env_var: string;
  adminUrl: string;
  publicUrl: string;
  stripeUrl: string;
  assetsUrl: string;
  apiHeaderName: string;
  apiHeaderValue: string;

  constructor() {
    this.env_var = environment.production ? 'prod' : 'acc';
    this.adminUrl = environment.adminUrl;
    this.stripeUrl = environment.stripeUrl;
    this.publicUrl = environment.publicUrl;
    this.assetsUrl = environment.assetsUrl;
    this.apiHeaderName = environment.apiHeaderName;
    this.apiHeaderValue = environment.apiHeaderValue;
  }
}
