export class RegistrationUser {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  birth_date: string;
  password: string;
  passwordConfirm: string;
  phone_type: string;
  employer: string;
  branding: string;
  manager_name: string;
  manager_email: string;
  manager_phone: string;
}
