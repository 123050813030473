import { createSelector } from 'reselect/lib';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

const selectAuth = (state) => state.auth;

export const selectEmail = createSelector([selectAuth], (state) =>
  state.user ? state.user.email : undefined
);

export const selectEmployer = createSelector([selectAuth], (state) =>
  state.user && state.employer_object ? <Address>state.employer_object : undefined
);

export const selectError = createSelector([selectAuth], (state) => state.error);

export const selectIsLoggedIn = createSelector([selectAuth], (state) => state.loggedIn);

export const selectIsBounced = createSelector([selectAuth], (state) =>
  state.loggedIn && state.user ? state.bounced : false
);

export const selectIsManager = createSelector([selectAuth], (state) =>
  state.loggedIn && state.claims && state.claims.is_manager ? state.claims.is_manager : false
);

export const selectJwtToken = createSelector([selectAuth], (state) =>
  state.claims ? state.claims.token : undefined
);

export const selectLanguage = createSelector([selectAuth], (state) => state.language);

export const selectRunningAction = createSelector([selectAuth], (state) => state.blnRunningAction);

export const selectSignUpStep = createSelector([selectAuth], (state) => state.signUpStep);

export const selectUser = createSelector([selectAuth], (state) => state.user);
export const selectClaims = createSelector([selectAuth], (state) => state.claims);

export const selectUserName = createSelector([selectAuth], (state) =>
  state.user && state.user.first_name && state.user.last_name
    ? `${state.user.first_name} ${state.user.last_name}`
    : undefined
);
