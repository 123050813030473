import { combineReducers } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';

import authReducer from './auth/redux/auth.reducer';
import adminReducer from './admin/redux/admin.reducer';
import trainingReducer from './training/redux/training.reducer';

export const appReducer = composeReducers(
  defaultFormReducer(),
  combineReducers({
    auth: authReducer,
    admin: adminReducer,
    training: trainingReducer
  })
);

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
    localStorage.removeItem('jwt');
    localStorage.removeItem('id');
  }

  return appReducer(state, action);
};
